/* eslint-disable class-methods-use-this */
import { HistoryLine, messageLines } from "../HistoryLine";
import {
  NotecardCommand,
  NotecardDeviceConnection,
  FirstHiddenReqID,
  LastHiddenReqID,
} from "../NotecardDevice/NotecardDeviceConnection";
import { NdjsonToJsonArray, RemoveNewlines } from "../ndjsonUtils";
import { CommandResult } from "../DeviceConnection";

let gaveFormatMessage = false;
export const FORMAT_MESSAGE = messageLines(
  "Each multi-line request object is sent on one line to conform to NDJSON."
);

let gaveIDMessage = false;
export const ID_MESSAGE = messageLines(
  `Responses are hidden in the In-Browser Terminal where (${FirstHiddenReqID} <= id <= ${LastHiddenReqID}).`
);

let gaveNdjsonMessage = false;
export const NDJSON_MESSAGE = messageLines(
  `This terminal sends one request at a time to conform to the Notecard API.`
);

export function resetMessages({
  formatMessage = false,
  ndjsonMessage = false,
  idMessage = false,
} = {}) {
  gaveFormatMessage = formatMessage;
  gaveNdjsonMessage = ndjsonMessage;
  gaveIDMessage = idMessage;
}

const requestWasReformatted = (request: string, formattedRequest: string) =>
  formattedRequest !== request;

const maybeFormatMessage = (request: string, formattedRequest: string) => {
  let give = !gaveFormatMessage;
  give = give && requestWasReformatted(request, formattedRequest);
  gaveFormatMessage = give || gaveFormatMessage;
  return give ? FORMAT_MESSAGE : [];
};

const requestHasHiddenID = (request: string) => {
  const { id } = JSON.parse(request);
  return id >= FirstHiddenReqID && id <= LastHiddenReqID;
};

const maybeIDMessage = (request: string) => {
  let give = !gaveIDMessage;
  give = give && requestHasHiddenID(request);
  gaveIDMessage = give || gaveIDMessage;
  return give ? ID_MESSAGE : [];
};

const maybeNDJsonMessage = (requestCount: number) => {
  let give = !gaveNdjsonMessage;
  give &&= requestCount > 1;
  gaveNdjsonMessage ||= give;
  return give ? NDJSON_MESSAGE : [];
};

const maybeMessages = (request: string, formattedRequest: string) => {
  const messages: HistoryLine[] = [
    ...maybeFormatMessage(request, formattedRequest),
    ...maybeIDMessage(formattedRequest),
  ];
  return messages;
};

export default class Ndjson implements NotecardCommand {
  name = "ndjson";

  triggeredBy(input: string) {
    // eslint-disable-next-line no-restricted-syntax
    for (const request of NdjsonToJsonArray(input).requests) {
      const r = RemoveNewlines(request);
      let j: JSON;
      try {
        j = JSON.parse(r);
      } catch (e) {
        // found a non-json
        return false;
      }
      if (typeof j !== "object" || Array.isArray(j)) {
        // found non-object json
        return false;
      }
    }
    // each ndjson item was an object
    return true;
  }

  copy(): Ndjson {
    return new Ndjson();
  }

  async perform(
    deviceConnection: Pick<NotecardDeviceConnection, "performTransaction">,
    input: string
  ): Promise<CommandResult> {
    const { requests } = NdjsonToJsonArray(input);
    const messages = maybeNDJsonMessage(requests.length);
    // eslint-disable-next-line no-restricted-syntax
    for (const request of requests) {
      const r = RemoveNewlines(request);
      messages.push(...maybeMessages(request, r));
      // eslint-disable-next-line no-await-in-loop
      await deviceConnection.performTransaction(r, false);
    }
    return { history: messages };
  }
}
