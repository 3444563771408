import { TextDecoderStream, TransformStream } from "@web-std/stream";
import { Mutex } from "async-mutex";
import { KeyboardEvent } from "react";

import { AnalyticsGateway } from "@components/analytics/AnalyticsGateway";

import LineAggregator from "../LineAggregator";
import JsonTransformer from "../JsonTransformer";
import DeviceConnection, {
  Command,
  CommandResult,
  Status,
} from "../DeviceConnection";
import { HistoryLine, HistoryLineSource, lines } from "../HistoryLine";
import { SerialPort } from "../WebSerialStandard";
import { StatusMonitorTask } from "../tasks/StatusMonitorTask";
import { NotecardHubSyncStatusResponse } from "./NotecardSyncStatusInterpreter";
import { DeviceBackgroundTask } from "../tasks/DeviceBackgroundTask";
import { NotecardSyncStatusMonitor } from "./NotecardSyncStatusMonitor";
import { JsonObject } from "./NotecardAPIValidation";

const debug = false;
// eslint-disable-next-line no-console
const debugLog = debug ? console.log : () => {};
// eslint-disable-next-line no-console
const debugTrace = debug ? console.trace : () => {};

export interface NotecardCommand extends Command<NotecardDeviceConnection> {}

// A recognizable place for us to start numbering our requests for
// synchronization purposes. I've tested notecard can go as high as
// 2**32-1 or about 4 billion.
export const FirstTrackedReqID: number = 2_000_000_000;
export const FirstHiddenReqID: number = 2_000_000_000;
export const LastHiddenReqID: number = 2_499_999_999;
export const FirstShownReqID: number = 2_500_000_000;
export const LastShownReqID: number = 2_999_999_999;
export const LastTrackedReqID: number = 2_999_999_999;

const flowControl = {
  software: { writeChunkSizeMax: 250, writeChunkPauseMs: 250 },
  hardware: { writeChunkSizeMax: 1024, writeChunkPauseMs: 5 },
};

const cmdPauseMs = 250;
const requestFlightPatienceMs = 5_000; // how long to wait for a response before guessing it's never getting a response.
const firstBootPatienceMs = 15_000; // first boot might be slow

const CLEAN_DISCONNECT = "clean disconnect";
const isCleanDisconnect = (e: any) =>
  JSON.stringify(e) === JSON.stringify([CLEAN_DISCONNECT, CLEAN_DISCONNECT]);

type NotecardAPIObject = {
  req?: string;
  id?: number;
  [key: string]: unknown;
};

function chunks(message: string, chunkSize: number): Array<string> {
  const result = [] as string[];
  for (let i = 0; i < message.length; i += chunkSize) {
    result.push(message.slice(i, i + chunkSize));
  }
  return result;
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function parseAPIObject(request: string): NotecardAPIObject {
  const j = JSON.parse(request);
  if (
    !j ||
    typeof j !== "object" ||
    Array.isArray(j) ||
    !["string", "undefined"].includes(typeof j.req) ||
    !["number", "undefined"].includes(typeof j.id)
  ) {
    throw new Error("could not parse notecard request");
  }
  const o: NotecardAPIObject = j;
  return o;
}

/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */

export class NotecardDeviceConnection implements DeviceConnection {
  /**
   * The command instances that know how to handle text input. (json input, raw input, info...)
   */
  private lineCommands: NotecardCommand[];

  /**
   * The serial port used to communicate with the device.
   */
  private port?: SerialPort;

  private inputDonePromise?: Promise<void>;

  private jsonReader?: ReadableStreamDefaultReader;

  private allReader?: ReadableStreamDefaultReader<string>;

  public onError?: (e: Error) => void;

  public isLostConnection = (e: Error) =>
    /The device has been lost/.test(`${e}`) ||
    /The simulator connection has been lost/.test(`${e}`);

  public isFailureToConnect = (e: Error) => {
    debugLog("e", e);
    return /Failed to open {serial|simulator}? ?port/.test(`${e}`);
  };

  public onOutput?: (lines: HistoryLine[]) => void;

  public onDisconnect?: (() => void) | undefined;

  private outputDonePromise?: Promise<void>;

  private writer?: WritableStreamDefaultWriter;

  private backgroundTasks: Array<DeviceBackgroundTask> = [];

  private writeChunkSizeMax = flowControl.software.writeChunkSizeMax;

  private writeChunkPauseMs = flowControl.software.writeChunkPauseMs;

  public constructor(port: SerialPort, lineCommands: NotecardCommand[]) {
    this.port = port;
    this.lineCommands = lineCommands;
    const decoder = new TextDecoderStream();
    this.inputDonePromise = this.port.readable
      .pipeTo(decoder.writable)
      .catch((e: Error) => {
        if (!isCleanDisconnect(e)) {
          this.onError?.(e);
        }
      });

    this.writer = this.port.writable.getWriter();

    const [jsonStream, allStream] = decoder.readable.tee();

    this.jsonReader = jsonStream
      .pipeThrough(new TransformStream(new LineAggregator()))
      .pipeThrough(new TransformStream(new JsonTransformer()))
      .getReader();
    this.allReader = allStream
      .pipeThrough(new TransformStream(new LineAggregator()))
      .getReader();
  }

  public async start() {
    this.readJsonLoop();
    this.readAllLoop();
    await this.waitForAPIReady();
    this.optimizeFlowControl();
    this.displayCurrentSKUAndFWVersion();
    this.displayIccidImeiPuk();
    this.displayHintForWifi();
    this.displayHintForProductUID();
    this.runCommandHooksOnConnect();
  }

  private runCommandHooksOnConnect() {
    // run each linecommand
    this.lineCommands.forEach(async (command) => {
      if (command.onConnect) {
        const { history } = await command.onConnect(this);
        this.onOutput?.(history);
      }
    });
  }

  private async waitForAPIReady() {
    for (;;) {
      const req = `{"req": "echo","text":"hi"}`;
      try {
        // eslint-disable-next-line no-await-in-loop
        const { text } = await this.performTransaction(
          req,
          true,
          firstBootPatienceMs,
        );
        if (text === "hi") {
          break;
        }
      } catch (e: any) {
        // ignore
      }
      await sleep(1000); // eslint-disable-line no-await-in-loop
    }
  }

  private async optimizeFlowControl() {
    const featureTestRequest = `{"req":"card.binary"}`;
    const { err } = await this.performTransaction(featureTestRequest);

    if (err) {
      debugLog("Using Software Flow Control (default)");
    } else {
      debugLog("Using Hardware Flow Control");
      this.writeChunkSizeMax = flowControl.hardware.writeChunkSizeMax;
      this.writeChunkPauseMs = flowControl.hardware.writeChunkPauseMs;
    }
  }

  private async readJsonLoop() {
    for (;;) {
      if (!this.jsonReader) throw Error("jsonReader can't get reader");
      debugLog("reading from jsonReader");
      // eslint-disable-next-line no-await-in-loop
      const { value, done } = await this.jsonReader.read();
      debugLog("read from jsonReader", value, done);
      if (value && value.id && this.responsePromiseResolvers.get(value.id)) {
        debugLog(`got response ${JSON.stringify(value)}`);
        const resolver = this.responsePromiseResolvers.get(value.id);
        debugLog(`this.responsePromiseResolvers.delete(${value.id})`);
        this.responsePromiseResolvers.delete(value.id);
        resolver?.(value);
      }
      if (done) {
        this.jsonReader?.releaseLock();
        break;
      }
    }
  }

  private privateOnStatusChange?: (s: Status) => void;

  public set onStatusChange(callback: (s: Status) => void) {
    if (this.privateOnStatusChange)
      throw new Error("Can't set onStatusChange twice.");

    const getDeviceStatus = async () => {
      const req = `{"req": "hub.sync.status"}`;
      const status: NotecardHubSyncStatusResponse =
        await this.performTransaction(req);
      return status;
    };
    this.privateOnStatusChange = callback;
    const updateIntervalMs = 2000;

    this.backgroundTasks.push(
      new StatusMonitorTask(
        new NotecardSyncStatusMonitor(
          getDeviceStatus,
          this.privateOnStatusChange,
          updateIntervalMs,
        ),
      ),
    );
  }

  private shouldHideResponse(response: string): boolean {
    try {
      const { id } = parseAPIObject(response);
      return !!id && id >= FirstHiddenReqID && id <= LastHiddenReqID;
    } catch (e) {
      /* noop */
    }
    return false;
  }

  private removeTrackingID(response: string): string {
    try {
      const o = parseAPIObject(response);
      if (!!o.id && o.id >= FirstTrackedReqID && o.id <= LastTrackedReqID)
        delete o.id;
      return JSON.stringify(o);
    } catch (e) {
      /* noop */
    }
    return response;
  }

  private async readAllLoop() {
    for (;;) {
      if (!this.allReader) throw Error("readAllLoop can't get reader");
      const r = await this.allReader.read(); // eslint-disable-line no-await-in-loop
      const { done } = r;
      let { value } = r;
      if (value && !this.shouldHideResponse(value)) {
        value = this.removeTrackingID(value || "");
        if (value) {
          this.onOutput?.(lines(HistoryLineSource.Output, value, true));
        }
      }
      if (done) {
        this.allReader?.releaseLock();
        break;
      }
    }
  }

  public async write(toWrite: string) {
    if (this.port === undefined || this.writer === undefined) {
      throw new Error("cannot write to notecard");
    }
    // eslint-disable-next-line no-restricted-syntax
    for await (const chunk of chunks(toWrite, this.writeChunkSizeMax)) {
      debugLog(`writing ${chunk.length} characters`);
      await this.writer.write(Buffer.from(chunk, "utf-8"));
      debugLog(`pausing ${this.writeChunkPauseMs}ms`);
      await sleep(this.writeChunkPauseMs);
    }
  }

  private async writeBytes(bytes: Buffer) {
    if (this.port === undefined || this.writer === undefined) {
      throw new Error("cannot write bytes to notecard");
    }

    for (let i = 0; i < bytes.byteLength; i += this.writeChunkSizeMax) {
      const toWrite = bytes.subarray(i, i + this.writeChunkSizeMax);
      debugLog(`writing ${toWrite.byteLength} bytes starting at ${i}`);
      await this.writer.write(toWrite); // eslint-disable-line no-await-in-loop
      debugLog(`pausing ${this.writeChunkPauseMs}ms`);
      await sleep(this.writeChunkPauseMs); // eslint-disable-line no-await-in-loop
    }
  }

  private previousHiddenReqID: number = LastHiddenReqID;

  private previousShownReqID: number = LastShownReqID;

  private nextTrackingID(hidden: boolean): number {
    if (hidden) {
      this.previousHiddenReqID += 1;
      if (this.previousHiddenReqID > LastHiddenReqID)
        this.previousHiddenReqID = FirstHiddenReqID;
      return this.previousHiddenReqID;
    }
    // Shown
    this.previousShownReqID += 1;
    if (this.previousShownReqID > LastShownReqID)
      this.previousShownReqID = FirstShownReqID;
    return this.previousShownReqID;
  }

  private ensureRequestHasTrackingID(request: string, hidden: boolean) {
    const req = parseAPIObject(request);
    if (typeof req.id !== "number") {
      req.id = this.nextTrackingID(hidden);
    }
    return {
      request: JSON.stringify(req),
      id: req.id,
    };
  }

  private transactionLock = new Mutex();

  private timeoutPromise(timeoutMs: number) {
    return new Promise((_, reject) =>
      setTimeout(() => reject(new Error("{timeout}")), timeoutMs),
    );
  }

  private handleTransactionError(e: any, req: string, timeoutMs: number) {
    // bail if we hit timeout because we're disconnecting/disconnected/closed
    if (this.isDisconnecting) {
      return { err: `terminal is disconnecting during transaction: ${req}` };
    }
    const reqSummary = JSON.stringify(req).replace(/(.{42})..+/, "$1…");
    if (e.message === "{timeout}") {
      return { err: `terminal timeout in ${timeoutMs}ms on ${reqSummary}` };
    }
    return { err: `terminal transaction error: ${e}` };
  }

  // performTransaction is safe to call when you don't know if there are any
  // transactions in flight. It uses a mutex lock to be sure of that. However,
  // if the request is outstanding for more than timeoutMs the lock will be
  // unlocked so as not to block everyone else forever. In that case, you'll
  // eventually get your response but the callers who come after you might never
  // get their responses because the notecard ignores requests that come in
  // while it's processing a request already.
  public async performTransaction(
    req: string,
    hidden = true,
    timeoutMs = requestFlightPatienceMs,
    largeBinary?: Buffer,
  ): Promise<any> {
    const releaseLock = await this.transactionLock.acquire();
    try {
      const response = await Promise.race([
        this.performTransactionUnlocked(req, hidden, largeBinary),
        this.timeoutPromise(timeoutMs),
      ]);
      return response;
    } catch (e: unknown) {
      return this.handleTransactionError(e, req, timeoutMs);
    } finally {
      releaseLock();
    }
  }

  private async performTransactionUnlocked(
    r: string,
    hidden = true,
    largeBinary?: Buffer,
  ): Promise<any> {
    const { request, id } = this.ensureRequestHasTrackingID(r, hidden);
    if (this.isDisconnecting) {
      return { err: "terminal is disconnecting" };
    }
    if (!this.jsonReader)
      throw Error("performTransactionUnlocked can't get reader");
    const responsePromise = this.responsePromiseForID(id);
    debugLog(`sending ${request}`);
    await this.write(`${request}\n`);
    if (parseAPIObject(r).cmd) {
      // Not expecting a response. Just pause 250ms to allow the notecard time
      // to process the command.
      await sleep(cmdPauseMs);
      return "";
    }
    debugLog(`waiting for response to ${id}`);
    const response = await responsePromise;
    debugLog(`got response to ${id} "${JSON.stringify(response)}"`);
    if (largeBinary) {
      await this.writeBytes(largeBinary);
      await this.writeBytes(Buffer.from("\n"));
    }
    return response;
  }

  private responsePromiseResolvers = new Map<
    number,
    (value: PromiseLike<JsonObject>) => void
  >();

  private responsePromiseForID(id: number) {
    return new Promise<JsonObject>((resolve) => {
      this.responsePromiseResolvers.set(id, resolve);
    });
  }

  private isDisconnecting = false;

  public async disconnect() {
    if (this.isDisconnecting) {
      debugTrace("already disconnecting");
      return;
    }

    this.isDisconnecting = true;

    this.backgroundTasks = this.backgroundTasks.filter((task) => {
      task.cleanup();
      return false;
    });

    const error = (e: Error) => {
      debugLog("Error while disconnecting: ", e); // eslint-disable-line
    };

    this.jsonReader?.cancel(CLEAN_DISCONNECT).catch(error);
    this.allReader?.cancel(CLEAN_DISCONNECT).catch(error);
    await this.writer?.close().catch(error);
    this.allReader?.releaseLock();
    this.jsonReader?.releaseLock();
    this.writer?.releaseLock();
    await this.inputDonePromise?.catch(error);
    await this.outputDonePromise?.catch(error);
    await this.port?.close().catch(error);

    this.writer = undefined;
    this.jsonReader = undefined;
    this.allReader = undefined;
    this.port = undefined;

    AnalyticsGateway().trackPageContext({ notecard: undefined });

    this.onDisconnect?.();
  }

  public handleKey(_key: KeyboardEvent<Element>): boolean {
    return false;
  }

  public async evaluateInput(
    input: string,
    onStatusChange: (s: Status) => void,
  ): Promise<CommandResult> {
    let out: CommandResult = { history: [] };

    const command = this.lineCommands.find((c) => c.triggeredBy(input));
    if (!command) {
      return out;
    }

    try {
      AnalyticsGateway().trackEvent(`REPL Command \`${command.name}\``);
      out = await command.copy().perform(this, input, onStatusChange);
    } catch (error: any) {
      this.onError?.(error);
    }

    return out;
  }

  private async displayCurrentSKUAndFWVersion() {
    const req = `{"req": "card.version"}`;
    const { err, device, sku, version } = await this.performTransaction(req);

    if (err) {
      return;
    }

    const semVer = version?.replace("notecard-", "");

    const message = `DeviceUID ${device} (${sku}) running firmware ${semVer}`;

    AnalyticsGateway().trackEvent(`REPL Device SKU: ${sku}`);
    AnalyticsGateway().trackEvent(`REPL Device Firmware: ${version}`);
    AnalyticsGateway().trackPageContext({
      notecard: { sku, firmwareVersion: version },
    });

    this.onOutput?.(lines(HistoryLineSource.Message, message, true));
  }

  private async displayIccidImeiPuk() {
    const req = `{"req": "card.test"}`;
    const { err, iccid, imei, key } = await this.performTransaction(req);

    if (err) {
      return;
    }

    if (!key) {
      return;
    }

    const message = `ICCID: ${iccid}, IMEI: ${imei}, PUK: ${key}`;

    this.onOutput?.(lines(HistoryLineSource.Message, message, true));
  }

  private async displayHintForProductUID() {
    const req = `{"req": "hub.get"}`;
    const { err, product } = await this.performTransaction(req);

    if (err) {
      return;
    }

    if (!product) {
      const message =
        `Set your Notehub ProductUID with` +
        ` {"req": "hub.set", "product": "com.example.product"}`;

      this.onOutput?.(lines(HistoryLineSource.HintMarkdown, message, true));
    }
  }

  private async displayHintForWifi() {
    const req = `{"req": "card.wifi"}`;
    const { err, ssid, version } = await this.performTransaction(req);

    if (err || !version) {
      return;
    }

    if (!ssid) {
      const message =
        `Set your 2.4GHz Wi-Fi access point and password with` +
        ` {"req": "card.wifi", "ssid": "YOUR-NETWORK", "password": "YOUR-PASSWORD"}`;

      this.onOutput?.(lines(HistoryLineSource.HintMarkdown, message, true));
    }
  }
}
