import { Config } from "@configured/EnvironmentConfig";
import DeviceConnection from "./DeviceConnection";
import { SerialConnection, isNotecardDevice } from "./SerialConnection";
import GenericDeviceConnection from "./GenericDeviceConnection";
import { LiveCommands } from "./commands/LiveCommands";
import SimulatorPort from "./SimulatorPort";
import { SerialPort, SerialPortInfo } from "./WebSerialStandard";
import { NotecardDeviceConnection } from "./NotecardDevice/NotecardDeviceConnection";
import {
  ConnectionRequest,
  Connector,
  DeviceType,
} from "./REPLDrivenInterfaces";

const PID_SWAN = 2;

const defaultConnectionRequest: ConnectionRequest = {
  type: "serial",
  doNotPromptUser: false,
  onDisconnect: () => {},
};

/**
 * This class knows how to connect to a device. It uses the WebSerial API  or a
 * simulator to open a Serial connection to the device. The device connection
 * behavior is determined by the USB Vendor and Product ID.
 */
export default class DeviceConnector implements Connector {
  // connect should be static but Typesript doesn't allow static methods in
  // interfaces like Connector.
  // eslint-disable-next-line class-methods-use-this
  async connect(
    connectionRequest = defaultConnectionRequest
  ): Promise<DeviceConnection | null> {
    let connection: DeviceConnection | undefined;

    const simulate = connectionRequest.type === "simulator";

    const queryParams = new URLSearchParams(window.location.search);
    const queryDeviceType: string | null = queryParams.get("deviceType");

    let port: SerialPort | null = null;
    if (simulate) {
      const simPort = new SimulatorPort({
        userID: connectionRequest.userID,
        softcardServiceURL: Config.softcardServiceURL,
      });
      try {
        await simPort.open();
        port = simPort;
      } catch (e) {
        port = null;
      }
    } else {
      const serialConnection = new SerialConnection();
      const connectionOptions = {
        allowAllDevices: queryDeviceType === "all",
        doNotPromptUser: connectionRequest.doNotPromptUser,
      };
      port = await serialConnection.connect(connectionOptions);
      serialConnection.addEventListener(
        "disconnect",
        connectionRequest.onDisconnect
      );
    }

    if (port) {
      const info = port.getInfo();
      const deviceType: DeviceType =
        DeviceConnector.deviceTypeFromString(queryDeviceType);
      connection = DeviceConnector.createConnection(port, info, deviceType);
      return connection;
    }
    return null;
  }

  static deviceTypeFromString(queryDeviceType: string | null): DeviceType {
    let deviceType: DeviceType = DeviceType.NONE;
    if (queryDeviceType) {
      deviceType = (<any>DeviceType)[queryDeviceType.toUpperCase()];
    }
    return deviceType || DeviceType.UNKNOWN;
  }

  static deviceTypeFromPort(
    port: SerialPort,
    info: SerialPortInfo
  ): DeviceType {
    if (isNotecardDevice(port)) {
      return DeviceType.NOTECARD;
    }
    if (info.usbProductId === PID_SWAN) {
      return DeviceType.SWAN;
    }
    return DeviceType.UNKNOWN;
  }

  static createConnectionForDeviceType(
    port: SerialPort,
    info: SerialPortInfo,
    deviceType: DeviceType
  ): DeviceConnection {
    switch (deviceType) {
      case DeviceType.NOTECARD:
        return new NotecardDeviceConnection(port, LiveCommands);
      case DeviceType.SWAN:
        return new GenericDeviceConnection(port);
      default:
        throw Error("The terminal does not support this type of device.");
    }
  }

  /**
   * Creates a device connection to the given serial port. The type of device is determined first via
   * deviceTypeFromQuery and if that doesn't specify a device, deviceTypeFromPort
   * @param port
   * @param info
   */
  static createConnection(
    port: SerialPort,
    info: SerialPortInfo,
    deviceTypeOverride: DeviceType
  ): DeviceConnection {
    let deviceType: DeviceType = deviceTypeOverride || DeviceType.NONE;
    if (deviceType === DeviceType.NONE) {
      deviceType = DeviceConnector.deviceTypeFromPort(port, info);
    }
    return DeviceConnector.createConnectionForDeviceType(
      port,
      info,
      deviceType
    );
  }
}
