import DeviceConnection from "./DeviceConnection";

export enum DeviceType {
  NONE = "none",
  UNKNOWN = "unknown",
  NOTECARD = "notecard",
  SWAN = "swan",
}

export type SerialConnectionRequest = {
  type: "serial";
  doNotPromptUser: boolean;
  onDisconnect: () => void;
};

export type SimulatorConnectionRequest = { type: "simulator"; userID: string };

export type ConnectionRequest =
  | SerialConnectionRequest
  | SimulatorConnectionRequest;

export interface Connector {
  connect(r: ConnectionRequest): Promise<DeviceConnection | null>;
}
