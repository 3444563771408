/* eslint-disable no-await-in-loop */
import {
  HistoryLine,
  errorLines,
  hintMarkdownLines,
  messageLines,
} from "../HistoryLine";
import {
  NotecardCommand,
  NotecardDeviceConnection,
} from "../NotecardDevice/NotecardDeviceConnection";
import { CommandResult } from "../DeviceConnection";

/* eslint-disable class-methods-use-this */

const COMMAND = "sync-trace";
const SECONDS_TO_LOG_AFTER_SYNC = 5;
const SYNC_COMPLETION_POLLING_INTERVAL_MS = 5_000;

export default class SyncTrace implements NotecardCommand {
  name = COMMAND;

  deviceConnection?: NotecardDeviceConnection;

  message: HistoryLine[] = [];

  output: HistoryLine[] = [];

  suggestedCommand = "";

  triggeredBy(request: string) {
    return request === COMMAND;
  }

  copy(): SyncTrace {
    return new SyncTrace();
  }

  appendHint(lines: string) {
    this.output.push(...hintMarkdownLines(lines));
  }

  appendMessage(lines: string) {
    this.output.push(...messageLines(lines));
  }

  appendError(lines: string) {
    this.message.push(...errorLines(lines));
  }

  async deviceRequest(toSend: string) {
    if (!this.deviceConnection) throw new Error(`error: no device connection`);

    const json = await this.deviceConnection.performTransaction(toSend);

    if (json.err) {
      const e = `${toSend}: ${json.err}`;
      throw new Error(e);
    }

    return json;
  }

  async waitForSyncCompletion() {
    const sleepMs = (ms: number) => new Promise((r) => setTimeout(r, ms));
    let completed: number | undefined;
    while ((completed || -1) < SECONDS_TO_LOG_AFTER_SYNC) {
      await sleepMs(SYNC_COMPLETION_POLLING_INTERVAL_MS);
      ({ completed } = await this.deviceRequest(
        `{ "req": "hub.sync.status" }`
      ));
    }
  }

  async syncTrace() {
    const isSyncDisabled =
      (await this.deviceRequest(JSON.stringify({ req: "hub.get" }))).mode ===
      "off";

    if (isSyncDisabled) {
      this.appendError(
        'Notehub sync is disabled ("mode":"off"). Enable sync and try again.'
      );
      this.appendHint(
        'To enable sync, set another "mode"; for example, use {"req":"hub.set","mode":"minimum"}'
      );
      this.suggestedCommand = `{"req":"hub.set","mode":"minimum"}`;
      return;
    }

    await this.deviceRequest(`{ "req": "card.trace", "mode": "on" }`);
    await this.deviceRequest(`{ "req": "hub.sync", "allow":true }`);

    await this.waitForSyncCompletion();

    await this.deviceRequest(`{ "req": "card.trace", "mode": "off" }`);

    this.appendMessage(
      "Diagnostic sync complete. Use the Save button above to save the " +
        "results to a file and then send the file to support."
    );
  }

  async perform(
    deviceConnection: NotecardDeviceConnection
    /* request: string */
  ): Promise<CommandResult> {
    this.deviceConnection = deviceConnection;

    try {
      await this.syncTrace();
    } catch (error) {
      this.appendError(`sync-trace failed; please try again: ${error}`);
    }

    this.output = this.output.concat(this.message);
    return { history: this.output, inputTemplate: this.suggestedCommand };
  }
}
