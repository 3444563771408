import { forwardRef, useContext } from "react";
import {
  REPLWidget,
  REPLRef,
  REPLWidgetProps,
} from "@components/repl/REPLWidget";
import { AuthContext } from "@adapters/AuthContextProvider";

function getDisplayName(WrappedComponent: any) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

// eslint-disable-next-line import/prefer-default-export
export const AuthAwareREPL = (WrappedREPLComponent: typeof REPLWidget) => {
  const component: typeof REPLWidget = forwardRef<REPLRef, REPLWidgetProps>(
    (props: REPLWidgetProps, ref) => {
      const auth = useContext(AuthContext);
      const user = auth.userInfo;

      return (
        <WrappedREPLComponent
          {...props}
          userIsSignedIn={user.signedIn}
          userAccountUID={user.accountUID}
          onSignInClick={auth.authActions.signIn}
          onSignUpClick={auth.authActions.signUp}
          ref={ref}
        />
      );
    }
  );
  component.displayName = `AuthAwareREPL ${getDisplayName(
    WrappedREPLComponent
  )}`;
  return component;
};
