import {
  AnalyticsGatewayInterface,
  PageContext,
  TrackedUser,
} from "@use-cases/AnalyticsGatewayInterface";
import { InitSegmentAnalyticsJS } from "./SegmentAnalyticsEmbed";

declare global {
  interface Window {
    // will be added to the window by SegmentAnalyticsJS
    analytics: SegmentAnalytics.AnalyticsJS; // make typescript smarter
  }
}

const debugSegmentAnalytics = false;
// eslint-disable-next-line no-console
const debugLog = debugSegmentAnalytics ? console.log : () => {};

const GDPRMask = { context: { ip: "0.0.0.0" } };

function labelForGoogleAnalytics(details?: Record<string, any>) {
  if (details && !details.label) details.label = JSON.stringify(details);
}

class SegmentAnalyticsGateway implements AnalyticsGatewayInterface {
  private identifiedUser?: TrackedUser;

  private environmentName?: string;

  private context: PageContext = {};

  constructor(writeKey: string, environmentName: string) {
    this.environmentName = environmentName;

    InitSegmentAnalyticsJS(writeKey);
    if (!window || !window?.analytics) {
      throw new Error("SegmentAnalyticsGateway: trouble initializing");
    }
  }

  private identifyIfChanged(user: TrackedUser) {
    debugLog("identifyIfChanged");
    if (user.uid !== this.identifiedUser?.uid) {
      debugLog("identify because user changed");
      analytics.identify(
        user.uid,
        {
          name: user.name,
          email: user.email,
          supportSLA: user.supportSLA,
        },
        GDPRMask
      );
    }
    this.identifiedUser = user;
  }

  identifyUser(user: TrackedUser) {
    debugLog("identifyUser", user);
    this.identifyIfChanged(user);
  }

  // eslint-disable-next-line class-methods-use-this
  trackEvent(name: string, details?: Record<string, any>) {
    labelForGoogleAnalytics(details);
    debugLog("trackEvent", name, details);
    analytics.track(name, details, GDPRMask);
  }

  trackPageContext(newContext: PageContext) {
    this.context = {
      ...this.context,
      ...newContext,
    };
    const p = {
      hash: document.location.hash,
      environment: this.environmentName,
      ...this.context,
    };
    debugLog("trackPageContext", p);
    analytics.page(p, GDPRMask);
  }
}

export { SegmentAnalyticsGateway };
