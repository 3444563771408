import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

const secondsToShowSyncAsComplete = 5;

const debug = false;
const debugLog = debug ? console.log : () => {}; // eslint-disable-line no-console

// Any hub.sync.status message containing the following {status-codes} will be
// replaced with a more actionable hint.
const HINTS = new Map([
  [
    /.*\{product-noexist\}.*/,
    `Hint: Set your ProductUID with {"req":"hub.set","product":"com.example.product"}.`,
  ],
]);

const FRACTION_FOR_STATUS_CODE: Record<string, number> = {
  // Physical
  "{modem-off}": 0, // .......................wifi./.cellular."mode"

  // Hardware
  "{wait-module}": 0.1,
  "{modem-on}": 0.1, // ......................wifi./.cellular."mode"

  // Physical
  //  Wifi Search
  "{wifi-join-wait}": 0.2, // ................wifi............"mode"
  //  Cell Search
  "{cell-scan}": 0.2, // ............................cellular."mode"
  //  Cell Found
  "{wait-service}": 0.3,
  "{cell-registration-wait}": 0.3, // ...............cellular."mode"

  // Data Link
  "{cell-registered}": 0.6, // ......................cellular."mode"

  "{wait-data}": 0.6,

  // LoRa uses this a lot
  "{sync}": 0.6,

  // Session attempt
  "{socket-ip-init}": 0.7,
  "{socket-open-session}": 0.7,
  "{socket-connecting}": 0.7,
  "{socket-tls-connecting}": 0.7,
  "{socket-connected}": 0.7,

  // Notehub connection
  "{network-up}": 1, // ......................wifi./.cellular."mode"

  // Notehub sync
  "{notehub-connected}": 1,
  "{sync-get-remote-changes}": 1,
  "{sync-get-local-changes}": 1,

  // The following status codes don't really tell us anything about 'progress'

  // {sync-end} means that we're done in all cases of a) successful, b) sync
  // attempted but there was nothing to sync, and c) sync failed
  "{sync-end}": 0,
  "{socket-disconnected}": 0,
  "{disconnected}": 0,
  "{transport}": 0,
  "{cell-disconnected}": 0,
  // Errors
  "{transport-unreachable}": 0,
  "{product-noexist}": 0,
  "{extended-service-failure}": 0,
  "{extended-network-failure}": 0, // used on LoRa
  "{sync-error}": 0, // used on LoRa
};

export interface NotecardHubSyncStatusResponse {
  // The status of the current or previous sync. Combination of English and {status-codes}
  status?: string;

  // Mode contains a single machine readable value, limited to one of only 7
  // values.  Intended to contain the key status information that a host CPU
  // would want to know.
  //
  // Cellular notecard
  // * {modem-off}    The modem is not powered on.
  // * {modem-on} The modem is powered on either during initialization (before
  //   sync) or deinitialization (after sync).
  // * {cell-scan} The modem is performing a cellular triangulation scan (see
  //   card.triangulate). This can take up to 2 minutes.
  // * {cell-registration-wait} The modem is attempting to register with the
  //   cellular system.
  // * {cell-registered} The modem is registered but does not yet have a data
  //   connection.
  // * {network-up}  The Notecard has a data connection (IP address).
  //
  // WIFI notecard
  // * {modem-off}    The WIFI is not powered on.
  // * {modem-on} The WIFI is powered on either during initialization (before
  //   sync) or deinitialization (after sync).
  // * {wifi-join-wait} The WIFI is searching for the configured SSID.
  // * {network-up}  The WIFI has a data connection  (IP address).
  //
  // https://bluesinc.atlassian.net/wiki/spaces/HW/pages/161251344/Updated+hub.sync.status+1.24.23
  mode?: string;

  // UNIX Epoch time - Time of the last sync completion. Will only populate if
  // the Notecard has completed a sync to Notehub to obtain the time.
  time?: number;

  // an error occurred during the most recent sync. (in older firmware, it is
  // also set after a no-op sync)
  alert?: boolean;

  // notecard has unsynchronized notes, or requires a sync to set its internal clock.
  sync?: boolean;

  // integer - seconds since the last sync completion.
  completed?: number;

  // integer - Number of seconds since the last explicit sync request.
  requested?: number;
}

export type SyncStatusDetails = NotecardHubSyncStatusResponse;

export function Quantify(s: SyncStatusDetails): number {
  const foundCodes = Object.keys(FRACTION_FOR_STATUS_CODE).filter(
    (code) => s.status?.includes(code) || s.mode?.includes(code)
  );
  let value = Math.max(
    0,
    ...foundCodes.map((code) => FRACTION_FOR_STATUS_CODE[code])
  );
  if (s.completed && s.completed <= secondsToShowSyncAsComplete) value = 1;
  debugLog(
    // eslint-disable-next-line prefer-template
    (foundCodes.length ? `` : `###### NO CODE: `) +
      `value:${value.toFixed(1)} ${JSON.stringify(s)}`
  );
  return value;
}

const friendly = (str: string) => {
  const curlyCodes = /\{[a-z-]*\}/g;
  const capitalizeFirstLetter = (s: string) =>
    s.charAt(0).toUpperCase() + s.slice(1);

  return capitalizeFirstLetter(
    str
      .replaceAll("was {connected}", "was connected")
      .replaceAll(curlyCodes, "")
      .replaceAll("[----]", "[----] 0/4 bars cell signal")
      .replaceAll("[+---]", "[+---] 1/4 bars cell signal")
      .replaceAll("[++--]", "[++--] 2/4 bars cell signal")
      .replaceAll("[+++-]", "[+++-] 3/4 bars cell signal")
      .replaceAll("[++++]", "[++++] 4/4 bars cell signal")
      .replaceAll("  ", " ")
      .trim()
  );
};

const age = (seconds: number) => {
  TimeAgo.addLocale(en);
  const timeAgo = new TimeAgo("en-US");
  return timeAgo.format(
    new Date(Date.now() - seconds * 1000),
    "mini"
  ) as string;
};

function hintForStatus(statusText = "") {
  let result = "";
  HINTS.forEach((hint, code) => {
    if (code.test(statusText)) {
      result = statusText.replace(code, hint);
    }
  });
  return result;
}

export function Summarize(s: SyncStatusDetails): string {
  // Hints are the most important
  const h = hintForStatus(s.status);
  if (h) {
    return h;
  }

  const isNewerFirmware = !!s.mode;
  const isAlertTrustworthy = isNewerFirmware;
  const signalBars = /\[[-+]{4}\]/;
  const isStatusConfusing =
    !isNewerFirmware && // E.g. I've seen `connected` even when we're not connected.
    !signalBars.test(s.status || ""); // even on older firmware, signal strength is not confusing

  const value = Quantify(s);
  const isInProgress = value > 0 && value < 1;

  // need
  const need = s.sync ? "Sync needed" : "";

  // sync
  let sync = "Notehub sync"; // In progress
  if (s.completed) sync = "Successful Notehub sync"; // Complete
  if (s.alert) sync = `Notehub sync failing${s.completed ? " since" : ""}`; // Failed
  if (!isAlertTrustworthy) sync = "Notehub sync attempt"; // Can't tell

  // when
  let when = "";
  if (s.completed) when = ` ${age(s.completed)} ago`;

  // humanDetails
  let elaboration = friendly(s.status || "");
  if (isStatusConfusing) elaboration = "";

  // anticipation
  let anticipation = "";
  if (isInProgress) anticipation = "...";

  // Be silent if nothing is interesting.
  if (!when && !elaboration && !anticipation) {
    return need;
  }

  return [need, `${sync}${when}`, `${elaboration}${anticipation}`]
    .filter((x) => !!x)
    .join(" — ")
    .trim();
}
