import { SerialPort } from "./WebSerialStandard";

// Vendor ID and Product ID of the Notecard. Found using:
// On Linux: $ lsusb # On Linux
// On Mac: https://stackoverflow.com/a/22043031
//
// 30a4:0001 Blues Wireless Notecard
export const BLUES_VENDOR_ID = 0x30a4;
export const MOST_NOTECARD_PRODUCT_ID = 0x0001;
// LoRa Notecards use a CP210x UART Bridge, which has a different vendor ID and
// product ID. It might be possible to reprogram the bridge to use our Blues
// VID/PID but until then, this will have to do.
//
// 10c4:ea60 Silicon Labs CP210x UART Bridge
export const SILABS_VENDOR_ID = 0x10c4;
export const NOTE_LORA_UART_BRIDGE_ID = 0xea60;

export const NOTECARD_PRODUCTS = [
  { usbProductId: MOST_NOTECARD_PRODUCT_ID, usbVendorId: BLUES_VENDOR_ID },
  { usbProductId: NOTE_LORA_UART_BRIDGE_ID, usbVendorId: SILABS_VENDOR_ID },
];
export const NOTECARD_BAUD_RATE = 115200;

const DEFAULT_OPTIONS = {
  baudrate: NOTECARD_BAUD_RATE, // Chrome 84-
  baudRate: NOTECARD_BAUD_RATE, // Chrome 86+
};

const NOTECARD_FILTER = {
  filters: NOTECARD_PRODUCTS,
};

const ALL_DEVICES_FILTER = {};

export const isNotecardDevice = (port: SerialPort) => {
  const { usbProductId, usbVendorId } = port.getInfo();
  return NOTECARD_FILTER.filters.some(
    (f) => f.usbProductId === usbProductId && f.usbVendorId === usbVendorId
  );
};

const getPairedPort = async (
  allowAllDevices: boolean
): Promise<SerialPort | null> => {
  const allPorts = await navigator.serial.getPorts();
  const allowed = (port: SerialPort) =>
    allowAllDevices || isNotecardDevice(port);
  const allowedPorts = allPorts.filter(allowed);
  const closedPorts = allowedPorts.filter((port) => !port.connected);
  if (closedPorts.length > 1) {
    return null;
  }
  return closedPorts[0] || null;
};

const getUnpairedPort = async (
  allowAllDevices: boolean
): Promise<SerialPort | null> =>
  navigator.serial.requestPort(
    allowAllDevices ? ALL_DEVICES_FILTER : NOTECARD_FILTER
  );

type ConnectionOptions = {
  allowAllDevices?: boolean;
  doNotPromptUser?: boolean;
};

export class SerialConnection {
  port: SerialPort | null = null;

  async connect(opt: ConnectionOptions = {}): Promise<SerialPort | null> {
    this.port = await getPairedPort(!!opt.allowAllDevices);
    if (!this.port && !opt.doNotPromptUser)
      this.port = await getUnpairedPort(!!opt.allowAllDevices);

    if (!this.port) return null;

    try {
      await this.port.open(DEFAULT_OPTIONS);
    } catch (e: any) {
      throw new Error(`Failed to open port: ${e}`);
    }
    return this.port;
  }

  addEventListener(name: "disconnect", handler: () => void) {
    navigator.serial.addEventListener("disconnect", (e) => {
      if (e.target === this.port) handler();
    });
  }
}
