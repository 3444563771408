/* eslint-disable no-await-in-loop */

import { Md5 } from "ts-md5";

// ported from github.com/blues/note-cli/notecard/dfu.go on 2023-04-18

export type FirmwareInfo = {
  name?: string; // "notecard-4.2.1.4015688$20230227005219.bin",
  length?: number; // 895474,
  md5?: string; // "f4c8ee422540de40edd51da7c4b72497",
  crc32?: number; // 2994681955,
  created?: number; // 1677459139,
  modified?: number; // 1677459139,
  source?: string; // "notecard-4.2.1.4015688.bin",
  type?: string; // "notecard",
  tags?: string; // "publish",
  notes?: string; // "Recommended LTS (Long Term Support) Release",
  firmware?: FirmwareVersion;
};

export type FirmwareVersion = {
  org?: string; // "Blues Wireless",
  product?: string; // "Notecard",
  version?: string; //  "notecard-4.2.1",
  target?: string; // "r5",
  ver_major?: number; // 4,
  ver_minor?: number; // 2,
  ver_patch?: number; // 1,
  ver_build?: number; // 4015688,
  built?: string; // "Feb 24 2023 11:48:13"
};

interface DfuPutRequest {
  cmd?: "dfu.put"; // use for last chunk
  req?: "dfu.put";
  offset: number;
  length: number;
  payload?: string; // unset if binary=true when using COBS
  binary?: boolean;
  status: string;
}
interface DfuPutResponse {
  err?: string;
  pending?: boolean;
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export type Chunk = {
  payload: string;
  status: string; // pre-COBS md5
  md5: string; // post-COBS md5
};

export type FirmwareChunkGetter = (
  name: string,
  offset: number,
  len: number,
  compressionMode: string,
) => Promise<Chunk>;

// Use tuples to make typescript strict (to avoid function bivariance)
export type TransactFn = ([req, payload]: [string, Buffer?]) => Promise<any>;

// Current fw can't do sideload DFU
export class NotecardFirmwareSupportError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "NotecardFirmwareSupportError";
  }
}

// Side-load a binary image
export async function loadBin(
  firmwareInfo: FirmwareInfo,
  deviceTransact: TransactFn,
  getChunk: FirmwareChunkGetter,
  reportStatus: (english: string, fraction: number) => void,
  binaryMax: number,
): Promise<{ seconds: number; bytes: number }> {
  if (!firmwareInfo.length) throw new Error("firmwareInfo.length is undefined");
  if (!firmwareInfo.name) throw new Error("firmwareInfo.name is undefined");

  const totalLen: number = firmwareInfo.length;

  // Issue the first request, which is to initiate the DFU put
  let chunkLen = 0;
  let compressionMode = "";
  for (;;) {
    const req = { req: "dfu.put", body: firmwareInfo };
    const rsp = (await deviceTransact([JSON.stringify(req)])) as {
      err?: string;
      mode: string;
      length?: number;
    };
    if (rsp.err) throw new Error(`Could not initiate dfu.put: ${rsp.err}`);
    if (!("length" in rsp) || !rsp.length)
      throw new NotecardFirmwareSupportError("no length in dfu.put response");

    // By default, use the chunk length being supplied to us by the notecard
    compressionMode = rsp.mode;
    chunkLen = rsp.length;

    // If we support binary, use the binary maximum for performance & reliability.
    // Note that we are guaranteed that if we support large binaries that the
    // notecard will tell us not to use compression.
    if (binaryMax > 0) {
      compressionMode = "cobs";
      chunkLen = binaryMax;
    }

    // Occasionally because of comms being out-of-sync (because of killing
    // loadBin etc.) we get a response that doesn't have the appropriate
    // fields because we are out of sync.  This is defensive
    // coding that ensures that we don't proceed until we get in sync.
    if (chunkLen > 0) {
      break;
    }
    await sleep(750);
  }

  // Send the chunk to sideload
  let offset = 0;
  let lenRemaining = totalLen;
  const beganSecs = Date.now() / 1000;
  while (lenRemaining > 0) {
    // Determine how much to send
    let thisLen = lenRemaining;
    if (thisLen > chunkLen) {
      thisLen = chunkLen;
    }
    const isLastChunk = thisLen === lenRemaining;

    // Send the chunk
    const fraction = 1 - lenRemaining / totalLen;
    reportStatus(
      `side-loading ${thisLen} bytes (${lenRemaining} remaining)`,
      fraction,
    );

    const chunk = await getChunk(
      firmwareInfo.name,
      offset,
      thisLen,
      compressionMode,
    );
    const req: DfuPutRequest = {
      [isLastChunk ? "cmd" : "req"]: "dfu.put",
      offset,
      length: thisLen,
      payload: chunk.payload,
      status: chunk.status,
    };

    // If we're doing binary, do the transaction
    if (binaryMax > 0) {
      // Encode COBS
      // The chunk from notehub is already cobs-encoded before it's base64 encoded.
      const payloadCOBS = Buffer.from(chunk.payload, "base64");
      const expectedMD5 = chunk.md5;
      const actualMD5 = new Md5().appendByteArray(payloadCOBS).end();
      if (actualMD5 !== expectedMD5) {
        throw new Error(
          `firmware chunk md5 ${actualMD5} doesn't match expectation ${expectedMD5}`,
        );
      }

      // Send the COBS data to the notecard The COBS code would make some more
      // sense in NotecardDeviceConnection. I'm leaving it here to match dfu.go.
      interface CardBinaryPutRequest {
        req: "card.binary.put";
        cobs: number;
      }
      interface CardBinaryPutResponse {
        err?: string;
      }

      const req2: CardBinaryPutRequest = {
        req: "card.binary.put",
        cobs: payloadCOBS.byteLength,
      };
      const rsp2 = (await deviceTransact([
        JSON.stringify(req2),
        payloadCOBS,
      ])) as CardBinaryPutResponse;
      if (rsp2.err) {
        throw new Error(`card.binary.put error: ${rsp2.err}`);
      }

      // Verify that the binary made it to the notecard
      interface CardBinaryResponse {
        err?: string;
        length?: number;
      }
      const req3 = { req: "card.binary" };
      const rsp3 = (await deviceTransact([
        JSON.stringify(req3),
      ])) as CardBinaryResponse;
      if (rsp3.err) {
        throw new Error(`card.binary failed: ${rsp3.err}`);
      }
      if (rsp3.length !== thisLen)
        throw new Error(
          `notecard payload is insufficient (${thisLen} sent, ${rsp3.length} received)`,
        );

      // Now that it's been received successfully, remove the payload and
      // tell the notecard to fetch the payload from the large binary area.
      delete req.payload;
      req.binary = true;
    }

    // Perform the request
    let rsp = (await deviceTransact([JSON.stringify(req)])) as DfuPutResponse;
    if (rsp.err?.includes("{io}")) {
      // Just silently retry {io} errors
      reportStatus(`retrying after error: ${rsp.err}`, fraction);
      continue; // eslint-disable-line no-continue
    }
    if (rsp.err) throw new Error(`Could not continue dfu.put: ${rsp.err}`);

    // Move on to next chunk
    lenRemaining -= thisLen;
    offset += thisLen;

    // Wait until the migration succeeds
    while (rsp.pending) {
      rsp = (await deviceTransact([
        JSON.stringify({ req: "dfu.put" }),
      ])) as DfuPutResponse;
      if (rsp.err?.includes("{dfu-not-ready}") && lenRemaining === 0) {
        break;
      }
      if (rsp.err) throw new Error(`Could not finish dfu.put: ${rsp.err}`);
      await sleep(750);
    }
  }

  const elapsedSecs = Date.now() / 1000 - beganSecs + 1;
  const bytesPerSecond = totalLen / elapsedSecs;
  const status = `${elapsedSecs.toFixed()} seconds (${bytesPerSecond.toFixed()} Bps)`;
  reportStatus(status, 1);

  // Done
  return { seconds: elapsedSecs, bytes: totalLen };
}

// Side-loads a file to the DFU area of the notecard, to avoid download
export async function dfuSideload(
  fw: FirmwareInfo,
  getChunk: FirmwareChunkGetter,
  deviceTransact: TransactFn,
  reportStatus: (english: string, fraction: number) => void,
) {
  // Do a card.binary transaction to see if the notecard is capable of
  // doing binary sideloads, and if so, how large.
  const binary = (await deviceTransact([
    `{"req":"card.binary","verify":true}`,
  ])) as {
    err?: string;
    max: number;
    verify?: boolean;
  };
  // Get the maximum size that the notecard can handle
  const binaryMax = binary.max;

  // If problems arise w/o it, port the following time code to typescript.

  // Sideloading on the Notecard requires that the Notecard's time is set.  This means that
  // in order to sideload, the Notecard might normally need a ProductUID configured and would
  // need to talk to the cloud.  Since this would also mean that the SIM would be provisioned,
  // we clearly could not do this at point of manufacture.  As such, this code uses a feature
  // whereby the Notecard's time can be set if and only if it hasn't yet been set.  We don't
  // trust the time of the local PC, so instead we fetch it from Notehub.
  /*
	epochTime, err := notehubTime()
	if err != nil {
		return
	}
	_, err = card.TransactionRequest(notecard.Request{Req: "card.time", Time: epochTime})
	if err != nil {
		return
	}
  */

  // Do the write
  reportStatus(`sending DFU binary to notecard`, 0);
  const ret = await loadBin(
    fw,
    deviceTransact,
    getChunk,
    reportStatus,
    binaryMax,
  );

  // Done
  reportStatus(`sideload completed`, 1);
  return ret;
}
