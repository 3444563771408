const debugEnvironmentConfig = false;

const debugLog = (message: string) => {
  // eslint-disable-next-line no-console
  if (debugEnvironmentConfig) console.log(message);
};

// eslint-disable-next-line @typescript-eslint/no-implied-eval
const isBrowser = new Function(
  "try {return this===window;}catch(e){ return false;}",
);

/*
  Note: In order to keep server-only secrets safe, Next.js replaces process.env.*
  with the correct values at build time. This means that process.env is not a
  standard JavaScript object, so you're not able to use object destructuring.
  Environment variables must be referenced as e.g.
  process.env.NEXT_PUBLIC_PUBLISHABLE_KEY, not const { NEXT_PUBLIC_PUBLISHABLE_KEY
  } = process.env.
*/
const envVarDefault = <T>(
  varName: string,
  envVal: T | undefined,
  defaultValue: T,
) => {
  if (!envVal) {
    debugLog(
      `Maybe export ${varName} or set it in the right .env file. Using default: ${defaultValue}`,
    );
    return defaultValue;
  }
  debugLog(`${varName}=${envVal}`);
  return envVal;
};

const Config = {
  announceKitWidgetURL: envVarDefault(
    "NEXT_PUBLIC_ANNOUNCEKIT_WIDGET_URL",
    process.env.NEXT_PUBLIC_ANNOUNCEKIT_WIDGET_URL,
    "",
  ),
  algoliaApiKey: envVarDefault(
    "NEXT_PUBLIC_ALGOLIA_API_KEY",
    process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
    "",
  ),
  algoliaAppId: envVarDefault(
    "NEXT_PUBLIC_ALGOLIA_APP_ID",
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    "",
  ),
  algoliaIndexName: envVarDefault(
    "NEXT_PUBLIC_ALGOLIA_INDEX_NAME",
    process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME,
    "",
  ),
  buildVersion: envVarDefault(
    "NEXT_PUBLIC_BUILD_VERSION",
    process.env.NEXT_PUBLIC_BUILD_VERSION,
    "0.0",
  ),
  domainsTrackedAsInternal: envVarDefault(
    "NEXT_PUBLIC_DOMAINS_TRACKED_AS_INTERNAL",
    process.env.NEXT_PUBLIC_DOMAINS_TRACKED_AS_INTERNAL,
    "blues.tools blues.com airnote.live",
  ).split(" "),
  envName: envVarDefault(
    "NEXT_PUBLIC_ENV_NAME",
    process.env.NEXT_PUBLIC_ENV_NAME,
    "",
  ),
  googleTagManagerID: envVarDefault(
    "NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID",
    process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
    "",
  ),
  imageCdnUrl: envVarDefault(
    "NEXT_PUBLIC_IMAGE_CDN_URL",
    process.env.NEXT_PUBLIC_IMAGE_CDN_URL,
    "",
  ),
  isBrowser: isBrowser(),
  hubspotNewsletterFormID: envVarDefault(
    "NEXT_PUBLIC_HUBSPOT_NEWSLETTER_FORM_ID",
    process.env.NEXT_PUBLIC_HUBSPOT_NEWSLETTER_FORM_ID,
    "",
  ),
  hubspotPartnerFormID: envVarDefault(
    "NEXT_PUBLIC_HUBSPOT_PARTNER_FORM_ID",
    process.env.NEXT_PUBLIC_HUBSPOT_PARTNER_FORM_ID,
    "",
  ),
  hubspotPortalID: envVarDefault(
    "NEXT_PUBLIC_HUBSPOT_PORTAL_ID",
    process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID,
    "",
  ),
  hubspotRegion: envVarDefault(
    "NEXT_PUBLIC_HUBSPOT_REGION",
    process.env.NEXT_PUBLIC_HUBSPOT_REGION,
    "",
  ),
  notehubAuthBase: envVarDefault(
    "NEXT_PUBLIC_NOTEHUB_AUTH_BASE",
    process.env.NEXT_PUBLIC_NOTEHUB_AUTH_BASE,
    "https://staging.blues.tools",
  ),
  notehubReqApiUrl: envVarDefault(
    "NEXT_PUBLIC_NOTEHUB_REQ_API_URL",
    process.env.NEXT_PUBLIC_NOTEHUB_REQ_API_URL,
    "https://api.staging.blues.tools/req",
  ),
  segmentEnabled: envVarDefault(
    "NEXT_PUBLIC_SEGMENT_ENABLE",
    process.env.NEXT_PUBLIC_SEGMENT_ENABLE?.toLowerCase() === "true",
    false,
  ),
  segmentEnvironment: envVarDefault(
    "NEXT_PUBLIC_SEGMENT_ENVIRONMENT",
    process.env.NEXT_PUBLIC_SEGMENT_ENVIRONMENT,
    "staging",
  ),
  segmentWriteKey: envVarDefault(
    "NEXT_PUBLIC_SEGMENT_WRITE_KEY",
    process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    "",
  ),
  siteURL: envVarDefault(
    "NEXT_PUBLIC_SITE_URL",
    process.env.NEXT_PUBLIC_SITE_URL,
    "",
  ),
  softcardServiceURL: envVarDefault(
    "NEXT_PUBLIC_SOFTCARD_SERVICE_URL",
    process.env.NEXT_PUBLIC_SOFTCARD_SERVICE_URL,
    "",
  ),
};

export { Config };
