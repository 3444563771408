import theme from "@config/theme";

const Help = () => (
  <div className="helpContainer">
    <strong>Having trouble connecting?</strong>
    <p>
      Try changing your USB cable{" "}
      <a href="https://www.quora.com/Are-some-USB-cables-for-power-only-no-data-Is-there-a-quick-way-to-tell-by-looking">
        as some cables do not support transferring data
      </a>
      . If that does not solve your problem, contact us at{" "}
      <a href="mailto:support@blues.com">support@blues.com</a> and we will get
      you set up with another tool to communicate with the Notecard.
    </p>
    <strong>Advanced Usage</strong>
    <p>
      The <code>help</code> command gives more info.
    </p>
    <style jsx>{`
      .helpContainer {
        padding: 1rem;
        background-color: ${theme.colors.darkCharlestonGreen};
      }
    `}</style>
  </div>
);

export default Help;
