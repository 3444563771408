/* eslint-disable no-await-in-loop */
import { docopt } from "docopt";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

import { HistoryLine, errorLines, messageLines } from "../HistoryLine";
import {
  NotecardCommand,
  NotecardDeviceConnection,
} from "../NotecardDevice/NotecardDeviceConnection";
import { CommandResult } from "../DeviceConnection";
import { newTable } from "./utils/format";

/* eslint-disable class-methods-use-this */

const COMMAND = "explore";

// This string drives the arg parser!
const DOC = `Usage:
  explore [--reserved|-a]
  explore [--deleted|-d] <notefile>...`;

type FileExt = "qo" | "qos" | "qi" | "qis" | "db" | "dbs";

const FILE_TYPE = {
  qo: "Outgoing Queue",
  qos: "Outgoing Queue (Secure)",
  qi: "Incoming Queue",
  qis: "Incoming Queue (Secure)",
  db: "Bidirectional Database",
  dbs: "Bidirectional Database (Secure)",
  qx: "Local Queue",
  dbx: "Local Database",
};

type FileChangesResponse = {
  total?: number;
  changes?: number;
  info?: {
    [notefileName: string]: {
      changes?: number;
      total?: number;
    };
  };
};

type NoteChangesResponse = {
  total?: number;
  changes?: number;
  notes?: {
    [noteID: string]: {
      body?: object;
      deleted?: boolean;
      payload?: string;
      time?: number;
    };
  };
};

export default class Explore implements NotecardCommand {
  name = COMMAND;

  deviceConnection?: NotecardDeviceConnection;

  output: HistoryLine[] = [];

  triggeredBy(request: string) {
    return request === COMMAND || request.startsWith(`${COMMAND} `);
  }

  copy(): Explore {
    return new Explore();
  }

  appendMessage(lines: string) {
    this.output.push(...messageLines(lines));
  }

  appendError(lines: string) {
    this.output.push(...errorLines(lines));
  }

  async deviceRequest(toSend: Record<string, any>) {
    if (!this.deviceConnection) throw new Error(`error: no device connection`);

    const response = await this.deviceConnection.performTransaction(
      JSON.stringify(toSend)
    );

    if (response.err) {
      throw new Error(response.err);
    }
    return response;
  }

  async exploreFiles(includeReserved: boolean) {
    const req = { req: "file.changes", allow: includeReserved };

    const { info } = (await this.deviceRequest(req)) as FileChangesResponse;

    if (!info) {
      this.appendMessage("0 Notefiles");
      return;
    }

    this.appendMessage(`${Object.keys(info).length} Notefiles`);

    const table = newTable({
      head: ["File", "Notes", "Type"],
    });

    Object.keys(info)
      .sort()
      .map((name) => {
        const ext = name.split(".")[1] as FileExt;
        const notes = `${info[name].total || ""}`;
        const type = FILE_TYPE[ext] || "??";
        table.push([name, notes, type]);
        return true;
      });

    this.appendMessage(table.toString());
  }

  async getAllNotes(
    notefile: string,
    deleted: boolean
  ): Promise<NoteChangesResponse> {
    const req = {
      req: "note.changes",
      file: notefile,
      allow: true,
      max: 100,
      deleted,
    };
    return (await this.deviceRequest(req)) as NoteChangesResponse;
  }

  async exploreNotes(notefile: string, showDeletedNotes: boolean) {
    let changes: NoteChangesResponse;

    try {
      changes = await this.getAllNotes(notefile, showDeletedNotes);
    } catch (e) {
      this.appendError(`${e}`);
      return;
    }

    const total = changes.total || 0;
    const pending = changes.changes || 0;

    this.appendMessage(
      `${notefile}: ${total} Notes${pending ? ` (${pending} pending)` : ""}`
    );

    if (!total) return;

    TimeAgo.addLocale(en);
    const timeAgo = new TimeAgo("en-US");

    const deletedHeader = showDeletedNotes ? ["Del."] : [];
    const table = newTable({
      head: [...deletedHeader, "Age", "ID", "Body (Payload)"],
    });

    Object.entries(changes.notes || {})
      .sort((a, b) => (b[1].time || 0) - (a[1].time || 0))
      .map(([noteID, note]) => {
        const bodyAndPayload: string[] = [];
        if (note.body) bodyAndPayload.push(JSON.stringify(note.body));
        if (note.payload) bodyAndPayload.push(`(${note.payload})`);
        const deletedCell: string[] = showDeletedNotes
          ? [note.deleted ? "X" : ""]
          : [];
        const time = note.time
          ? (timeAgo.format(new Date(note.time * 1000), "mini") as string)
          : "";
        table.push([...deletedCell, time, noteID, bodyAndPayload.join(" ")]);
        return true;
      });

    this.appendMessage(table.toString());
  }

  async perform(
    deviceConnection: NotecardDeviceConnection,
    request: string
  ): Promise<CommandResult> {
    this.deviceConnection = deviceConnection;

    const argv = request
      .split(" ")
      .filter((x) => x !== "")
      .slice(1);

    let opts: any;
    try {
      opts = docopt(DOC, { argv, exit: false, help: false });
    } catch (e: any) {
      this.appendError(e.toString());
      return { history: this.output };
    }
    const showReservedFiles = opts["--reserved"] || opts["-a"];
    const showDeletedNotes = opts["--deleted"] || opts["-d"];
    const notefiles = opts["<notefile>"] as string[];

    if (opts["<notefile>"].length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const notefile of notefiles) {
        await this.exploreNotes(notefile, showDeletedNotes);
      }
    } else {
      await this.exploreFiles(showReservedFiles);
    }

    return { history: this.output };
  }
}
