/* eslint-disable no-await-in-loop */
import { HistoryLine, errorLines, messageLines } from "../HistoryLine";
import {
  NotecardCommand,
  NotecardDeviceConnection,
} from "../NotecardDevice/NotecardDeviceConnection";
import { CommandResult } from "../DeviceConnection";

/* eslint-disable class-methods-use-this */

const COMMAND = "factory-reset";

export default class FactoryReset implements NotecardCommand {
  name = COMMAND;

  deviceConnection?: NotecardDeviceConnection;

  output: HistoryLine[] = [];

  triggeredBy(request: string) {
    return request === COMMAND;
  }

  copy(): FactoryReset {
    return new FactoryReset();
  }

  appendMessage(lines: string) {
    this.output.push(...messageLines(lines));
  }

  appendError(lines: string) {
    this.output.push(...errorLines(lines));
  }

  async deviceRequest(toSend: string) {
    if (!this.deviceConnection) throw new Error(`error: no device connection`);

    const json = await this.deviceConnection.performTransaction(toSend);

    if (json.err) {
      const e = `${toSend}: ${json.err}`;
      this.appendError(e);
      throw new Error(e);
    }

    return json;
  }

  async perform(
    deviceConnection: NotecardDeviceConnection
  ): Promise<CommandResult> {
    this.deviceConnection = deviceConnection;

    const resetReq = `{"req":"card.restore","delete":true}`;

    await this.deviceRequest(resetReq);

    this.appendMessage(`Factory reset (i.e. ${resetReq}) will:
- clear all notes
- reset most settings
- restart`);

    return { history: this.output };
  }
}
