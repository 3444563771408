/* eslint-disable react/require-default-props */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import theme from "@config/theme";
import REPLHeader, { ConnectionButtonProps } from "./REPLHeader";
import { REPLHistory } from "./History";
import { DownloadString } from "./downloadString";
import { REPLInputBar } from "./InputBar";
import { REPLModal } from "./Modals";
import { ConnectionType, REPL } from "./REPL";
import Progress from "./Progress";

export type REPLWidgetProps = {
  repl: REPL;
  hideCloseButton?: boolean;
  hideSaveButton?: boolean;
  isFullscreen?: boolean;
  noHeader?: boolean;
  onCloseClick?: () => void;
  onConnectAttempt?: () => void;
  onConnected?: (details: { type: ConnectionType }) => void;
  onConnectFailed?: (error: string) => void;
  onConnectionChange?: (isConnected: boolean) => void;
  onDisconnectAttempt?: () => void;
  onDisconnected?: () => void;
  onDisconnectFailed?: (error: string) => void;
  onInputChange?: () => void;
  onFullscreenToggleClick?: () => void;
  onRequestSent?: (request: string) => void;
  onSaveClick?: () => void;
  onSignInClick?: () => void;
  onSignUpClick?: () => void;
  onUnsupportedBrowser?: () => void;
  userAccountUID?: string;
  userIsSignedIn?: boolean;
};

export type REPLRef = {
  setInput: (req: string) => void;
  setInputFocus: () => void;
};

const debug = false;
// eslint-disable-next-line no-console
const debugLog = debug ? console.log : () => {};

export const lineBufferUpdateIntervalMs = 50;

const onlyOnBrowser = useEffect;

export const REPLWidget = forwardRef<REPLRef, REPLWidgetProps>(
  (props: REPLWidgetProps, ref) => {
    const setReplChangeCounter = useState<number>(0)[1];
    const handleReplChange = () => {
      setReplChangeCounter((oldCount: number) => oldCount + 1);
    };

    const { repl } = props;

    const [isSerialSupported, setIsSerialSupported] = useState(true);
    onlyOnBrowser(() => setIsSerialSupported(repl.isSerialSupported()), [repl]);
    const [isSimSupported, setIsSimSupported] = useState(true);
    onlyOnBrowser(() => setIsSimSupported(repl.isSimSupported()));

    repl.userAccountUID = props.userAccountUID;
    repl.onChange = handleReplChange; // todo useRef or useCallback?
    repl.onConnectAttempt = props.onConnectAttempt;
    repl.onConnectFailed = props.onConnectFailed;
    repl.onConnectionChange = props.onConnectionChange;
    repl.onDisconnectAttempt = props.onDisconnectAttempt;
    repl.onDisconnected = props.onDisconnected;
    repl.onDisconnectFailed = props.onDisconnectFailed;
    repl.onRequestSent = props.onRequestSent;
    repl.onUnsupportedBrowserDetected = props.onUnsupportedBrowser;

    // REPL
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const sleep = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    const setInputFocus = async () => {
      if (!inputRef.current) return;
      await sleep(100); // WHY is this necessary?
      debugLog("setInputFocus");
      inputRef.current.focus();
      debugLog("setInputFocus: focus", inputRef.current);
    };
    repl.onInputChange = setInputFocus;

    const imperativeHandle: REPLRef = {
      setInput: (text: string) => repl.setInput(text),
      setInputFocus,
    };

    useImperativeHandle(ref, () => imperativeHandle);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.altKey || event.ctrlKey || event.shiftKey || event.metaKey) {
        // Allow modified [Enter] to insert an actual newline.
        // Allow modified arrows to navigate within the input box.
      } else if (event.key === "Enter") {
        // Normal [Enter] sends the request.
        event.preventDefault();
        repl.sendRequest(repl.input);
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        repl.commandHistoryGoBack();
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        repl.commandHistoryGoForward();
      }
    };

    // TODO (carl): find a way to not have to define this on every render.
    repl.onConnected = (details) => {
      setInputFocus();
      props.onConnected?.(details);
    };

    const connectUSB = () => repl.connectUSB();
    const connectSimulator = () => repl.connectSimulator();
    const disconnect = () => repl.disconnect();

    useEffect(() => {
      const cleanup = () => repl.disconnect();
      return cleanup;
    }, [repl]);

    const save = () => {
      props.onSaveClick?.();
      DownloadString(
        repl.historyAsString(),
        "text/plain",
        `notecard_${new Date().toISOString().split(".")[0]}.log`
      );
    };

    // View Model for Connect/Disconnect Buttons
    const isUSBConnected = repl.connectionState === "serial";
    const usbSupported = isSerialSupported;
    const isSimConnected = repl.connectionState === "simulator";
    const simSupported = isSimSupported;

    const connectionButtonsViewModel: Array<ConnectionButtonProps> = [
      {
        text: "USB",
        border: !usbSupported ? "gray" : "none",
        background: !usbSupported ? "none" : isUSBConnected ? "light" : "dark", // eslint-disable-line no-nested-ternary
        isDepressed: isUSBConnected,
        mouseCursor: !usbSupported ? "default" : "pointer",
        dotColor: !usbSupported ? "gray" : isUSBConnected ? "green" : "red", // eslint-disable-line no-nested-ternary
        enabled: usbSupported,
        fontColor: !usbSupported ? "gray" : isUSBConnected ? "black" : "white", // eslint-disable-line no-nested-ternary
        onClick: isUSBConnected ? disconnect : connectUSB,
      },
      {
        text: "Simulator (Beta)",
        border: !simSupported ? "gray" : "none",
        background: !simSupported ? "none" : isSimConnected ? "light" : "dark", // eslint-disable-line no-nested-ternary
        isDepressed: isSimConnected,
        mouseCursor: !simSupported ? "default" : "pointer",
        dotColor: !simSupported ? "gray" : isSimConnected ? "green" : "red", // eslint-disable-line no-nested-ternary
        enabled: simSupported,
        fontColor: !simSupported ? "gray" : isSimConnected ? "black" : "white", // eslint-disable-line no-nested-ternary
        onClick: isSimConnected ? disconnect : connectSimulator,
      },
    ];

    // View Model for Connection Status Text
    const isAnyConnectionTypeSupported = usbSupported || simSupported;
    const connectionStatusText = !isAnyConnectionTypeSupported
      ? "Unsupported"
      : {
          disconnected: "Notecard Disconnected",
          simulator: "Simulator Connected",
          serial: "Notecard USB Connected",
          lost: "Notecard Lost",
        }[repl.connectionState];

    return (
      <>
        <div
          className={`REPL ${
            repl.isConnected() ? "connected" : "disconnected"
          }`}
          data-testid="REPL"
        >
          {!props.noHeader && (
            <REPLHeader
              serialSupported={isSerialSupported}
              connected={repl.isConnected()}
              connectionStatusText={`${connectionStatusText}`}
              connectionButtons={connectionButtonsViewModel}
              hideHelp={!isSerialSupported}
              hideCloseButton={!!props.hideCloseButton}
              isFullscreen={!!props.isFullscreen}
              onCloseClick={props.onCloseClick}
              onFullscreenToggleClick={props.onFullscreenToggleClick}
              hideSaveButton={!!props.hideSaveButton}
              onSaveClick={save}
            />
          )}
          {Array.from(repl.statusBars).map(([key, value]) => (
            <Progress
              key={key}
              message={value.message}
              fraction={value.fraction}
            />
          ))}
          <REPLModal
            connected={repl.isConnected()}
            onConnectUSBClick={connectUSB}
            onConnectSimulatorClick={connectSimulator}
            onSignInClick={props.onSignInClick}
            onSignUpClick={props.onSignUpClick}
            userIsSignedIn={!!props.userIsSignedIn}
            error={repl.error}
            serialSupported={isSerialSupported}
          />
          <REPLHistory
            REPLHistoryLog={repl.historyLog}
            lookDisabled={!repl.isConnected()}
            onClick={setInputFocus}
            lineBufferUpdateIntervalMs={lineBufferUpdateIntervalMs}
          />
          <div className="inputContainer">
            <REPLInputBar
              enabled={repl.isConnected()}
              onChange={(e) => {
                repl.setInput(e.target.value);
              }}
              onKeyDown={handleKeyDown}
              onSubmit={(txt) => repl.sendRequest(txt)}
              ref={inputRef}
              value={repl.input}
            />
          </div>
          <style jsx>
            {`
              .REPL {
                align-content: stretch;
                display: flex;
                flex-direction: column;
                font-size: ${theme.fonts.size.px16};
                font-variant-ligatures: none; // for cell signal [----]
                height: 100%;
                left: 0;
                max-height: 100%;
                max-width: 100%;
                min-height: 100%;
                min-width: 100%;
                padding: 0;
                position: absolute;
                top: 0;
                transform: none;
                width: 100%;
              }
              .inputContainer {
                min-height: 60px;
                max-height: calc(100% - 200px);
              }

              .REPL :global(::-webkit-scrollbar) {
                width: 12px;
              }
              .REPL :global(::-webkit-scrollbar-thumb) {
                border-radius: 4px;
                background-color: ${theme.colors.mediumGray};
              }
              .REPL :global(::-webkit-scrollbar-thumb:hover) {
                background-color: ${theme.colors.gray};
              }
            `}
          </style>
        </div>
      </>
    );
  }
);

REPLWidget.displayName = `REPLWidget`;

export default REPLWidget;
