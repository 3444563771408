const newRecentSelections: (
  newPreferences: string[],
  previousPreferences: string[]
) => string[] = (newPreferences, previousPreferences) => [
    ...newPreferences,
    ...previousPreferences.filter(
      (selection) => !newPreferences.includes(selection)
    ),
  ];

const mostRecentlySelected: (
  available: string[],
  recentlySelected: string[]
) => string | undefined = (available, recentlySelected) => (recentlySelected || []).find((selection) =>
    available.includes(selection)
  );

export { newRecentSelections, mostRecentlySelected };
