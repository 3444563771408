import { docopt } from "docopt";
import { Md5 } from "ts-md5";

import { AnalyticsGateway } from "@components/analytics/AnalyticsGateway";

import { errorLines, messageLines } from "../HistoryLine";
import Ndjson from "./Ndjson";
import {
  NotecardCommand,
  NotecardDeviceConnection,
} from "../NotecardDevice/NotecardDeviceConnection";
import { CommandResult } from "../DeviceConnection";

const COMMAND = "import";

// This formal usage text drives the arg parser!
const DOC = `Import the requests found in an NDJSON-formatted script found at the <github-gist-url>. Only the first file in the gist is used.

Usage:
  ${COMMAND} [options] <github-gist-url>
  ${COMMAND} (--help|-h)

Options:
  --comment=<ignored>  Any meaningful text to let you differentiate scripts in your command history.
  --execute -x         Execute the script immediately.
  --help -h            Show this screen.
`;

type GistId = {
  gistID: string;
  sha?: string;
};

export function gistId(maybeURL: string): GistId | false {
  const acceptableHosts = [`gist.github.com`, "gist.githubusercontent.com"];

  let url: URL;
  try {
    url = new URL(maybeURL);
  } catch (error) {
    return false;
  }
  const { host, pathname } = url;

  let isGistURL: boolean = true; // assume for now
  isGistURL &&= acceptableHosts.includes(host);

  // remove `/raw` from url as it just gets in the way when we're looking for revisionID
  const slugs = pathname.split("/").filter((e) => e !== "raw");

  // grab gist id and revision sha
  const [, username, gistID, sha] = slugs;
  isGistURL &&= username?.length > 0;
  isGistURL &&= gistID?.length > 0;
  // sha is optional and will be undefined here if the url ran out of
  if (!isGistURL) {
    return false;
  }

  return { gistID, sha };
}

async function gistRawContent({ gistID, sha }: GistId): Promise<string> {
  // https://docs.github.com/en/rest/gists/gists#get-a-gist-revision
  type GistApiResponse = {
    files: { [name: string]: { truncated: boolean; content: string } };
  };
  const apiUrl = `https://api.github.com/gists/${gistID}${
    sha ? `/${sha}` : ""
  }`;
  const response = await fetch(apiUrl, {
    headers: [["accept", "application/vnd.github+json"]],
  });
  const resp = (await response.json()) as GistApiResponse;
  const firstFile = Object.values(resp.files)[0];
  if (firstFile.truncated) {
    throw new Error("gist content is too long");
  }
  return firstFile.content;
}

/* eslint-disable class-methods-use-this */
export default class Import implements NotecardCommand {
  name = COMMAND;

  deviceConnection?: NotecardDeviceConnection;

  output: CommandResult = { history: [] };

  triggeredBy(request: string) {
    return request === COMMAND || request.startsWith(`${COMMAND} `);
  }

  appendMessage(lines: string) {
    this.output.history.push(...messageLines(lines));
  }

  appendError(lines: string) {
    this.output.history.push(...errorLines(lines));
  }

  copy(): Import {
    return new Import();
  }

  parse(commandLine: string) {
    const argv = commandLine
      .split(" ")
      .filter((x) => x !== "")
      .slice(1);
    const opts = docopt(DOC, { argv, exit: false, help: false });
    const url: string = opts["<github-gist-url>"];
    const isHelp: boolean = opts["--help"] || opts["-h"];
    const execute: boolean = opts["--execute"] || opts["-x"];
    return { url, isHelp, execute };
  }

  async perform(
    deviceConnection: NotecardDeviceConnection,
    command: string
  ): Promise<CommandResult> {
    this.deviceConnection = deviceConnection;

    let url: string;
    let isHelp: boolean;
    let execute: boolean;
    try {
      ({ url, isHelp, execute } = this.parse(command));
    } catch (e: any) {
      this.appendError(e.toString());
      return this.output;
    }

    const urlMD5 = Md5.hashStr(url); // hash the url to anonymize it
    AnalyticsGateway().trackEvent(`REPL Import`, { urlMD5, execute });

    if (isHelp) {
      this.appendMessage(DOC);
      return this.output;
    }

    const id = gistId(url);
    if (!id) {
      this.appendError("URL format is not recognized");
      return this.output;
    }

    const requests = await gistRawContent(id);

    if (execute) {
      await new Ndjson().perform(deviceConnection, requests);
      this.appendMessage("Script complete");
      return this.output;
    }

    this.output.inputTemplate = requests;

    return this.output;
  }
}
