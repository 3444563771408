import { AnalyticsGatewayInterface } from "@use-cases/AnalyticsGatewayInterface";

const placeholder = () => {
  throw new Error("No impl has been set with SetAnalyticsGatewayImpl(gateway)");
};

let analyticsGateway: AnalyticsGatewayInterface = {
  identifyUser: placeholder,
  trackEvent: placeholder,
  trackPageContext: placeholder,
};

export const SetAnalyticsGatewayImpl = (gateway: AnalyticsGatewayInterface) => {
  analyticsGateway = gateway;
};

export const AnalyticsGateway = () => analyticsGateway;
