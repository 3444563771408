import { Settings, SettingsAdapter } from "@use-cases/GetSettingsInteractor";

class LocalStorageSettings implements SettingsAdapter {
  LOCAL_SETTINGS_KEY = "localSettings";

  fetchSettings(): Settings | undefined {
    if (window.localStorage.getItem(this.LOCAL_SETTINGS_KEY) !== null) {
      const serialized = window.localStorage.getItem(this.LOCAL_SETTINGS_KEY);
      if (serialized) {
        return JSON.parse(serialized);
      }
    }
  }

  storeSettings(settings: Settings) {
    window.localStorage.setItem(
      this.LOCAL_SETTINGS_KEY,
      JSON.stringify(settings)
    );
  }
}

export { LocalStorageSettings };
