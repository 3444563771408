// NdjsonToJsonArray takes a pseudo-ndjson (ndjson, but where each json value is
// an object and each object may contain newlines.) and breaks it on the
// inter-object newlines.
export const NdjsonToJsonArray = (requests: string) => {
  // Lookbehind for }, find some whitespace maybe, at least one newline, maybe
  // more whitespace, and finally lookahead for {
  // Rewritten without regex lookahead or lookbehind as those broke Safari.
  const mark = "//SPLIT-HERE//";
  const splitsMarked = requests.replaceAll(/\}\s*\n+\s*\{/g, `}${mark}{`);
  const strings = splitsMarked.split(mark);
  return { requests: strings };
};

export const RemoveNewlines = (s: string) => s.replace(/[\n\r]/g, "");
