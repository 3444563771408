export type Settings = {
  recentSelections: string[];
  closedBanners: string[];
};

export type SettingsAdapter = {
  storeSettings: (settings: Settings) => void;
  fetchSettings: () => Settings | undefined;
};

export interface GetSettings {
  readonly get: () => Settings;
  readonly set: (settings: Settings) => void;
}

export class GetSettingsInteractor implements GetSettings {
  settingsAdapter: SettingsAdapter;

  constructor(settingsAdapter: SettingsAdapter) {
    this.settingsAdapter = settingsAdapter;
  }

  get(): Settings {
    return {
      ...DEFAULT_LOCAL_SETTINGS,
      ...this.settingsAdapter.fetchSettings(),
    };
  }

  set(settings: Settings) {
    this.settingsAdapter.storeSettings(settings);
  }
}

export const DEFAULT_LOCAL_SETTINGS: Settings = {
  recentSelections: [],
  closedBanners: [],
};
