import { Status } from "../DeviceConnection";
import { StatusMonitorTaskDefinition } from "../tasks/StatusMonitorTask";
import {
  NotecardHubSyncStatusResponse,
  Quantify,
  Summarize,
} from "./NotecardSyncStatusInterpreter";

export class NotecardSyncStatusMonitor
  implements StatusMonitorTaskDefinition<NotecardHubSyncStatusResponse>
{
  taskName = "NotecardSyncStatusMonitor";

  estimateProgress = Quantify;

  summarize = Summarize;

  constructor(
    public getDeviceStatus: () => Promise<NotecardHubSyncStatusResponse>,
    public onStatusChange: (s: Status) => void,
    public updateIntervalMs = 2000
  ) {}
}

export {};
