/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useState, useEffect, Suspense, ReactNode } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { Tooltip } from "react-tooltip";

import theme from "@config/theme";
import REPLPositioner from "@components/REPLPositioner";
import { useWindowWidth } from "@react-hook/window-size";
import useScrollbarSize from "react-scrollbar-size";
import { IntegratedREPL } from "@components/integrated/IntegratedREPL";
import { BluesErrorBoundary } from "@components/BluesErrorBoundary";
import { CREPL } from "@configured/use-cases/CREPL";
import { Config } from "@configured/EnvironmentConfig";

// global styles to prevent them needing to be listed in the jsx styling below
import globalStyles from "@styles/globalStyles";
import StandardTooltip from "@components/doc/StandardTooltip";

type LayoutProps = {
  children: ReactNode;
};

export const layoutContextObj = {
  // Left Menu
  menuCollapsed: true,
  setMenuCollapsed: (val: boolean) => {},
  menuDocked: false,
  setMenuDocked: (val: boolean) => {},

  // REPL
  // TODO: move this
  REPLCollapsed: true,
  setREPLCollapsed: (val: boolean) => {},
  REPLFullSize: false,
  setREPLFullSize: (val: boolean) => {},
  REPLConnected: false,
  setREPLConnected: (val: boolean) => {},
  hideREPL: false,
  setHideREPL: (val: boolean) => {},

  // Warning banner for mobile devices
  isWarningBannerShowing: false,
  setIsWarningBannerShowing: (val: boolean) => {},

  // Announcement banner state
  isAnnouncementBannerShowing: false,
  setIsAnnouncementBannerShowing: (val: boolean) => {},

  isMobileTopNavMenuOpen: false,
  setIsMobileTopNavMenuOpen: (val: boolean) => {},
};

export const LayoutContext = createContext(layoutContextObj);

const Layout = (props: LayoutProps) => {
  const scrollBarWidth = useScrollbarSize().width;
  const windowWidth = useWindowWidth({ leading: true }) + scrollBarWidth;

  const [menuCollapsed, setMenuCollapsed] = useState(
    layoutContextObj.menuCollapsed,
  );
  const [menuDocked, setMenuDocked] = useState(layoutContextObj.menuDocked);
  const [REPLCollapsed, setREPLCollapsed] = useState(
    layoutContextObj.REPLCollapsed,
  );
  const [REPLFullSize, setREPLFullSize] = useState(
    layoutContextObj.REPLFullSize,
  );
  const [REPLConnected, setREPLConnected] = useState(
    layoutContextObj.REPLConnected,
  );
  const [hideREPL, setHideREPL] = useState(layoutContextObj.hideREPL);

  const [isWarningBannerShowing, setIsWarningBannerShowing] = useState(
    layoutContextObj.isWarningBannerShowing,
  );

  const [isAnnouncementBannerShowing, setIsAnnouncementBannerShowing] =
    useState(layoutContextObj.isAnnouncementBannerShowing);

  const [isMobileTopNavMenuOpen, setIsMobileTopNavMenuOpen] = useState(
    layoutContextObj.isMobileTopNavMenuOpen,
  );

  const router = useRouter();
  const route = router.query;
  const { pathname } = router;

  const layoutContextVals = {
    // Left Menu
    menuCollapsed,
    setMenuCollapsed,
    menuDocked,
    setMenuDocked,

    // REPL
    REPLCollapsed,
    setREPLCollapsed,
    REPLFullSize,
    setREPLFullSize,
    REPLConnected,
    setREPLConnected,
    hideREPL,
    setHideREPL,

    // Warning Banner
    isWarningBannerShowing,
    setIsWarningBannerShowing,

    // Announcement Banner
    isAnnouncementBannerShowing,
    setIsAnnouncementBannerShowing,

    // mobile version of top nav menu
    isMobileTopNavMenuOpen,
    setIsMobileTopNavMenuOpen,
  };

  useEffect(() => {
    // window resized
    // hide repl for mobile
    if (windowWidth <= theme.breakpoints.noReplMobile) {
      if (menuDocked || !menuCollapsed) {
        setMenuDocked(false);
        setMenuCollapsed(true);
      }
    }
    // dock menu by default and display
    else if (!menuDocked || menuCollapsed) {
      setMenuDocked(true);
      setMenuCollapsed(false);
    }
  }, [windowWidth]);

  // scroll to top of page on url change
  useEffect(() => {
    if (!window.location.hash) {
      window.scrollTo({ top: 0 });
    }
  }, [route.galaxy, route.slugs]);

  // only display warning banner when on quickstart guide in mobile view
  useEffect(() => {
    if (
      route.galaxy === "quickstart" &&
      (route.slugs?.includes("notecard-quickstart") ||
        route.slugs?.includes("notecard-lora-quickstart") ||
        route.slugs?.includes("swan-quickstart")) &&
      windowWidth <= theme.breakpoints.noReplMobile
    ) {
      setIsWarningBannerShowing(true);
    } else {
      setIsWarningBannerShowing(false);
    }
  }, [route.slugs, route.galaxy, windowWidth]);

  const toggleREPLFullscreen = () => {
    if (router.pathname !== "/terminal") router.push("/terminal");
    else router.back();
  };

  return (
    <div
      className={
        `page-layout` +
        `${menuCollapsed ? " menuCollapsed" : " menuExpanded"}` +
        `${menuDocked ? " menuDocked" : " menuTray"}` +
        `${REPLCollapsed ? " replCollapsed" : " replExpanded"}` +
        `${REPLFullSize ? " replFullSize" : ""}`
      }
    >
      <Head>
        <meta
          name="build-version"
          content={`VERSION: ${Config.envName} ${Config.buildVersion}`}
        />

        {/* favicon */}
        {/* todo update this when we switch to new Next 13 file structure: https://nextjs.org/docs/app/api-reference/file-conventions/metadata/app-icons#favicon  */}

        <link rel="icon" href="/blues-favicon.svg" />
        <link rel="manifest" href="/site.webmanifest" />

        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1.0"
        />
      </Head>

      {/* added here b/c content for tooltip is rendered conditionally based on badge */}
      <StandardTooltip id="badge-tooltip" />
      <LayoutContext.Provider value={layoutContextVals}>
        <Suspense fallback="Loading...">
          <BluesErrorBoundary boundaryName="LayoutMain">
            {props.children}
          </BluesErrorBoundary>
        </Suspense>
        <Suspense fallback="Loading Terminal...">
          <REPLPositioner>
            <BluesErrorBoundary boundaryName="REPL">
              <IntegratedREPL
                repl={CREPL}
                onConnectionChange={setREPLConnected}
                onCloseClick={() => setREPLCollapsed(true)}
                onFullscreenToggleClick={toggleREPLFullscreen}
                hideCloseButton={pathname === "/terminal"}
                isFullscreen={pathname === "/terminal"}
              />
            </BluesErrorBoundary>
          </REPLPositioner>
        </Suspense>
      </LayoutContext.Provider>
      <style jsx global>
        {globalStyles}
      </style>
      <style jsx>{`
        .page-layout {
          display: grid;
          grid-template-areas:
            "header header"
            "menu main"
            "menu footer";
          grid-template-columns: ${theme.menu.width} minmax(0, 1fr);
        }

        @media screen and (max-width: ${theme.breakpoints.sm}px) {
          .page-layout {
            grid-template-areas:
              "header"
              "main"
              "footer";
            grid-template-columns: 100%;
          }
        }

        @media screen and (min-width: ${theme.breakpoints.sm +
          1}px) and (max-width: ${theme.breakpoints.md}px) {
          .page-layout {
            grid-template-areas:
              "header header"
              "menu main"
              "menu footer"
              "console console";
            grid-template-columns: ${theme.menu.width} minmax(0, 1fr);
          }

          .page-layout.menuTray,
          .page-layout.menuCollapsed {
            grid-template-areas:
              "header"
              "main"
              "footer"
              "console";
            grid-template-columns: minmax(0, 1fr);
          }

          .page-layout.replFullSize {
            display: block;
          }
        }

        @media screen and (min-width: ${theme.breakpoints.md +
          1}px) and (max-width: ${theme.breakpoints.lg}px) {
          .page-layout.menuDocked {
            grid-template-areas:
              "header header"
              "menu main"
              "menu footer"
              "menu console";
            grid-template-columns: ${theme.menu.width} minmax(0, 1fr);
          }
        }
      `}</style>
    </div>
  );
};

export default Layout;
