import { AnalyticsGateway } from "@components/analytics/AnalyticsGateway";
import { SettingsContext } from "@components/settings/SettingsProvider";
import useHash from "@components/utility/useHash";
import { PropsWithChildren, useContext, useEffect } from "react";

const debug = false;
const debugLog = debug ? console.log : () => {}; // eslint-disable-line no-console

// Get the page's scroll position releative to a reference element.
const getElementBasedScrollPosition = (element: HTMLElement) => {
  const position = { element, top: element.getBoundingClientRect().top };
  debugLog("Getting scroll position:", position);
  return position;
};

// Set the page's scroll position relative to a reference element.
const setElementBasedScrollPosition = (position: {
  top: number;
  element: HTMLElement;
}) => {
  debugLog("Setting scroll position:", position);
  const initialPosition = position.top;
  const newPosition = position.element.getBoundingClientRect().top;
  window.scrollBy(0, newPosition - initialPosition);
};

const selectAllTabs = (titleURI: string) => {
  Array.from(
    document.querySelectorAll<HTMLInputElement>(
      `*[data-tab-title="${titleURI}"]`
    )
  ).forEach((element) => {
    debugLog("Setting tab to checked:", titleURI);
    // eslint-disable-next-line no-param-reassign
    element.checked = true;
  });
};

export const TabSelector = ({ children }: PropsWithChildren) => {
  const {
    updateSelections,
    settings: { recentSelections },
  } = useContext(SettingsContext);
  const hash = useHash();

  // Switch the starting active tab based on the url hash and settings.
  useEffect(() => {
    // 1. Restore the user's preferences by selecting all the items they clicked
    //    recently.
    const leastRecentlySelected = recentSelections.slice().reverse();
    debugLog("Restoring selections for tabs.", hash, leastRecentlySelected);
    leastRecentlySelected.forEach(selectAllTabs);

    // 2. But, override if url-hash belongs to one of our children tabs.
    if (!window.location.hash) return; // no hash
    const id = window.location.hash.substring(1);
    if (!id) return; // no hash value
    const element = document.getElementById(id);
    if (!element) return; // no element matches hash
    // Get nearest ancestor .tab-content
    const tabContent = element.closest(".tab-content");
    if (!tabContent) return; // not a tab
    const idToActivate = tabContent.getAttribute("data-for-tab-id");
    const elementToActivate = document.querySelector<HTMLInputElement>(
      `input[id="${idToActivate}"]`
    );
    if (!elementToActivate) return; // nonsense hash
    elementToActivate.checked = true;
    debugLog("Activated tab from hash:", elementToActivate);
  }, [hash, recentSelections]);

  // Make an event handler which can work on a react div onClick or onKeyDown
  const handleEvent = (event: React.KeyboardEvent | React.MouseEvent) => {
    debugLog("Handling event?", event);
    const target = event.target as HTMLElement; // Type assertion
    if (!(target instanceof HTMLInputElement)) return;
    if (!target.hasAttribute("data-tab-title")) return;
    const titleURI = target.getAttribute("data-tab-title");
    if (!titleURI) return;
    debugLog("Yes, Tab clicked:", titleURI);
    AnalyticsGateway().trackEvent("Tab Clicked", { tabTitle: titleURI });
    // clear page hash so step (2.) above doesn't override our selection
    // don't scroll to the top, and keep query parameters.
    if (window.location.hash) {
      window.history.pushState(
        null,
        document.title,
        window.location.pathname + window.location.search
      );
    }
    const scrollPosition = getElementBasedScrollPosition(target);
    updateSelections([titleURI]);
    selectAllTabs(titleURI);
    setElementBasedScrollPosition(scrollPosition);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onClick={handleEvent} onKeyDown={handleEvent}>
      {children}
    </div>
  );
};

export default TabSelector;
