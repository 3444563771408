import { Tooltip } from "react-tooltip";

type StandardTooltipProps = {
  id: string;
};

const StandardTooltip = ({ id }: StandardTooltipProps) => (
  <div className="tooltip-container">
    <Tooltip id={id} className="tooltip standard-tooltip" opacity={1} />
  </div>
);

export default StandardTooltip;

/* To see an example of how this tooltip gets its ID and content, 
visit the `<Badge>` component and note the `data-tooltip-id` and 
`data-tooltip-content` properties on the `<span>`. 
Further reference docs also available here for working with the 
React Tooltip library: https://react-tooltip.com/docs/examples/content */
