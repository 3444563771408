
// refactored out of the class thanks to eslint class-methods-use-this
function parseJson(chunk: string): { isValid: boolean; json?: any } {
  try {
    const json = JSON.parse(chunk);
    return { isValid: true, json };
  } catch {
    return { isValid: false };
  }
}

export default class JsonTransformer implements Transformer {
  transform(
    chunk: string,
    controller: TransformStreamDefaultController<string>
  ) {
    const { isValid, json } = parseJson(chunk);
    if (isValid) {
      controller.enqueue(json);
    }
  }
}
