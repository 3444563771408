import { REPL } from "@components/repl/REPL";
import {
  TryExampleInteractor,
  ExampleReceiver,
} from "@use-cases/TryExampleInteractor";

// Allows the REPL to act as an ExampleReceiver.
export const ExampleReceivingREPL = ({
  wrappedREPL,
  interactor,
}: {
  wrappedREPL: REPL;
  interactor: TryExampleInteractor;
}) => {
  const receiver: ExampleReceiver = {
    receive: (example: string) => {
      wrappedREPL.setInput(example);
    },
    isReady: () => wrappedREPL.isConnected(),
  };

  interactor.setReceiver(receiver);
  return wrappedREPL;
};

const DEFAULT = { ExampleReceivingREPL };
export default DEFAULT;
