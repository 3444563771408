import theme from "@config/theme";

type ProgressProps = {
  fraction: number;
  message: string;
};

const Progress = ({ fraction, message }: ProgressProps) => {
  const color = fraction >= 0.9 ? theme.colors.kellyGreenTint : theme.colors.kellyGreenTint;
  const stripeColor = fraction < 0.9 ? theme.colors.kellyGreenShade : color;
  return (
    <>
      <div className="progress-bar">
        <div className="progress-unfilled" />
      </div>
      <span className="progress-message">{message}</span>
      <style jsx>{`
        // To keep the animated stripes moving at a consistent speed we set
        // the animation on the wider .progress-bar div and then vary the
        // width of the div that represents the _unfilled_ part of the
        // progress bar since that part is not animated (it's just gray like
        // the background).

        .progress-bar {
          width: 100%;
          height: 5px;
          background: ${theme.colors.gray2}
            repeating-linear-gradient(90deg, ${stripeColor}, ${color} 400px);
          background-size: 200% 200%;
          animation: barberpole 5s linear infinite;
          animation-direction: reverse;
        }
        @keyframes barberpole {
          100% {
            background-position: 100% 100%;
          }
        }

        .progress-unfilled {
          width: ${(1 - fraction) * 100}%;
          height: 100%;
          background-color: ${theme.colors.gray2};
          margin-left: auto;
          margin-right: 0;
        }

        .progress-message {
          min-height: ${theme.fonts.size.px28};
          background-color: ${theme.colors.gray2};
          color: ${theme.colors.gray6};
          padding: 3px 19px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .progress-message:hover {
          text-overflow: clip;
          white-space: normal;
        }
      `}</style>
    </>
  );
};

export default Progress;
