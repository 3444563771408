import { ExampleReceivingREPL } from "@adapters/ExampleReceivingREPL";
import { REPL, REPLImpl } from "@components/repl/REPL";
import { TryExampleInteractor } from "@use-cases/TryExampleInteractor";

let configured: REPL = new REPLImpl();

export const TryExample = new TryExampleInteractor();
configured = ExampleReceivingREPL({
  wrappedREPL: configured,
  interactor: TryExample,
});

export const CREPL = configured;
