import React, { useState, useEffect } from "react";
import theme from "@config/theme";
import Help from "@components/repl/Help";

export type ConnectionButtonProps = {
  background: "light" | "dark" | "none";
  border: "none" | "gray";
  dotColor: "red" | "green" | "gray";
  enabled: boolean;
  fontColor: "black" | "white" | "gray";
  isDepressed: boolean;
  mouseCursor: "pointer" | "default";
  onClick: () => void;
  text: string;
};

const themedColor = {
  black: theme.colors.black,
  dark: theme.colors.gray2,
  gray: theme.colors.grayBlue,
  green: theme.colors.kellyGreenTint,
  light: theme.colors.gray6,
  none: "none",
  red: theme.colors.poppyRed,
  white: theme.colors.white,
};

const borderLookup = {
  /* width | style | color */
  gray: `1px solid ${theme.colors.grayBlue}`,
  none: "none",
};

const ConnectionButton = ({
  background: color,
  border,
  dotColor,
  enabled,
  fontColor,
  isDepressed,
  mouseCursor: cursor,
  onClick,
  text,
}: ConnectionButtonProps) => (
  <>
    <button
      className="connection-button"
      onClick={onClick}
      type="button"
      disabled={!enabled}
    >
      <span className="indicator-dot" />
      {text}
    </button>
    <style jsx>
      {`
        .connection-button {
          color: ${themedColor[fontColor]};
          cursor: ${cursor};
          font-size: ${theme.fonts.size.px16};
          font-weight: 500;
          padding: 5px 30px;
          background: ${themedColor[color]};
          border-radius: 7px;
          border: ${borderLookup[border]};
          position: relative;
          float: left;
          margin-right: 10px;
          margin-top: 5px;
          box-shadow: ${isDepressed
            ? "inset -3px 3px 8px rgba(35, 57, 80, 0.7)"
            : "none"};
        }

        button:focus-visible {
          outline: 1px solid ${theme.colors.ultramarineTint};
        }

        .indicator-dot {
          --dot-radius: 4px;
          position: absolute;
          top: 5px;
          left: 5px;
          height: calc(var(--dot-radius) * 2);
          width: calc(var(--dot-radius) * 2);
          border-radius: var(--dot-radius);
          display: inline-block;
          background-color: ${themedColor[dotColor]};
        }
      `}
    </style>
  </>
);

function REPLHeader({
  connected,
  connectionButtons,
  connectionStatusText,
  hideCloseButton,
  hideHelp,
  hideSaveButton,
  isFullscreen,
  onCloseClick,
  onFullscreenToggleClick,
  onSaveClick,
  serialSupported,
}: {
  connected: boolean;
  connectionButtons: Array<ConnectionButtonProps>;
  connectionStatusText: string;
  hideCloseButton: boolean;
  hideHelp?: boolean;
  hideSaveButton: boolean;
  isFullscreen: boolean;
  onCloseClick?: () => void;
  onFullscreenToggleClick?: () => void;
  onSaveClick?: () => void;
  serialSupported: boolean;
}) {
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    setExpanded(false);
  }, [connected]);

  return (
    <>
      <div className="repl-connection-indicator">
        <div className="StyledConnectedIndicatorFlexContainer">
          <div>
            {/* status elements on left side of header */}
            <div className="REPLHeaderBodyContainer">
              <div role="status">{connectionStatusText}</div>
              {connectionButtons.map((buttonProps) => (
                <ConnectionButton key={buttonProps.text} {...buttonProps} />
              ))}
            </div>
          </div>
          <div className="FlexRight">
            {
              /* HELP button - only display if web serial supported  */
              serialSupported && !hideHelp && (
                <button
                  className={`HeaderButton ${expanded ? "active" : ""}`}
                  title="Help"
                  onClick={() => setExpanded(!expanded)}
                  type="button"
                >
                  <span className="material-icons-round">help</span>
                </button>
              )
            }
            {!hideSaveButton && (
              <button
                className="HeaderButton Save"
                onClick={onSaveClick}
                title="Save History"
                type="button"
              >
                <span className="material-icons-round">file_download</span>
              </button>
            )}
            {!isFullscreen && (
              <button
                className="HeaderButton Fullscreen"
                onClick={onFullscreenToggleClick}
                title="Fullscreen"
                type="button"
              >
                <span className="material-icons-round">fullscreen</span>
              </button>
            )}
            {isFullscreen && (
              <button
                className="HeaderButton ExitFullscreen"
                onClick={onFullscreenToggleClick}
                title="Exit Fullscreen"
                type="button"
              >
                <span className="material-icons-round">fullscreen_exit</span>
              </button>
            )}
            {!hideCloseButton && (
              <button
                className="HeaderButton Close"
                onClick={onCloseClick}
                title="Hide"
                type="button"
              >
                <span className="material-icons-round">close</span>
              </button>
            )}
          </div>
        </div>
      </div>

      {/* drop down content */}
      <div
        className={
          `StyledDropDownContainer` +
          `${!expanded ? " collapsed" : " expanded"}`
        }
      >
        <Help />
      </div>

      <style jsx global>{`
        .repl-connection-indicator {
          background: ${theme.colors.black};
          flex: 0 0 auto;
          padding: 3px 19px 10px 19px;
          box-sizing: border-box;
          width: 100%;
          z-index: calc(${theme.zIndex.repl} + 1);
        }

        .StyledDropDownContainer {
          color: ${theme.colors.white};
          position: absolute;
          top: 65px;
          padding: 1.5rem 1rem;
          box-sizing: border-box;
          /* transition: all 200ms ease; */
          max-height: calc(100% - 65px - 83px);
          transform: translateY(0%);
          width: 100%;
          overflow-x: hidden;
          overflow-y: auto;
          opacity: 1;
          z-index: ${theme.zIndex.repl};
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .StyledDropDownContainer::-webkit-scrollbar {
          display: none;
        }

        .StyledDropDownContainer.collapsed {
          transform: translateY(-100%);
          max-height: 0;
          opacity: 0;
          overflow-y: hidden;
        }

        .StyledConnectedIndicatorFlexContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .FlexRight {
          display: flex;
        }

        .REPLHeaderBodyContainer {
          color: ${theme.colors.white};
          display: inline-block;
        }

        .HeaderButton {
          position: relative;
          text-transform: uppercase;
          background: none;
          border: none;
          font-size: ${theme.fonts.size.px16};
          cursor: pointer;
          display: inline-block;
          font-weight: bold;
          padding: 0 0 0 1rem;
          height: auto;
          vertical-align: middle;
          color: ${theme.colors.lightGray};
        }

        .HeaderButton:hover,
        .HeaderButton:focus-visible,
        .HeaderButton.active {
          color: ${theme.colors.ultramarineTint};
        }

        /* push down help message as repl drawer shrinks so it's not obscured by header that gets taller as it condenses its buttons */
        @container repl-drawer (max-width: 480px) {
          .page-layout.menuDocked .StyledDropDownContainer.expanded {
            top: 100px;
          }
        }

        @container repl-drawer (max-width: 370px) {
          .page-layout.menuDocked .StyledDropDownContainer.expanded {
            top: 135px;
          }
        }
      `}</style>
    </>
  );
}

REPLHeader.defaultProps = {
  hideHelp: false,
  onCloseClick: () => {},
  onSaveClick: () => {},
  onFullscreenToggleClick: () => {},
};

export default REPLHeader;
