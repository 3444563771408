import { AnalyticsGateway } from "@components/analytics/AnalyticsGateway";
import { DeviceBackgroundTask } from "./DeviceBackgroundTask";
import { Status } from "../DeviceConnection";

export interface StatusMonitorTaskDefinition<RawStatusDetails> {
  taskName: string;
  getDeviceStatus: () => Promise<RawStatusDetails>;
  estimateProgress: (d: RawStatusDetails) => number;
  summarize: (d: RawStatusDetails) => string;
  onStatusChange: (s: Status) => void;
  updateIntervalMs: number;
}
export class StatusMonitorTask<T> implements DeviceBackgroundTask {
  timer: NodeJS.Timeout;

  statusHistory: Array<Status>;

  constructor(private def: StatusMonitorTaskDefinition<T>) {
    this.timer = setInterval(
      () => this.updateStatus(),
      this.def.updateIntervalMs
    );
    this.statusHistory = [];
  }

  private async updateStatus() {
    try {
      const raw = await this.def.getDeviceStatus();
      const status = {
        fraction: this.def.estimateProgress(raw),
        message: this.def.summarize(raw),
      };
      this.setStatus(status);
      AnalyticsGateway().trackEvent(
        this.def.taskName,
        JSON.parse(JSON.stringify(raw))
      );
    } catch {
      this.setStatus({ fraction: 0, message: "Error getting sync status" });
      // ignore
    }
  }

  private setStatus(s: Status) {
    this.statusHistory.push(s);
    this.def.onStatusChange(s);
  }

  cleanup() {
    this.setStatus({ fraction: 0, message: "" });
    clearInterval(this.timer);
  }
}

export {};
