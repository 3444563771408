import { ReactNode } from "react";
import { AuthContextProvider } from "@adapters/AuthContextProvider";
import { CGetUserInfoInteractor } from "@configured/use-cases/CGetUserInfoInteractor";

export const CAuthContextProvider = (props: { children: ReactNode }) => (
    <AuthContextProvider
      {...props}
      getUserInfoInteractor={CGetUserInfoInteractor}
      closeTabRoute="/close-tab"
    />
  );
