import { SmartLink } from "@components/doc/SmartLink";
import theme from "@config/theme";
import { MouseEventHandler } from "react";

type REPLModalProps = {
  connected: boolean;
  onConnectSimulatorClick: () => void;
  onConnectUSBClick: () => void;
  onSignInClick?: () => void;
  onSignUpClick?: () => void;
  error: string;
  serialSupported: boolean;
  userIsSignedIn: boolean;
};

export enum REPLError {
  Error,
  Unsupported,
  NotConnected,
}

type ModalProps = {
  children?: React.ReactNode | null;
  className?: string;
  message?: JSX.Element | null;
  title?: string;
};

const defaultProps = {
  className: "",
  chilren: null,
  message: null,
  title: "",
};

const Modal = ({ className, message, children, title }: ModalProps) => (
  <>
    <div className={`REPLModal ${className || ""}`}>
      {title && <span className="REPLModalTitle">{title}</span>}
      {message && <span className="REPLModalBody"> {message}</span>}
      {children}
      <style jsx>{`
        .REPLModal {
          position: absolute;
          max-width: 800px;
          background-color: ${theme.colors.gray2};
          display: flex;
          flex-direction: column;
          left: 2rem;
          right: 2rem;
          margin: calc(85px + 2rem) auto 0;
          align-items: center;
          justify-content: center;
          padding: 1rem 1.25rem;
          border-radius: 3px;
          z-index: 1;
          overflow-y: auto;
          box-shadow: ${theme.misc.boxShadow};
          font-size: ${theme.fonts.size.px16};
          color: ${theme.colors.white};
        }

        .REPLModal.hidden {
          display: none;
        }

        :global(.REPLModal .REPLModalTitle) {
          font-size: ${theme.fonts.size.px22};
          font-weight: 600;
          text-align: center;
        }

        .REPLModalBody {
          margin-top: 0.5rem;
        }

        @container repl-drawer (max-width: 500px) {
          .REPLModal {
            margin: calc(150px + 0.5rem) auto 0;
            padding: 0.5rem 1.25rem;
          }

          :global(.REPLModal .REPLModalTitle) {
            font-size: ${theme.fonts.size.px14};
          }
        }
      `}</style>
    </div>
  </>
);

Modal.defaultProps = defaultProps;

const unsupportedBrowserMessage = (
  <>
    The In-Browser Terminal, which enables in-browser communication with a
    Notecard over a Serial connection, is not supported on this browser.
    Consider visiting this site using Chrome, Opera, or Edge, or download the{" "}
    <SmartLink href="/tools-and-sdks/notecard-cli">Notecard CLI</SmartLink>{" "}
    which provides all of the functionality of the In-Browser Terminal in a
    terminal application.
  </>
);

export const REPLModal = ({
  onConnectSimulatorClick,
  onConnectUSBClick,
  onSignInClick,
  onSignUpClick,
  connected,
  error,
  serialSupported,
  userIsSignedIn,
}: REPLModalProps) => {
  if (!serialSupported) {
    return (
      <Modal title="Unsupported Browser" message={unsupportedBrowserMessage} />
    );
  }

  const Button = ({
    text,
    handler,
  }: {
    text: string;
    handler: MouseEventHandler<HTMLButtonElement>;
  }) => (
    <>
      <button className="btn" onClick={handler} type="button">
        {text}
      </button>
      <style jsx>{`
        button {
          font-size: ${theme.fonts.size.px16};
          white-space: nowrap;
          margin-top: 0.6rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0.5rem 1rem;
        }

        @media screen and (max-width: ${theme.breakpoints.md}px) {
          button {
            padding: 0.5rem;
            font-size: ${theme.fonts.size.px14};
          }
        }
      `}</style>
    </>
  );

  const usbButton = <Button text="USB Notecard" handler={onConnectUSBClick} />;
  const simButton = (
    <Button text="Simulator (Beta)" handler={onConnectSimulatorClick} />
  );
  const signInButton = onSignInClick && onSignUpClick && (
    <>
      <Button text="Sign In" handler={onSignInClick} />
      <p className="signUp">
        {"Don't have an account? "}
        <SmartLink onClick={onSignUpClick} role="button">
          <span>Sign up</span>
        </SmartLink>
      </p>
      <style jsx>{`
        span {
          white-space: nowrap;
        }
      `}</style>
    </>
  );
  const simOrSignInButton = userIsSignedIn ? simButton : signInButton;

  if (error) {
    return (
      <Modal title="Notice">
        {error}
        {!connected && (
          <>
            {usbButton}
            {simOrSignInButton}
          </>
        )}
      </Modal>
    );
  }
  if (!connected) {
    return (
      <Modal title="">
        <>
          <div className="modalMessage">
            <div className="usb">
              <div className="REPLModalTitle">Connect a Notecard</div>
              Use USB to connect and start issuing requests from the browser.
              {usbButton}
            </div>
            <div className="sim">
              <div className="REPLModalTitle">Try Notecard Simulator</div>
              Experiment with Notecard's latest firmware on a Simulator assigned
              to your free Notehub account.
              {simOrSignInButton}
            </div>
          </div>
          <style jsx>{`
            .modalMessage {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
            }
            .modalMessage:before {
              content: "";
              border: 1px solid ${theme.colors.lightGray};
              align-self: stretch;
            }
            .usb {
              order: -1; // before the .modalMessage:before pseudoelement
              margin-bottom: 0.5rem;
            }
            .usb,
            .sim {
              font-size: ${theme.fonts.size.px14};
              display: flex;
              align-items: center;
              flex-direction: column;
            }

            @container repl-drawer (min-width: 623px) {
              .modalMessage {
                flex-direction: row;
                gap: 1rem;
              }
              .usb,
              .sim {
                font-size: ${theme.fonts.size.px16};
              }
            }
          `}</style>
        </>
      </Modal>
    );
  }
  return null;
};

export default REPLModal;
