/* eslint-disable react/destructuring-assignment */
// React
import React, { useRef, useContext, useEffect } from "react";

// NextJS
import { useRouter } from "next/router";

// REPL
import { SET_RESIZER } from "@scripts/resize";

// Theme
import theme from "@config/theme";
import { ImgSrc } from "@config/image";

// REPL Context
import { LayoutContext } from "@components/layout/Layout";

// Analytics
import { AnalyticsGateway } from "@components/analytics/AnalyticsGateway";
import RESIZER_HANDLE from "./resizer/Handle";

type REPLPositionerProps = {
  children: React.ReactNode;
};

function REPLPositioner(props: REPLPositionerProps) {
  // TODO: create separate REPL context
  const {
    REPLCollapsed,
    setREPLCollapsed,
    REPLFullSize,
    hideREPL,
    isAnnouncementBannerShowing,
    menuCollapsed,
  } = useContext(LayoutContext);

  // RESIZER
  const replPositionerRef = useRef<HTMLDivElement>(null);

  // NextJS Router
  const { pathname } = useRouter();

  // Init Resizer
  useEffect(() => {
    !REPLFullSize && SET_RESIZER(replPositionerRef, ".REPL_RESIZER_HANDLE");
  }, []);

  const isTerminalPage = pathname === "/terminal";

  return (
    <>
      <div
        ref={replPositionerRef}
        className={
          `repl-positioner` +
          `${REPLCollapsed ? " collapsed" : ""}` +
          `${REPLFullSize ? " fullsize" : ""}` +
          `${isTerminalPage ? " noAnimations" : ""}` +
          `${isTerminalPage ? " isTerminalPage" : ""}` +
          `${hideREPL ? " hide-repl" : ""}` +
          `${isAnnouncementBannerShowing ? " visible-banner" : ""}` +
          `${menuCollapsed ? "" : " menuExpanded"}`
        }
      >
        <ToggleCollapse
          collapsed={REPLCollapsed}
          setCollapsed={() => {
            setREPLCollapsed(!REPLCollapsed);
            AnalyticsGateway().trackEvent(
              `REPL ${REPLCollapsed ? "Expand" : "Collapse"} Clicked`,
            );
          }}
        />
        {!REPLFullSize && <RESIZER_HANDLE />}
        {props.children}
        <style jsx>{`
          .repl-positioner.hide-repl {
            display: none;
          }

          // REPL to the right, large / xl screens 1279px+
          .repl-positioner {
            height: ${theme.repl.height.right};
            max-height: calc(100% - ${theme.header.height});
            min-height: calc(100% - ${theme.header.height});
            max-width: calc((100% - ${theme.menu.width}) - 40px);
            /* set the min-width of REPL to EITHER 400px or the calculated value, whichever is greater. 
            Needed on small screen widths before REPL drawer hidden completely
            CSS max() reference docs: https://developer.mozilla.org/en-US/docs/Web/CSS/max*/
            min-width: max(calc((100% - ${theme.menu.width}) * 0.4), 335px);
            padding: 0 0 0 11px;
            transform: translateX(0);
            container-name: repl-drawer;
            container-type: inline-size;
          }

          .repl-positioner.collapsed {
            transform: translateX(100%);
          }

          // REPL to the right or Notecard Playground, large / xl screens 1279px+, announcement banner visible
          .repl-positioner.visible-banner {
            max-height: calc(100% - (${theme.header.height} + 34px));
            min-height: calc(100% - (${theme.header.height} + 34px));
          }

          // Notecard Playground, large / xl screen 1279px +
          .repl-positioner.fullsize {
            padding: 0;
            min-width: 100vw;
            max-width: 100vw;
          }

          // hide repl-positioner resize grab handle when collapsed
          .repl-positioner.collapsed {
            right: -10px;
            bottom: -10px;
          }

          @media screen and (max-width: ${theme.breakpoints.md}px) {
            // REPL to the right or Notecard Playground, max screen size 991px, visible announcement banner
            .repl-positioner.visible-banner {
              max-height: calc(
                (
                  100% -
                    (
                      ${theme.header.height} +
                        ${theme.announcementBanner.height.mediumScreen}
                    )
                )
              );
              min-height: calc(
                (
                  100% -
                    (
                      ${theme.header.height} +
                        ${theme.announcementBanner.height.mediumScreen}
                    )
                )
              );
            }
          }

          @media screen and (max-width: ${theme.breakpoints.sm}px) {
            // Notecard Playground, smallest screens under 480px, visible announcement banner
            .repl-positioner.visible-banner {
              max-height: calc(
                100% -
                  (
                    ${theme.header.height} +
                      ${theme.announcementBanner.height.smallScreen}
                  )
              );
              min-height: calc(
                100% -
                  (
                    ${theme.header.height} +
                      ${theme.announcementBanner.height.smallScreen}
                  )
              );
            }
          }
        `}</style>
        <style jsx>{`
          // REPL static styles
          .repl-positioner {
            position: fixed;
            right: 0;
            bottom: 0;
            z-index: ${theme.zIndex.repl};
            grid-area: console;
            touch-action: none;
          }

          // hide repl when width < no repl breakpoint and galaxy not notecard-playground
          @media screen and (max-width: ${theme.breakpoints.noReplMobile}px) {
            .repl-positioner {
              display: ${isTerminalPage ? "flex" : "none"} !important;
            }
            .repl-poitioner.isTerminalPage {
              width: 100% !important;
            }
          }
        `}</style>
      </div>
    </>
  );
}

type ToggleCollapseProps = {
  collapsed: boolean;
  setCollapsed: () => void;
};

const ToggleCollapse = ({ collapsed, setCollapsed }: ToggleCollapseProps) => {
  const { isAnnouncementBannerShowing } = useContext(LayoutContext);
  return (
    <div
      title={`${collapsed ? "Show" : "Hide"} In-Browser Terminal`}
      className={`toggleContainer ${collapsed ? "collapsed" : ""} ${
        isAnnouncementBannerShowing ? "visible-banner" : ""
      }`}
      onClick={setCollapsed}
      onKeyDown={setCollapsed}
      tabIndex={0}
      role="button"
    >
      <div className={`iconImg ${collapsed && "collapsed"}`}>
        <img alt="" src={ImgSrc("/images/icons/terminal.svg")} />
      </div>

      <style jsx>{`
        // container
        .toggleContainer {
          display: none;
        }

        .toggleContainer.collapsed {
          display: flex;
          position: absolute;
          top: 16px;
          left: -62px;
          background-color: ${theme.colors.black};
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          padding: 8px;
          cursor: pointer;
          box-shadow: ${theme.misc.boxShadow};
          z-index: ${theme.zIndex.repl};
          border: 1px solid ${theme.colors.mediumGray};
        }

        .iconImg {
          display: flex;
        }

        .iconImg img {
          width: 36px;
          height: 36px;
        }

        // show connection status when collapsed
        .statusContainer.collapsed {
          margin: -12px 2px auto -2px;
        }

        // icon zoom in animation
        .toggleContainer:hover > .iconImg.collapsed {
          transform: scale(1.1);
        }
      `}</style>
    </div>
  );
};

export default REPLPositioner;
