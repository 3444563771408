export const SET_RESIZER = (
  REF: React.RefObject<HTMLDivElement>,
  HANDLE_NAME: string
): void => {
  const resizeElement = REF.current;
  if (!resizeElement) return;

  const resizeHandle = resizeElement.querySelector(HANDLE_NAME) as HTMLElement;
  if (!resizeHandle) return;

  // Set the cursor for the resize handle
  resizeHandle.style.cursor = "ew-resize";
  // Prevent text selection when dragging the handle
  resizeHandle.style.userSelect = "none";

  const startResize = (e: MouseEvent) => {
    e.preventDefault();

    const startX = e.clientX;
    const startWidth = parseInt(
      window.getComputedStyle(resizeElement).width,
      10
    );

    const doDrag = (e2: MouseEvent) => {
      const newWidth = startWidth - (e2.clientX - startX);
      resizeElement.style.width = `${Math.max(newWidth, 0)}px`;
    };

    const stopDrag = () => {
      // Remove the class to allow text selection
      resizeElement.classList.remove("no-select");

      document.removeEventListener("mousemove", doDrag);
      document.removeEventListener("mouseup", stopDrag);
    };

    document.addEventListener("mousemove", doDrag);
    document.addEventListener("mouseup", stopDrag);
  };

  resizeHandle.addEventListener("mousedown", startResize);
};
