import Table from "cli-table";

export const CLI_TABLE_WITHOUT_BORDERS = {
  top: "",
  "top-mid": "",
  "top-left": "",
  "top-right": "",
  bottom: "",
  "bottom-mid": "",
  "bottom-left": "",
  "bottom-right": "",
  left: "",
  "left-mid": "",
  mid: "",
  "mid-mid": "",
  right: "",
  "right-mid": "",
  middle: " ",
};

type Alignments = ConstructorParameters<typeof Table>[0]["colAligns"];

export const newTable = (params: { head?: string[]; colAligns?: Alignments }) =>
  new Table({
    chars: CLI_TABLE_WITHOUT_BORDERS,
    colors: false,
    style: { head: [], border: [] },
    ...params,
  });
