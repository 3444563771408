import theme from "@config/theme";

const RESIZER_HANDLE = () => (
  <>
    <div className="REPL_RESIZER_HANDLE" />

    <style jsx>{`
      .REPL_RESIZER_HANDLE {
        position: absolute;
        background-color: ${theme.colors.gray1};
        border: 1px solid ${theme.colors.mediumGray};
        box-sizing: border-box;
        z-index: ${theme.zIndex.repl + 99};
        height: 100%;
        width: 10px;
        left: -10px;
      }

      .REPL_RESIZER_HANDLE:after {
        width: 1px;
        height: 40px;
      }

      .REPL_RESIZER_HANDLE:hover {
        background-color: ${theme.colors.gray2};
      }

      .REPL_RESIZER_HANDLE:after {
        content: "";
        top: 50%;
        left: 48%;
        transform: translate(-50%, -50%);
        position: absolute;
        background-color: ${theme.colors.mediumGray};
      }

      .PRESSED {
        background-color: ${theme.colors.gray2};
      }
    `}</style>
  </>
);

export default RESIZER_HANDLE;
