/* eslint-disable no-await-in-loop */
import { docopt } from "docopt";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

import { AnalyticsGateway } from "@components/analytics/AnalyticsGateway";
import {
  HistoryLine,
  errorLines,
  hintMarkdownLines,
  messageLines,
} from "../HistoryLine";
import {
  NotecardCommand,
  NotecardDeviceConnection,
} from "../NotecardDevice/NotecardDeviceConnection";
import { CommandResult, Status } from "../DeviceConnection";
import { doDFU, listCompatibleFirmware } from "../NotecardDevice/SideloadDFU";
import { newTable } from "./utils/format";
import {
  FirmwareInfo,
  FirmwareVersion,
  NotecardFirmwareSupportError,
  TransactFn,
} from "../NotecardDevice/ported/dfu";
import { NUM, STR, has } from "../NotecardDevice/NotecardAPIValidation";

/* eslint-disable class-methods-use-this */

const COMMAND = "firmware";

// This string drives the arg parser!
const PUBLIC_DOC = `Perform firmware update on a Notecard.
Usage:
  firmware list
  firmware install <version>
  firmware install latest
  firmware help
`.trim();

const PRIVATE_DOC = `
  firmware --allow list
  firmware --allow install <source>

Options:
  --allow   Allow unpublished firmware to be listed and installed. [default: false]
`;

const DOC = PUBLIC_DOC + PRIVATE_DOC;

const minVersionForDFU: FirmwareVersion = {
  ver_major: 5,
  ver_minor: 3,
  ver_patch: 1,
};

export default class Firmware implements NotecardCommand {
  name = COMMAND;

  deviceConnection?: NotecardDeviceConnection;

  output: HistoryLine[] = [];

  private static fwUpdateLinkMarkdown =
    "[Notecard Firmware Updates](/notecard/notecard-firmware-updates/)";

  async onConnect(deviceConnection: NotecardDeviceConnection) {
    this.deviceConnection = deviceConnection;
    const current = await this.getCurrentRunningVersion();
    const allVersions = await this.fetchList(false);
    const hint = await this.maybeUpgradeHint(current, allVersions);
    return { history: hintMarkdownLines(hint) };
  }

  private static DevOrLTS({ ver_major }: FirmwareVersion): "Dev" | "LTS" {
    if (!ver_major)
      throw new Error("Can't determine if firmware is Dev or LTS");
    return ver_major % 2 === 0 ? "LTS" : "Dev";
  }

  private async maybeUpgradeHint(
    current: {
      version: FirmwareVersion | undefined;
      sku: string | undefined;
    },
    allVersions: FirmwareInfo[],
  ) {
    const { version, sku } = current;

    if (!version || !sku) return "";

    const allow = false;
    const cached = false;
    const latest = await this.findSpecificFirmware(
      "latest",
      allow,
      Firmware.DevOrLTS(version),
      allVersions,
      cached,
    );
    if (!latest?.firmware) return "";

    if (Firmware.compareVersions(version, latest.firmware) >= 0) {
      return "";
    }

    const supportsODFU =
      Firmware.compareVersions(version, minVersionForDFU) >= 0;
    return this.englishUpgradeHint(
      supportsODFU,
      latest.firmware.version || "",
      latest.notes || "",
    );
  }

  private englishUpgradeHint(
    supportsODFU: boolean,
    newVersion: string,
    newNotes: string,
  ) {
    let hintMD = `A newer firmware, ${newVersion} (${newNotes}), is available.`;
    if (supportsODFU) {
      hintMD += ` Run 'firmware install latest' to update.`;
    } else {
      hintMD += ` See ${Firmware.fwUpdateLinkMarkdown}`;
    }
    return hintMD;
  }

  private async getCurrentRunningVersion(): Promise<{
    version: FirmwareVersion | undefined;
    sku: string | undefined;
  }> {
    if (!this.deviceConnection) return { version: undefined, sku: undefined };
    const response = (await this.deviceConnection.performTransaction(
      `{"req":"card.version"}`,
    )) as unknown;
    const expectedShape = {
      sku: STR,
      body: {
        version: STR,
        ver_major: NUM,
        ver_minor: NUM,
        ver_patch: NUM,
        ver_build: NUM,
      },
    };
    if (!has(response, expectedShape)) {
      return { version: undefined, sku: undefined };
    }
    return {
      version: response.body,
      sku: response.sku,
    };
  }

  triggeredBy(request: string) {
    return request === COMMAND || request.startsWith(`${COMMAND} `);
  }

  copy(): Firmware {
    const newCommand = new Firmware();
    newCommand.latestVersion = this.latestVersion;
    return newCommand;
  }

  appendHint(lines: string) {
    this.output.push(...hintMarkdownLines(lines));
  }

  appendMessage(lines: string) {
    this.output.push(...messageLines(lines));
  }

  appendError(lines: string) {
    this.output.push(...errorLines(lines));
  }

  notecardTransaction: TransactFn = async ([str, largeBinary]) => {
    const hidden = true;
    const timeoutMs = 9_000;
    if (!this.deviceConnection)
      throw new Error(
        "Can't do transaction: notecard connection can't be found. Please reconnect and try again.",
      );
    return this.deviceConnection.performTransaction(
      str,
      hidden,
      timeoutMs,
      largeBinary,
    );
  };

  async tryList(allow: boolean) {
    AnalyticsGateway().trackEvent("Firmware List");
    const firmwares = await this.fetchList(allow);
    await this.showList(allow, firmwares);
  }

  async fetchList(allow: boolean) {
    try {
      return await listCompatibleFirmware(
        allow,
        this.notecardTransaction.bind(this),
      );
    } catch (e: any) {
      this.appendError(
        `Error fetching available firmware. Please try again later.`,
      );
      AnalyticsGateway().trackEvent("Firmware Fetch Error", {
        error: e.message,
      });
    }
    return [];
  }

  async showList(allow: boolean, firmwares: FirmwareInfo[]) {
    this.appendMessage(`${firmwares.length} Firmware`);

    if (!firmwares.length) return;

    const table = newTable({
      head: [
        "Date",
        allow ? "Source" : "Version",
        "Notes",
        allow ? "Target-HW" : "",
      ],
    });

    firmwares
      .sort((a, b) => (a.created || 0) - (b.created || 0))
      .map((firmware) => {
        const date = new Date(1000 * (firmware.created || 0));
        TimeAgo.addLocale(en);
        const timeAgo = new TimeAgo("en-US");
        const absoluteDate = date.toISOString().split("T")[0];
        const relativeDate = timeAgo.format(date, "mini");
        table.push([
          `${absoluteDate} (${relativeDate} ago)`,
          (allow ? firmware.source : firmware.firmware?.version) || "",
          firmware.notes || "",
          (allow && firmware.firmware?.target) || "",
        ]);
        return true;
      });

    this.appendMessage(table.toString());
  }

  async tryInstall(versionOrSource: string, allowUnpublished: boolean) {
    AnalyticsGateway().trackEvent("Firmware Install", {
      versionOrSource,
      allowUnpublished,
    });
    try {
      await this.install(versionOrSource, allowUnpublished);
    } catch (e: unknown) {
      if (e instanceof NotecardFirmwareSupportError) {
        this.appendHint(
          `Current Notecard does not support firmware updates directly` +
            ` through the terminal. See ${Firmware.fwUpdateLinkMarkdown}`,
        );
      } else {
        this.appendError(`Error installing firmware: ${e}.`);
        this.appendHint(`Try again or see ${Firmware.fwUpdateLinkMarkdown}.`);
        AnalyticsGateway().trackEvent("Firmware Install Error", {
          error: `${e}`,
        });
      }
    }
  }

  // compare firmware based on major minor patch and build
  static compareVersions(a: FirmwareVersion, b: FirmwareVersion) {
    const aVerMajor = a.ver_major || 0;
    const bVerMajor = b.ver_major || 0;
    if (aVerMajor !== bVerMajor) return aVerMajor - bVerMajor;
    const aVerMinor = a.ver_minor || 0;
    const bVerMinor = b.ver_minor || 0;
    if (aVerMinor !== bVerMinor) return aVerMinor - bVerMinor;
    const aVerPatch = a.ver_patch || 0;
    const bVerPatch = b.ver_patch || 0;
    if (aVerPatch !== bVerPatch) return aVerPatch - bVerPatch;
    const aVerBuild = a.ver_build || 0;
    const bVerBuild = b.ver_build || 0;
    return aVerBuild - bVerBuild;
  }

  private latestVersion: FirmwareInfo | undefined = undefined;

  async findSpecificFirmware(
    wantedVersion: string,
    allowUnpublished: boolean,
    DevOrLTS: "Dev" | "LTS" | "any" = "any",
    allVersions: FirmwareInfo[] = [],
    cachedLatest = true,
  ): Promise<FirmwareInfo | undefined> {
    let fwList = allVersions.length
      ? allVersions
      : await this.fetchList(allowUnpublished);
    if (DevOrLTS !== "any")
      fwList = fwList.filter((fwInfo) => {
        const fw = fwInfo.firmware;
        return fw && Firmware.DevOrLTS(fw) === DevOrLTS;
      });
    if (wantedVersion === "latest") {
      if (!cachedLatest) this.latestVersion = undefined;
      if (this.latestVersion) return this.latestVersion;
      fwList = fwList.sort((a, b) =>
        a.firmware && b.firmware
          ? Firmware.compareVersions(b.firmware, a.firmware)
          : 0,
      );
      [this.latestVersion] = fwList;
      return this.latestVersion;
    }
    return fwList.find((fwInfo) =>
      allowUnpublished
        ? wantedVersion === `${fwInfo.source}`
        : wantedVersion === `${fwInfo.firmware?.version}`,
    );
  }

  async install(wantedVersion: string, allowUnpublished: boolean) {
    const fw = await this.findSpecificFirmware(wantedVersion, allowUnpublished);

    if (!fw) {
      throw new Error(`No firmware found matching "${wantedVersion}"`);
    }

    const foundVersion = allowUnpublished ? fw.source : fw.firmware?.version;
    const foundNotes = fw.notes || "";

    AnalyticsGateway().trackEvent(
      `REPL Firmware Install Attempt ${foundVersion}`,
    );

    try {
      const msgStart = `firmware install ${foundVersion}`;
      this.reportStatus?.({
        message: `${msgStart}: Starting`,
        fraction: 0.05,
      });
      const stats = await doDFU(
        fw,
        this.notecardTransaction.bind(this),
        (english, fraction) => {
          this.reportStatus?.({
            message: `${msgStart}: ${english}`,
            fraction,
          });
        },
      );
      this.appendMessage(
        `Sent ${foundVersion} (${foundNotes}) firmware to the Notecard in ${stats.seconds.toFixed()}s` +
          ` (${(stats.bytes / stats.seconds / 1024).toFixed()} KB/s).`,
      );
      this.appendMessage(`Notecard will now restart.`);
      AnalyticsGateway().trackEvent(
        `REPL Firmware Install Success ${foundVersion}`,
      );
    } finally {
      this.reportStatus?.({
        message: ``,
        fraction: -1,
      });
    }
  }

  reportStatus?: (status: Status) => void;

  async perform(
    deviceConnection: NotecardDeviceConnection,
    request: string,
    reportStatus?: (status: Status) => void,
  ): Promise<CommandResult> {
    this.deviceConnection = deviceConnection;
    this.reportStatus = reportStatus;

    // Parse command line arguments
    const argv = request
      .split(" ")
      .filter((x) => x !== "")
      .slice(1);

    let opts: any;
    try {
      opts = docopt(DOC, { argv, exit: false, help: false });
    } catch (e: any) {
      this.appendError(PUBLIC_DOC);
      return { history: this.output };
    }
    // Command
    const install = Boolean(opts.install);
    const help = Boolean(opts.help);
    const list = Boolean(opts.list);
    // Options
    const allowUnpublished = Boolean(opts["--allow"] || opts["-a"]);
    const version = `${opts["<version>"] || ""}`;
    const source = `${opts["<source>"] || ""}`;

    if (list) {
      await this.tryList(allowUnpublished);
    } else if (install) {
      await this.tryInstall(version || source, allowUnpublished);
    } else if (help) {
      this.appendMessage(PUBLIC_DOC);
    } else {
      this.appendError(PUBLIC_DOC);
    }

    return { history: this.output };
  }
}
