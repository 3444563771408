import {
  AnalyticsGatewayInterface,
  PageContext,
  TrackedUser,
} from "@use-cases/AnalyticsGatewayInterface";

const debug = false;
// eslint-disable-next-line no-console
const debugLog = debug ? console.log : () => {};

class ConsoleAnalyticsGateway implements AnalyticsGatewayInterface {
  private identifiedUser?: TrackedUser;

  private identifyIfChanged(user: TrackedUser) {
    if (user.uid !== this.identifiedUser?.uid) {
      this.print("identifyUser->identifyIfChanged", user);
    }
    this.identifiedUser = user;
  }

  constructor(private print = debugLog) {}

  identifyUser(user: TrackedUser) {
    this.identifyIfChanged(user);
  }

  trackEvent(name: string, details?: Record<string, any>) {
    this.print("trackEvent", name, details);
  }

  trackPageContext(context: PageContext) {
    this.print("trackPageContext", context);
  }
}

export { ConsoleAnalyticsGateway };
