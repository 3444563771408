/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// React
import { useEffect, useRef, useState, memo } from "react";

// React Syntax Highlighter
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import json from "react-syntax-highlighter/dist/cjs/languages/hljs/json";
import snarkdown from "snarkdown";

// Virtualized List
import { Virtuoso } from "react-virtuoso";

// Theme
import theme from "@config/theme";

// Lodash Throttle
import throttle from "lodash/throttle";

import { formatJSONLine } from "@scripts/utils";
import { HistoryLine, HistoryLineSource } from "./HistoryLine";

const historyLineSourceNames: Record<HistoryLineSource, string> = {
  [HistoryLineSource.Error]: "error",
  [HistoryLineSource.HintMarkdown]: "hint",
  [HistoryLineSource.Input]: "input",
  [HistoryLineSource.Message]: "message",
  [HistoryLineSource.Output]: "output",
  [HistoryLineSource.Welcome]: "welcome",
};

// import syntax highlighter language
// json appears to colorize the repl json and output decently for now.
// maybe there's a better language to use?
SyntaxHighlighter.registerLanguage("json", json);

// disable/enable REPL exclusive history text selection
const selectOnlyREPLText = (on: boolean) => {
  const html = document.querySelector("html")?.classList;
  const repl = document.querySelector(".REPLHistory")?.classList;

  if (html && repl) {
    if (on) {
      html.add("repl-DisableSelect");
      repl.add("repl-EnableSelect");
    } else {
      html.remove("repl-DisableSelect");
      repl.remove("repl-EnableSelect");
    }
  }
};

type RowProps = {
  index: number;
  line: string;
  source: HistoryLineSource;
};

const Row = memo(({ index: _, line, source }: RowProps) => {
  const className = `${historyLineSourceNames[source]}Line historyRow`;
  return (
    <>
      {source === HistoryLineSource.HintMarkdown ? (
        <code
          className={className}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: snarkdown(line) }}
        />
      ) : (
        <SyntaxHighlighter
          language="json"
          wrapLines
          wrapLongLines
          showInlineLineNumbers={false}
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          style={replHistoryRowStyle}
          codeTagProps={{ className }}
        >
          {source === HistoryLineSource.Output ? formatJSONLine(line) : line}
        </SyntaxHighlighter>
      )}
      <div className="historyRowBottom" />
    </>
  );
});

Row.displayName = "memo(Row)";

// Core HistoryLog Component
type REPLHistoryProps = {
  lineBufferUpdateIntervalMs: number;
  lookDisabled?: boolean;
  onClick?: () => void;
  REPLHistoryLog: HistoryLine[];
};

const REPLHistoryCore = ({
  lineBufferUpdateIntervalMs,
  lookDisabled,
  onClick,
  REPLHistoryLog,
}: REPLHistoryProps) => {
  const virtuoso = useRef<any>(null);
  const [atBottom, setAtBottom] = useState(true);

  const log: HistoryLine[] = REPLHistoryLog;

  // used to get current historyLog length in scrollToBottom callback
  const currHistoryLogLen = useRef(log.length);
  currHistoryLogLen.current = log.length;
  // NOTE: be sure to verify that the REPL will stay scrolled to the
  // bottom when changing lineBufferUpdateIntervalMS
  const throttleDelay = 1.5 * lineBufferUpdateIntervalMs;
  // scrolls to bottom (last item index)
  function scrollToBottom() {
    // timeout to allow rendering when callback is leading
    // TODO: only apply timeout when callback is a leading call
    //       (can't find a way to do this with lodash)
    setTimeout(() => {
      virtuoso.current?.scrollToIndex({
        index: currHistoryLogLen.current - 1,
        align: "end",
      });

      const rows = document.getElementsByClassName("historyRowBottom");
      const lastRow = rows[rows.length - 1];
      if (lastRow) {
        lastRow.scrollIntoView();
      }

      // force this to true in cases where the virtual list range
      // update below sets to false
      setAtBottom(true);
      // make sure this is set to <= throttle delay to avoid cases
      // where continuous output is received over long durations and
      // scrolling down is repeated after it stops
    }, throttleDelay);
  }

  const autoScroller = useRef(throttle(scrollToBottom, throttleDelay));

  // new lines received.
  // if currently scrolled to bottom or new line is input, scroll to bottom
  useEffect(
    () => {
      const newInputLine =
        log[log.length - 1].source === HistoryLineSource.Input;

      if (atBottom || newInputLine) {
        autoScroller.current();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [log.length, atBottom]
  );

  const handleOnClick = () =>
    window.getSelection()?.toString() ? undefined : onClick?.();

  return (
    <>
      <div
        className={`REPLHistory ${lookDisabled ? "lookDisabled" : ""}`}
        // history text selection only.
        // NOTE: virtual list deselects text when not visible.
        // TODO: fix text selection and
        //       maintain selecting when mouse drags outside REPL.
        onMouseDown={() => selectOnlyREPLText(true)}
        onMouseUp={() => selectOnlyREPLText(false)}
        onBlur={() => selectOnlyREPLText(false)}
        onClick={handleOnClick}
        onKeyUp={() => {}}
        role="region"
      >
        {/* {atBottom && (
          <div style={{ position: "absolute", right: 0, background: "orange" }}>
            At bottom!
          </div>
        )} */}
        <Virtuoso
          ref={virtuoso}
          totalCount={log.length}
          initialTopMostItemIndex={log.length - 1}
          overscan={200}
          style={{
            height: "100%",
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
          rangeChanged={(range) => {
            setAtBottom(range.endIndex === log.length - 1);
          }}
          itemContent={(index: number) => (
            <Row
              index={index}
              line={log[index].line}
              source={log[index].source}
            />
          )}
        />
      </div>

      <style jsx>
        {`
          .REPLHistory {
            padding: 2px 0 0 0.75rem;
            background-color: ${theme.colors.replDark};
            flex: 1;
          }

          // Look disabled
          .REPLHistory.lookDisabled::after {
            content: "";
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: ${theme.colors.gray1};
            opacity: 0.5;
            cursor: default;
          }

          .REPLHistory :global(.errorLine span) {
            color: ${theme.colors.poppyRed} !important;
          }

          .REPLHistory :global(.hintLine) {
            color: ${theme.colors.saffronYellow} !important;
          }

          .REPLHistory :global(.inputLine span) {
            color: ${theme.colors.ultramarineTintLighter} !important;
            word-break: break-all;
          }

          .REPLHistory :global(.messageLine span) {
            color: ${theme.colors.gray6}98 !important;
          }

          .REPLHistory :global(.outputLine span) {
            word-break: break-all;
          }

          .REPLHistory :global(.welcomeLine span) {
            color: ${theme.colors.saffronYellowTintLighter} !important;
          }

          // Display text cursor
          .REPLHistory :global(div div) {
            cursor: text;
          }

          // ScrollBar Styling
          .REPLHistory :global(::-webkit-scrollbar) {
            width: ${theme.fonts.size.px12};
          }

          .REPLHistory :global(::-webkit-scrollbar-thumb) {
            background-color: ${theme.colors.mediumGray};
          }

          .REPLHistory :global(::-webkit-scrollbar-thumb:hover) {
            background-color: ${theme.colors.gray};
          }

          // selection enable/disable
          :global(.repl-DisableSelect) {
            user-select: none !important;
          }

          :global(.repl-EnableSelect) {
            user-select: text !important;
          }
        `}
      </style>
    </>
  );
};

REPLHistoryCore.defaultProps = {
  lookDisabled: false,
  onClick: () => {},
};

export const REPLHistory = memo(REPLHistoryCore);

export default REPLHistory;

// syntax highlighting theme
const c = theme.colors;

const cREPL = {
  hue1: c.teal,
  hue2: c.gray6,
  hue3: c.gray,
  hue4: c.gray6,
  hue5: c.notehubBlueTintLightest,
  hue6: c.lightGray,
  hue7: c.gray5,
  hue8: c.gray6,
  hue9: c.saffronYellow,
  hue10: c.notehubBlueTintLightest,
  hue11: c.saffronYellowTint,
  hue12: c.saffronYellowTintLighter,
  hue13: c.saffronYellow,
};

export const replHistoryRowStyle = {
  hljs: {
    display: "inline-flex",
    color: cREPL.hue2,
    cursor: "text",
    fontSize: theme.fonts.size.px16,
    margin: 0,
    padding: "1px 0",
    width: "100%",
  },
  "hljs-keyword": {
    color: cREPL.hue1,
  },
  "hljs-literal": {
    color: cREPL.hue1,
  },
  "hljs-symbol": {
    color: cREPL.hue1,
  },
  "hljs-name": {
    color: cREPL.hue1,
  },
  "hljs-link": {
    color: cREPL.hue1,
    textDecoration: "underline",
  },
  "hljs-built_in": {
    color: cREPL.hue10,
  },
  "hljs-type": {
    color: cREPL.hue10,
  },
  "hljs-number": {
    color: cREPL.hue11,
  },
  "hljs-class": {
    color: cREPL.hue11,
  },
  "hljs-string": {
    color: cREPL.hue12,
  },
  "hljs-meta-string": {
    color: cREPL.hue12,
  },
  "hljs-regexp": {
    color: cREPL.hue13,
  },
  "hljs-template-tag": {
    color: cREPL.hue13,
  },
  "hljs-subst": {
    color: cREPL.hue2,
  },
  "hljs-function": {
    color: cREPL.hue2,
  },
  "hljs-title": {
    color: cREPL.hue2,
  },
  "hljs-params": {
    color: cREPL.hue2,
  },
  "hljs-formula": {
    color: cREPL.hue2,
  },
  "hljs-comment": {
    color: cREPL.hue3,
    fontStyle: "italic",
  },
  "hljs-quote": {
    color: cREPL.hue3,
    fontStyle: "italic",
  },
  "hljs-doctag": {
    color: cREPL.hue9,
  },
  "hljs-meta": {
    color: cREPL.hue7,
  },
  "hljs-meta-keyword": {
    color: cREPL.hue7,
  },
  "hljs-tag": {
    color: cREPL.hue7,
  },
  "hljs-variable": {
    color: cREPL.hue8,
  },
  "hljs-template-variable": {
    color: cREPL.hue8,
  },
  "hljs-attr": {
    color: cREPL.hue4,
  },
  "hljs-attribute": {
    color: cREPL.hue4,
  },
  "hljs-builtin-name": {
    color: cREPL.hue4,
  },
  "hljs-section": {
    color: cREPL.hue5,
  },
  "hljs-emphasis": {
    fontStyle: "italic",
  },
  "hljs-strong": {
    fontWeight: "bold",
  },
  "hljs-bullet": {
    color: cREPL.hue6,
  },
  "hljs-selector-tag": {
    color: cREPL.hue6,
  },
  "hljs-selector-id": {
    color: cREPL.hue6,
  },
  "hljs-selector-class": {
    color: cREPL.hue6,
  },
  "hljs-selector-attr": {
    color: cREPL.hue6,
  },
  "hljs-selector-pseudo": {
    color: cREPL.hue6,
  },
};
