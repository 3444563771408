import React, { ReactNode, useCallback } from "react";
import { useRouter } from "next/router";

interface InterceptLinkProps {
  children: ReactNode;
}

export const ClientSideLinkRouting: React.FC<InterceptLinkProps> = ({
  children,
}) => {
  const router = useRouter();

  const handleLinkClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const target = event.target as HTMLAnchorElement;

      if (
        target.tagName === "A" &&
        target.href.startsWith(window.location.origin) &&
        !target.hasAttribute("download")
      ) {
        event.preventDefault();
        const href = target.getAttribute("href") || "";
        router.push(href);
      }
    },
    [router]
  );

  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  return <div onClick={handleLinkClick}>{children}</div>;
};

export default ClientSideLinkRouting;
