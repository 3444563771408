function imageImport(path: string) {
  path = path?.replace("/images/", "");
  // `images/` must be a literal in the require() because at build time webpack
  // needs to import _everything_ that the string interpolation _could be_ at
  // run time. By hard-coding 'images/` in the string webpack doesn't have to
  // work harder than it needs to.
  return require(`images/${path}`);
}

export const ImgSrc = (src: string) => imageImport(src).default?.src;
