/* eslint-disable no-param-reassign */
import { Options, OptionObjProps } from "@content/en/Options";

type StringToURIOptionalProps = {
  discourseLink?: boolean;
};

// transform a string into key/id format
export function stringToURI(
  str: string,
  options?: StringToURIOptionalProps,
): string {
  // replace em and en dashes with a simple hyphen
  str = str.replace(/—|–/g, "-");
  // trim string and convert periods, commas, spaces, slashes to dashes and lowercase
  str = str
    .trim()
    .replace(/\s+|[.,/]/g, "-")
    .toLowerCase();
  // replace c++ with cpp
  str = str.replace(/c\+\+/g, "cpp");

  // replace &
  str = str.replace(/&/g, options?.discourseLink ? "" : "and");

  // remove special characters
  str = str.replace(/[`~!@#$%^*()_“”‘’|+=?;:'"<>{}[\]\\/]/gi, "");
  // replace repeated dashes --- with one dash -
  str = str.replace(/-{2,}/g, "-");

  return str;
}

// HumanOptions help you turn keys into human-readable names.
// For example, HumanOptions['language']['c-cpp'] = 'C/C++'
export const HumanOptions = Object.fromEntries(
  Object.keys(Options).map((key) => [
    key,
    Object.fromEntries(
      Options[key].map((option) => [stringToURI(option), option]),
    ),
  ]),
);

// Get text after 1st char in string
export const getTextAfterChar = (str: string, char: string) =>
  str.substring(str.indexOf(char)).trim();

// path slugs match url slugs
// ex) 'content/en/start/tutorials/02 Sensor Tutorial'
// matches slug array ['tutorials','sensor-tutorial','notecarrier-al','arduino','c-cpp']
export const pathMatchesSlugs = (
  galaxy: string,
  path: string,
  slugs: string[],
) => {
  const pathArray = path.split("/");
  const pathSlugs = pathArray
    .slice(pathArray.indexOf(galaxy) + 1)
    .map((pathSlug) => stringToURI(getTextAfterChar(pathSlug, " ")));

  // check if param slugs match path slugs
  return (
    pathSlugs.filter((pathSlug, idx) => pathSlug === slugs[idx]).length ===
    pathSlugs.length
  );
};

// get url path from array of slug names
// ex) ["notecarrier", "board", "lanugage"] => "[notcarrier]/[board]/[language]"
export const slugArrayToPath = (names: string[]) =>
  names.map((param: string) => `[${param}]`).join("/");

// replace dashes with space and first letter capitalized
export const prettyStr = (str: string) =>
  str &&
  str
    .replace(/-/g, " ")
    .replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());

export const getWindowSize = () => {
  const isClient = typeof window === "object";
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  };
};

// get latest version of one of the obj arrays as defined in the Options.ts file
export const getLatestVersion = (options: OptionObjProps, key: string) => {
  const latestVersion = options[key][0];
  return latestVersion;
};

export function formatOptionVal(
  options: OptionObjProps,
  key: string,
  val: string,
) {
  if (val.includes("latest")) {
    const latestOptionVersion = getLatestVersion(options, key);
    // combine "Latest" string with latest version number as defined in Options.ts file
    return `Latest (${latestOptionVersion})`;
  }

  const result = options[key]?.find(
    (str) => stringToURI(str) === stringToURI(val),
  );
  return result || val;
}

// JSON string formatter:
//------------------------
// if the string begins with { and ends with },
// check if the line is json and format each key/val pair with newlines
export const formatJSONLine = (line: string) => {
  try {
    // convert string to object, then stringify object to auto-format it
    const obj = JSON.parse(line);
    // only format if more than 1 property
    if (line.split(":").length > 2) {
      return JSON.stringify(obj, null, 1);
    }
  } catch (e) {
    // do nothing on error (most likely from JSON.parse)
  }
  return line;
};

export const urlSegments = (url: string): string[] =>
  url?.split("/")?.filter((x) => x.length > 0);

export function pad<T>(
  list: T[],
  size: number,
  filler: any = undefined,
): (T | typeof filler)[] {
  if (list.length >= size) {
    return list;
  }
  return [...list, ...Array(size - list.length).fill(filler)];
}

export const guaranteeArray = (
  maybeArray: string | string[] | undefined,
): string[] => [maybeArray || []].flat();

// Our documents have file names with number prefixes, like "01-step-1",
// "02-step-2", etc. This function sorts them numerically if they have numbers,
// and alphabetically otherwise.
export const sortFileNamesByNumber = (a: string, b: string) => {
  // Extract the numeric part of the file from the first 2-3 characters
  const extractNumber = (str: string) => {
    const match = str.match(/^\d{1,3}/);
    return match ? parseInt(match[0], 10) : null;
  };

  const numA = extractNumber(a);
  const numB = extractNumber(b);

  if (numA !== null && numB !== null) {
    return numA - numB; // Compare numerically if both have numbers
  }

  return a.localeCompare(b); // Fallback to default comparison
};
