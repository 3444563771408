import {
  NotecardCommand,
  NotecardDeviceConnection,
} from "../NotecardDevice/NotecardDeviceConnection";
import { CommandResult } from "../DeviceConnection";

/* eslint-disable class-methods-use-this */

export default class Raw implements NotecardCommand {
  name = "raw";

  triggeredBy() {
    return true;
  }

  copy(): Raw {
    return new Raw();
  }

  async perform(
    deviceConnection: NotecardDeviceConnection,
    request: string
  ): Promise<CommandResult> {
    deviceConnection.write(`${request}\n`);
    return { history: [] };
  }
}
