import { REPLWidget } from "@components/repl/REPLWidget";
import { REPLTracked } from "@components/analytics/REPLTracked";
import { AuthAwareREPL } from "./AuthAwareREPL";

let integrated = REPLWidget;

integrated = REPLTracked(integrated);

integrated = AuthAwareREPL(integrated);

export const IntegratedREPL = integrated;
