import { Config } from "@configured/EnvironmentConfig";
import { Chunk, FirmwareInfo, TransactFn, dfuSideload } from "./ported/dfu";

type HubUploadQueryResponse = { err?: string; uploads?: FirmwareInfo[] };

type HubUploadGetRequest = {
  req: "hub.upload.get";
  type: "notecard";
  compress: string;
  name: string; // e.g. "notecard-4.1.1.4015681$20221206134917.bin";
  offset: number;
  length: number;
};
type HubUploadGetResponse = Chunk | { err: string };

const notehubApiUrl = Config.notehubReqApiUrl;

// When we started shipping notecards with different MCUs, the `target` field
// was added to the firmware metadata (and card.version response). This is the
// target for the vintage notecards before the target field was added.
const vintageTarget = "r5";

async function fetchAllFirmware(
  allowUnpublished = false
): Promise<FirmwareInfo[]> {
  // $ curl -L 'https://api.notefile.net/req' -d '{"req":"hub.upload.query","type":"notecard"}'
  const req = JSON.stringify({
    req: "hub.upload.query",
    type: "notecard",
    allow: allowUnpublished,
  });

  const response = await fetch(notehubApiUrl, { method: "POST", body: req });
  const object = (await response.json()) as HubUploadQueryResponse;
  if (object.err || !object.uploads) {
    throw new Error(`Could not get firmware list from notehub ${object.err}`);
  }
  return object.uploads;
}

// memoize fetchAllFirmware
const firmwareMemo = {
  published: [] as FirmwareInfo[],
  all: [] as FirmwareInfo[],
};
// getFirmware is a memoized fetchAllFirmware
export const getFirmware: typeof fetchAllFirmware = async (
  allowUnpublished = false
) => {
  const key = allowUnpublished ? "all" : "published";
  if (!firmwareMemo[key].length) {
    firmwareMemo[key] = await fetchAllFirmware(allowUnpublished);
  }
  return firmwareMemo[key];
};

interface CardVersionResponse {
  body?: {
    product?: string;
    target?: string;
  };
}

export function isCompatible(card: CardVersionResponse, fwInfo: FirmwareInfo) {
  const dev = card.body;
  if (!dev) return false;
  if (!fwInfo.firmware) return false;
  const { firmware } = fwInfo;
  const target = dev.target || vintageTarget;
  if (target === "wl") return false;
  if (dev.product !== firmware?.product) return false;
  if (target !== fwInfo.firmware?.target) return false;
  return true;
}

export async function listCompatibleFirmware(
  allowUnpublished: boolean,
  deviceTransact: TransactFn,
  allFirmware?: FirmwareInfo[]
) {
  const card = (await deviceTransact([
    `{"req":"card.version"}`,
  ])) as CardVersionResponse;

  const fwList = allFirmware || (await getFirmware(allowUnpublished));
  const compatibleFirmware = fwList.filter((fw) => isCompatible(card, fw));
  return compatibleFirmware;
}

export async function getFirmwareChunk(
  name: string,
  offset: number,
  byteLength: number,
  compressionMode: string
): Promise<Chunk> {
  const req: HubUploadGetRequest = {
    req: "hub.upload.get",
    type: "notecard",
    name,
    compress: compressionMode,
    offset,
    length: byteLength,
  };

  const response = await fetch(notehubApiUrl, {
    method: "POST",
    body: JSON.stringify(req),
  });
  const object = (await response.json()) as HubUploadGetResponse;
  if ("err" in object) {
    throw new Error(`Could not get firmware chunk from notehub ${object.err}`);
  }
  return object;
}

export async function doDFU(
  fw: FirmwareInfo,
  deviceTransact: TransactFn,
  reportStatus: (english: string, fraction: number) => void
) {
  const result = await dfuSideload(
    fw,
    getFirmwareChunk,
    deviceTransact,
    reportStatus
  );
  return result;
}
