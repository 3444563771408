import { useRouter } from "next/router";
import { useEffect, useState } from "react";

function useHash() {
  const [hash, setHash] = useState("");
  const router = useRouter();

  useEffect(() => {
    const handleHashChange = () => {
      const newHash = window.location.hash;
      if (newHash !== hash) setHash(window.location.hash);
    };

    handleHashChange();

    // add the hashchange event listener
    window.addEventListener("hashchange", handleHashChange);
    router.events.on("routeChangeStart", handleHashChange);
    window.addEventListener("popstate", handleHashChange);

    // clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
      router.events.off("routeChangeStart", handleHashChange);
      window.removeEventListener("popstate", handleHashChange);
    };
  }); // run every time

  return hash;
}

export default useHash;
