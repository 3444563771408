import { KeyboardEvent } from "react";
import DeviceConnection, { CommandResult } from "./DeviceConnection";
import { lines, HistoryLine, HistoryLineSource } from "./HistoryLine";
import { SerialPort } from "./WebSerialStandard";

const CLEAN_DISCONNECT = "$$clean$disconnect$$";
const isCleanDisconnect = (e: any) => String(e) === CLEAN_DISCONNECT;

export default class GenericDeviceConnection implements DeviceConnection {
  port?: SerialPort;

  inputDonePromise?: Promise<void>;

  onError?: (e: Error) => void;

  isLostConnection = (e: Error) => /The device has been lost/.test(`${e}`);

  isFailureToConnect = (e: Error) => /Failed to open/.test(`${e}`);

  onOutput?: (lines: HistoryLine[]) => void;

  outputDonePromise?: Promise<void>;

  writer?: WritableStreamDefaultWriter;

  reader?: ReadableStreamDefaultReader;

  constructor(port: SerialPort) {
    this.port = port;

    const decoder = new TextDecoderStream();
    this.inputDonePromise = this.port.readable
      .pipeTo(decoder.writable)
      .catch((e: Error) => {
        if (!isCleanDisconnect(e)) {
          this.onError?.(e);
        }
      });
    this.reader = decoder.readable.getReader();

    const encoder = new TextEncoderStream();
    this.outputDonePromise = encoder.readable
      .pipeTo(this.port.writable)
      .catch((e) => this.onError?.(e));
    this.writer = encoder.writable.getWriter();
  }

  async start(): Promise<void> {
    this.readAllLoop();
  }

  async readAllLoop() {
    for (;;) {
      if (!this.reader) throw Error("readAllLoop can't get reader");
      const { value, done } = await this.reader.read(); // eslint-disable-line
      if (value) {
        this.onOutput?.(lines(HistoryLineSource.Output, value, false));
      }
      if (done) {
        this.reader?.releaseLock();
        break;
      }
    }
  }

  async write(request: string) {
    if (this.port === undefined || this.writer === undefined) {
      throw new Error("cannot write to port");
    }
    return this.writer.write(request);
  }

  async evaluateInput(input: string): Promise<CommandResult> {
    this.write(`${input}\n`);
    return { history: [] };
  }

  static isLowerCase(str: String) {
    return str === str.toLowerCase() && str !== str.toUpperCase();
  }

  handleKey(keyboardEvent: KeyboardEvent<Element>): boolean {
    if (keyboardEvent.key?.length === 1) {
      if (
        keyboardEvent.ctrlKey &&
        GenericDeviceConnection.isLowerCase(keyboardEvent.key)
      ) {
        let n: number | undefined = keyboardEvent.key.codePointAt(0);
        if (n !== undefined) {
          n -= 96;
          if (n >= 0) {
            this.write(String.fromCodePoint(n));
          }
        }
      } else {
        this.write(keyboardEvent.key);
      }
    }
    return true;
  }

  async disconnect() {
    const error = (e: Error) => {
      console.log("Error while disconnecting: ", e); // eslint-disable-line
    };

    this.reader?.cancel(CLEAN_DISCONNECT).catch(error);

    await this.writer?.close().catch(error);
    this.reader?.releaseLock();
    this.writer?.releaseLock();
    await this.inputDonePromise?.catch(error);
    await this.outputDonePromise?.catch(error);
    await this.port?.close().catch(error);

    this.writer = undefined;
    this.reader = undefined;
    this.port = undefined;
  }
}
