export default class LineAggregator implements Transformer {
  awaitingLineBreak: string = "";

  transform(
    chunk: string,
    controller: TransformStreamDefaultController<string>
  ) {
    this.awaitingLineBreak += chunk;
    const lines = this.awaitingLineBreak.split(/\r?\n/);
    this.awaitingLineBreak = lines.pop() || "";
    lines.forEach((line) => controller.enqueue(line));
  }
}
