import { ConnectionType } from "@components/repl/REPL";
import {
  REPLRef,
  REPLWidget,
  REPLWidgetProps,
} from "@components/repl/REPLWidget";
import React, { forwardRef, useRef } from "react";

import { AnalyticsGateway } from "./AnalyticsGateway";

// Shallow replace values in a json string with "REDACTED (object|string)"
// except the value of "req":"this.that" because we are interested in the value
// of "req" and it's not sensitive.
const anonymize = (str: string) => {
  let thing: any;
  try {
    thing = JSON.parse(str);
  } catch (error) {
    thing = { nonJsonRequest: true };
  }

  if (["number", "undefined", "boolean", "bigint"].includes(typeof thing)) {
    return thing; // not redacted
  }
  if (typeof thing === "object") {
    const newObj: any = {};
    Object.keys(thing).forEach((key) => {
      if (key === "req" || !["string", "object"].includes(typeof thing[key])) {
        newObj[key] = thing[key];
      } else {
        newObj[key] = `REDACTED ${typeof thing[key]}`;
      }
    });
    return newObj;
  }
  return `REDACTED ${typeof thing}`;
};

export const REPLTracked = (WrappedComponent: typeof REPLWidget) => {
  const component: typeof REPLWidget = forwardRef<REPLRef, REPLWidgetProps>(
    (props: REPLWidgetProps, ref) => {
      const onCloseClick = () => {
        AnalyticsGateway().trackEvent("REPL Close Clicked");
        props.onCloseClick?.();
      };
      const didShowMessage = useRef(false);
      const onUnsupportedBrowser = () => {
        props.onUnsupportedBrowser?.();
        if (didShowMessage.current) {
          return;
        }
        AnalyticsGateway().trackEvent("REPL Unsupported Browser Message Shown");
        didShowMessage.current = true;
      };
      const onRequestSent = (request: string) => {
        AnalyticsGateway().trackEvent("REPL Sent Request", {
          request: anonymize(request),
        });
        props.onRequestSent?.(request);
      };
      const onConnectAttempt = () => {
        AnalyticsGateway().trackEvent("REPL Attempting Connect");
        props.onConnectAttempt?.();
      };
      const onConnected = (details: { type: ConnectionType }) => {
        AnalyticsGateway().trackEvent("REPL Connected", details);
        props.onConnected?.(details);
      };
      const onConnectFailed = (error: string) => {
        AnalyticsGateway().trackEvent("REPL Connection Failed", { error });
        props.onConnectFailed?.(error);
      };
      const onDisconnectAttempt = () => {
        AnalyticsGateway().trackEvent("REPL Attempting Disconnect");
        props.onDisconnectAttempt?.();
      };
      const onDisconnected = () => {
        AnalyticsGateway().trackEvent("REPL Disconnected");
        props.onDisconnected?.();
      };
      const onDisconnectFailed = (error: string) => {
        AnalyticsGateway().trackEvent("REPL Disconnect Failed", { error });
        props.onDisconnectFailed?.(error);
      };
      const onFullscreenToggleClick = () => {
        AnalyticsGateway().trackEvent("REPL Fullscreen Button Clicked");
        props.onFullscreenToggleClick?.();
      };
      const onSaveClick = () => {
        AnalyticsGateway().trackEvent("REPL Save Button Clicked");
        props.onSaveClick?.();
      };
      const onSignInClick = () => {
        AnalyticsGateway().trackEvent("Sign In Clicked", {
          location: "REPL",
        });
        props.onSignInClick?.();
      };
      const onSignUpClick = () => {
        AnalyticsGateway().trackEvent("Sign Up Clicked", {
          location: "REPL",
        });
        props.onSignInClick?.();
      };

      return (
        <WrappedComponent
          {...props}
          onCloseClick={onCloseClick}
          onUnsupportedBrowser={onUnsupportedBrowser}
          onRequestSent={onRequestSent}
          onConnectAttempt={onConnectAttempt}
          onConnected={onConnected}
          onConnectFailed={onConnectFailed}
          onDisconnectAttempt={onDisconnectAttempt}
          onDisconnected={onDisconnected}
          onDisconnectFailed={onDisconnectFailed}
          onFullscreenToggleClick={onFullscreenToggleClick}
          onSaveClick={onSaveClick}
          onSignInClick={onSignInClick}
          onSignUpClick={onSignUpClick}
          ref={ref}
        />
      );
    }
  );
  component.displayName = `REPLTracked`;
  return component;
};

export const testable = { anonymize };
