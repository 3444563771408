/* eslint-disable react/destructuring-assignment */
import React, { createContext, useEffect, useState, ReactNode } from "react";
import {
  mostRecentlySelected,
  newRecentSelections,
} from "@scripts/RecentSelectionsUtils";
import {
  DEFAULT_LOCAL_SETTINGS,
  Settings,
  GetSettings,
} from "@use-cases/GetSettingsInteractor";
import { CGetSettingsInteractor } from "@configured/use-cases/CGetSettingsInteractor";

type SettingsProviderProps = {
  children: ReactNode;
  settings: GetSettings;
};

const SettingsContext: React.Context<{
  mostRecentlySelected: (titles: string[]) => string | undefined;
  settings: Settings;
  updateSelections: (titles: string[]) => void;
  updateBannerSettings: (banners: string[]) => void;
}> = createContext({
  mostRecentlySelected: (titles: string[]): string | undefined => titles[0],
  settings: DEFAULT_LOCAL_SETTINGS,
  updateSelections: (_titles: string[]) => {},
  updateBannerSettings: (_banners: string[]) => {},
});

const SettingsProvider = (props: SettingsProviderProps) => {
  const [settings, updateSettings] = useState(DEFAULT_LOCAL_SETTINGS);
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
    updateSettings(props.settings.get());
  }, [hasMounted]);

  useEffect(() => {
    if (hasMounted) {
      props.settings.set(settings);
    }
  }, [settings]);

  const updateSelections = (titles: string[]) => {
    updateSettings({
      ...settings,
      recentSelections: newRecentSelections(
        titles,
        settings.recentSelections || []
      ),
    });
  };

  const updateBannerSettings = (banners: string[]) => {
    updateSettings({ ...settings, closedBanners: banners });
  };

  const mostRecentlySelectedFromSettings = (titles: string[]) =>
    mostRecentlySelected(titles, settings.recentSelections);

  return (
    <SettingsContext.Provider
      value={{
        mostRecentlySelected: mostRecentlySelectedFromSettings,
        settings,
        updateSelections,
        updateBannerSettings,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.defaultProps = {
  settings: CGetSettingsInteractor,
};

export { SettingsContext, SettingsProvider };
