import memoize from "fast-memoize";

export interface ExampleReceiver {
  readonly receive: (example: string) => void;
  readonly isReady: () => boolean;
}

export interface TryExample {
  readonly try: (example: string) => void;
  readonly isTriable: (example: string) => boolean;
}

const formattedForNotecard = (example: string) => {
  let out = example.trim();
  out = out.replace(/(\r\n|\n|\r|^\s{2,})/gm, "");
  out = out.replace(/:\s*/gm, ":");
  out = out.replace(/\}\s*\{/gm, "}\n{");
  return out;
};

function slowParseObjectsFromStringOfManyJSON(s: string): object[] | false {
  const objects: object[] = [];
  let example = s.trim();
  while (example.length) {
    for (let len = 0; len <= example.length; len += 1) {
      const element = example.substring(0, len);
      try {
        const obj = JSON.parse(element);
        if (typeof obj !== "object") {
          throw Error("non-object found");
        }
        objects.push(obj);
        example = example.substring(len);
        break;
      } catch (error) {
        if (len === example.length) {
          return false;
        }
      }
    }
  }
  return objects;
}

const parseObjectsFromStringOfManyJSON = memoize(
  slowParseObjectsFromStringOfManyJSON
);

const containsOnlyNotecardRequests = memoize(function (
  example: string
): boolean {
  const objects = parseObjectsFromStringOfManyJSON(example);
  if (objects) {
    const validRequests = objects.filter((obj: object) => "req" in obj);
    return objects.length === validRequests.length;
  }
  return false;
});

export class TryExampleInteractor implements TryExample {
  private receiver?: ExampleReceiver;

  try(example: string) {
    const request = formattedForNotecard(example);
    if (!this.isTriable(request)) {
      return false;
    }
    if (!this.receiver) {
      return false;
    }
    this.receiver.receive(request);
    return true;
  }

  isTriable(example: string) {
    if (!this.receiver) {
      return false;
    }
    if (!this.receiver.isReady()) {
      return false;
    }
    if (!containsOnlyNotecardRequests(example)) {
      return false;
    }
    return true;
  }

  setReceiver(r: ExampleReceiver) {
    this.receiver = r;
  }
}

export const testable = {
  parseObjectsFromStringOfManyJSON,
  containsOnlyNotecardRequests,
  formattedForNotecard,
};
