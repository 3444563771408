import { NotecardCommand } from "../NotecardDevice/NotecardDeviceConnection";
import Bootloader from "./Bootloader";
import Explore from "./Explore";
import FactoryReset from "./FactoryReset";
import Firmware from "./Firmware";
import Help from "./Help";
import Import from "./Import";
import Info from "./Info";
import Ndjson from "./Ndjson";
import Raw from "./Raw";
import SyncTrace from "./SyncTrace";

export const LiveCommands: NotecardCommand[] = [
  new Explore(),
  new FactoryReset(),
  new Import(),
  new Help(),
  new Info(),
  new Firmware(),
  new SyncTrace(),
  new Ndjson(),
  new Bootloader(),
  new Raw(), // Make sure this is last since it accepts everything.
];

const DEFAULT = { LiveCommands };
export default DEFAULT;
