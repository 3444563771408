import { HistoryLine, errorLines, messageLines } from "../HistoryLine";
import {
  NotecardCommand,
  NotecardDeviceConnection,
} from "../NotecardDevice/NotecardDeviceConnection";
import { CommandResult } from "../DeviceConnection";
import { BOOL, NUM, STR, has } from "../NotecardDevice/NotecardAPIValidation";
import { newTable } from "./utils/format";

/* eslint-disable class-methods-use-this */

const COMMAND = "info";
const PRODUCT_UID_NOT_SET_WARNING =
  "*** Product UID is not set. Please use notehub.io to create a project and a product UID ***";

export default class Info implements NotecardCommand {
  name = COMMAND;

  deviceConnection?: NotecardDeviceConnection;

  errors: HistoryLine[] = [];

  allOutput: HistoryLine[] = [];

  triggeredBy(request: string) {
    return request.trim().toLowerCase() === COMMAND;
  }

  formatDateTime(seconds?: number): string {
    if (!seconds) {
      return "";
    }
    const t = new Date(seconds * 1000); // Epoch
    return `${t.toString()} (${t.toISOString()})`;
  }

  copy(): Info {
    return new Info();
  }

  appendMessage(lines: string) {
    this.allOutput.push(...messageLines(lines));
  }

  appendError(lines: string) {
    this.errors.push(...errorLines(lines));
  }

  async fromDevice(request: string): Promise<unknown> {
    const response: unknown = await this.deviceConnection!.performTransaction(
      `{"req": "${request}"}`
    );

    if (has(response, { err: STR })) {
      if (!response.err.includes("{not-supported}"))
        this.appendError(`${request}: ${response.err}`);
    }

    return response;
  }

  gpsModeOutput({ status, mode }: { status?: string; mode: string }): string {
    return !status ? mode : `${mode} (${status})`;
  }

  locationOutput(loc: unknown): string {
    const outputParts = [];
    if (has(loc, { lat: NUM, lon: NUM }))
      outputParts.push(`${loc.lat},${loc.lon}`);
    if (has(loc, { olc: STR })) outputParts.push(`(${loc.olc})`);
    return outputParts.join(" ");
  }

  fileChangesOutput(fileChanges: unknown): string {
    if (!has(fileChanges, { info: {} })) return "";
    return Object.entries(fileChanges.info)
      .map(([key]) => key)
      .join(", ");
  }

  formatJson(json: unknown): string {
    if (!json) {
      return "";
    }
    return JSON.stringify(json, null, 2);
  }

  outboundOutput(hubGet: unknown): string {
    let outboundPeriod = "-";
    if (has(hubGet, { minutes: NUM })) {
      outboundPeriod = `${hubGet.minutes} minutes`;
    }
    if (has(hubGet, { outbound: NUM })) {
      outboundPeriod = `${hubGet.outbound} minutes`;
    }
    if (has(hubGet, { voutbound: STR })) {
      outboundPeriod = hubGet.voutbound;
    }
    return outboundPeriod;
  }

  inboundOutput(hubGet: unknown): string {
    let inboundPeriod = "-";
    if (has(hubGet, { hours: NUM })) {
      inboundPeriod = `${hubGet.hours} hours`;
    }
    if (has(hubGet, { inbound: NUM })) {
      inboundPeriod = `${hubGet.inbound} minutes`;
    }
    if (has(hubGet, { vinbound: STR })) {
      inboundPeriod = hubGet.vinbound;
    }
    return inboundPeriod;
  }

  async perform(
    deviceConnection: NotecardDeviceConnection,
    _request: string
  ): Promise<CommandResult> {
    this.deviceConnection = deviceConnection;

    const envGet = await this.fromDevice("env.get");
    const fileChanges = await this.fromDevice("file.changes");
    const hubGet = await this.fromDevice("hub.get");
    const hubStatus = await this.fromDevice("hub.status");
    const hubSyncStatus = await this.fromDevice("hub.sync.status");
    const location = await this.fromDevice("card.location");
    const locationMode = await this.fromDevice("card.location.mode");
    const status = await this.fromDevice("card.status");
    const temp = await this.fromDevice("card.temp");
    const time = await this.fromDevice("card.time");
    const usage = await this.fromDevice("card.usage.get");
    const version = await this.fromDevice("card.version");
    const voltage = await this.fromDevice("card.voltage");
    const wireless = await this.fromDevice("card.wireless");

    // Normally I would avoid this kind of thing but it makes the following so
    // much easier to read
    const table = newTable({ colAligns: ["right", "left"] });
    const print = (header: string, body: string) =>
      table.push([`${header}:`, body]);

    const normal = "";
    const weird = "{?}";

    this.appendMessage((has(version, { name: STR }) && version.name) || weird);

    print(
      "ProductUID",
      (has(hubGet, { product: STR }) && hubGet.product) ||
        PRODUCT_UID_NOT_SET_WARNING
    );

    print(
      "DeviceUID",
      (has(version, { device: STR }) && version.device) || weird
    );

    print("Serial Number", (has(hubGet, { sn: STR }) && hubGet.sn) || normal);

    print("Notehub Host", (has(hubGet, { host: STR }) && hubGet.host) || weird);

    print(
      "Firmware Version",
      (has(version, { version: STR }) && version.version) || weird
    );

    print("SKU", (has(version, { sku: STR }) && version.sku) || weird);

    if (has(wireless, { net: { modem: STR } })) {
      print("Modem", wireless.net.modem);
      if (has(wireless, { net: { iccid: STR } }))
        print("ICCID", wireless.net.iccid);
      if (has(wireless, { net: { imsi: STR } }))
        print("IMSI", wireless.net.imsi);
      if (has(wireless, { net: { imei: STR } }))
        print("IMEI/MAC", wireless.net.imei);
    } else print("Modem", weird);

    if (has(wireless, { net: { iccid_external: STR } })) {
      print("External ICCID", wireless.net?.iccid_external);
      if (has(wireless, { net: { imsi: STR } }))
        print("External IMSI", wireless.net?.imsi);
    }

    print(
      "Provisioned",
      (has(usage, { time: NUM }) && this.formatDateTime(usage.time)) || normal
    );

    const usedBytes =
      ((has(usage, { bytes_sent: NUM }) && usage?.bytes_sent) || 0) +
      ((has(usage, { bytes_received: NUM }) && usage?.bytes_received) || 0);
    print("Used Over-the-Air", (usedBytes && `${usedBytes} bytes`) || weird);

    print("Sync Mode", (has(hubGet, { mode: STR }) && hubGet?.mode) || weird);
    print("Sync Upload Period", this.outboundOutput(hubGet));
    print("Download Period", this.inboundOutput(hubGet));

    print(
      "Notehub Status",
      `${(has(hubStatus, { status: STR }) && hubStatus.status) || weird}${
        has(hubStatus, { connected: BOOL }) && hubStatus.connected
          ? " (connected)"
          : ""
      }`
    );

    print(
      "Last Synced",
      (has(hubSyncStatus, { time: NUM }) &&
        this.formatDateTime(hubSyncStatus.time)) ||
        normal
    );

    print(
      "Voltage",
      (has(voltage, { value: NUM }) && `${voltage.value.toFixed(2)}V`) || weird
    );

    print(
      "Temperature",
      (has(temp, { value: NUM }) && `${temp.value.toFixed(2)}C`) || weird
    );

    print(
      "GPS Mode",
      (has(locationMode, { mode: STR }) && this.gpsModeOutput(locationMode)) ||
        normal
    );

    print("Location", this.locationOutput(location));

    print(
      "Current Time",
      (has(time, { time: NUM }) && this.formatDateTime(time.time)) || normal
    );

    print(
      "Boot Time",
      (has(status, { time: NUM }) && this.formatDateTime(status.time)) || normal
    );

    print("Notefiles", this.fileChangesOutput(fileChanges));
    print(
      "Notefile Storage Used",
      (has(status, { storage: NUM }) && `${status.storage}%`) || weird
    );

    print(
      "Env",
      (has(envGet, { body: {} }) && this.formatJson(envGet.body)) || weird
    );

    this.appendMessage(table.toString());
    this.allOutput = this.allOutput.concat(this.errors);
    return { history: this.allOutput };
  }
}
