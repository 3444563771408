import { NotecardCommand } from "../NotecardDevice/NotecardDeviceConnection";
import { CommandResult } from "../DeviceConnection";
import { messageLines } from "../HistoryLine";

/* eslint-disable class-methods-use-this */

const COMMAND = "help";

export const HELP_MESSAGE: string =
  `In-Browser Terminal - communicate with a serial-connected Notecard.

USAGE:

  {JSON}        Send an API request to an attached Notecard. For example {"req":"card.version"}
  [TRY]         Click the TRY button on any JSON example in the docs to send the example to the terminal.
  TEXT          Send a diagnostic command to the Notecard. For example 'restart' or another` +
  ` command customer support may recommend.
  explore       Explore the notefiles and notes on the Notecard.
  factory-reset Factory reset and restart the Notecard. Alias for {"req":"card.restore","delete":true}.
  firmware      Perform firmware update on a Notecard.
  help          Display this message.
  info          Gather some general info about an attached Notecard.
  import        Import a script of Notecard requests from a GitHub gist.
  sync-trace    Having trouble connecting to Notehub.io? Make a single attempt to sync` +
  ` with Notehub and collect a trace log, which is useful for customer support.

KEY: Text in the history is prefixed (and colored) based on type and source.

  (no prefix) JSON and text     from the Notecard
      >       JSON and commands from the terminal user
      ~       info              from the terminal
      *       errors            from the terminal
      Hint:   hints             from the terminal

SHORTCUTS:

  [up arrow]    Get previous commands from sent-command history
  [down arrow]  Get next commands from sent-command history

Created by Blues`;

export default class Help implements NotecardCommand {
  name = COMMAND;

  triggeredBy(request: string) {
    return request.trim().toLowerCase() === COMMAND;
  }

  copy(): Help {
    return new Help();
  }

  async perform(): Promise<CommandResult> {
    return { history: messageLines(HELP_MESSAGE) };
  }
}
