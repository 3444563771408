import React, { useCallback, useEffect } from "react";
import useForwardRef from "@bscop/use-forward-ref";

import theme from "@config/theme";

interface REPLInputBarProps {
  enabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onSubmit: (request: string) => void;
  value: string;
}

export type REPLInputRef = { setInputFocus: () => void };

export const REPLInputBar = React.forwardRef<
  HTMLTextAreaElement,
  REPLInputBarProps
>(
  (
    { enabled, onChange, onKeyDown, onSubmit, value }: REPLInputBarProps,
    remoteRef
  ) => {
    // Input resizing
    const ref = useForwardRef(remoteRef);

    const resize = useCallback(() => {
      if (!ref?.current) return;
      ref.current.style.height = "auto";
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }, [ref]);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      resize();
      return onChange(e);
    };

    useEffect(() => resize(), [value, resize]);

    return (
      <>
        <div id="replInputContainer" className="StyledTextAreaContainer layout">
          <div className="promptAppearance promptPosition" />
          <textarea
            id="replInput"
            className="StyledTextArea inputPosition"
            ref={ref}
            data-testid="REPL-input"
            value={value}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            disabled={!enabled}
          />
          <button
            type="button"
            data-testid="REPL-send-button"
            className="send sendPosition sendAppearance"
            onClick={() => onSubmit(value)}
            disabled={!value}
          >
            <span className="material-icons-round">send</span>
          </button>
        </div>
        <style jsx>{`
          .StyledTextAreaContainer {
            font-size: ${theme.fonts.size.px14};
            font-weight: 500;
            letter-spacing: ${theme.fonts.letterSpacing.one};
            background-color: ${theme.colors.darkCharlestonGreen};

            touch-action: none;
          }

          .layout {
            width: 100%;
            min-height: 60px;
            max-height: calc(100%);
            overflow: auto;
            display: grid;
            align-items: start;
            border: 0;
            grid-template-columns: max-content auto max-content;
          }

          .promptPosition {
            margin: 0.2rem 0.25rem 0.25rem 0.25rem;
          }

          .inputPosition {
            align-self: stretch;
          }

          .sendPosition {
            position: sticky;
            top: 0;
          }

          .promptAppearance::before {
            font-size: ${theme.fonts.size.px16};
            font-family: var(--fira-code-font);
            color: ${theme.colors.saffronYellow};
            content: ">";
          }

          :global(.disconnected .promptAppearance::before) {
            content: "";
          }

          .StyledTextArea {
            font-size: ${theme.fonts.size.px14};
            font-family: var(--fira-code-font);
            color: ${theme.colors.gray5};
            background-color: transparent;
            border: 0;
            line-height: 1.5em;
            padding: 0.25rem;
            overflow: hidden;
            box-sizing: border-box;
            resize: none;
            word-break: break-all;
            max-height: calc(100%);
            cursor: auto; // fixes scrollbar pointer
          }

          .StyledTextAreaContainer:hover {
            background-color: ${theme.colors.gray1};
          }

          .StyledTextAreaContainer.textareaExpanded .StyledTextArea:hover {
            background-color: transparent;
          }

          // make the repl look disabled when disconnected
          :global(.REPL.disconnected .StyledTextAreaContainer::after) {
            content: "";
            background: ${theme.colors.gray1};
            opacity: 0.5;
          }

          button.send {
            background: transparent;
            border: none;
            padding: 0;
          }

          .sendAppearance {
            width: 2rem;
            height: 2rem;
            margin: 0.75rem;
            color: ${theme.colors.lightGray};
          }

          .sendAppearance:hover:not([disabled]),
          .sendAppearance:focus-visible:not([disabled]),
          .sendAppearance.active:not([disabled]) {
            color: ${theme.colors.ultramarineTint};
          }
        `}</style>
      </>
    );
  }
);
REPLInputBar.displayName = "REPLInputBar";
