import * as Sentry from "@sentry/browser";
import { Config } from "./EnvironmentConfig";

// CMonitoring does error monitoring and performance sampling in the wild.
export const CMonitoring = () => {
  if (!["staging", "production", "development"].includes(Config.envName))
    return;

  const tracesSampleRate = 1.0;

  if (Config.envName === "development") {
    return;
  }

  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: Config.envName,
    release: Config.buildVersion,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate,
  });
};
