// Types
export interface JsonObject {
  [key: string]: JsonValue;
}
export type JsonArray = Array<JsonValue>;
export type JsonValue =
  | string
  | number
  | boolean
  | null
  | JsonObject
  | JsonArray;

// Examples of different data types.
export const NUM = 1;
export const STR = "";
export const BOOL = true;

/*
 * `has()` is a simple data validator we can use when we get reponses from the
 * Notecard API and want to be sure it has the fields we're expecting. It's
 * implemented as a generic type guard function so we can stop using the
 * dangerous 'any' type in our code and we can cleanly detect when a notecard
 * give an unexpected response.
 *
 * Usage:
 * const rsp = await request({req: "hub.get"})
 * // rsp is type 'unknown'
 *
 * const expectedTemplate = { foo: STR ,baz: NUM, qux:[STR] }
 * if( !has(rsp, expectedTemplate ) )
 *   throw new Error("Notecard response does not match expected type")
 *
 * // rsp is now type {foo:string, baz:number, qux:string[]}
 */
export function has<T extends JsonValue>(
  data: unknown,
  expected: T
): data is T {
  function isObject(value: unknown): value is Record<string, unknown> {
    return typeof value === "object" && value !== null;
  }

  function isArray(value: unknown): value is unknown[] {
    return Array.isArray(value);
  }

  function matchObject(obj: unknown, example: JsonObject): boolean {
    if (!isObject(obj)) {
      return false;
    }

    return Object.keys(example).every((key) => {
      if (!(key in obj)) {
        return false;
      }
      return has(obj[key], example[key]);
    });
  }

  function matchArray(arr: unknown, example: JsonArray): boolean {
    if (!isArray(arr)) {
      return false;
    }

    if (example.length === 0) {
      return true;
    }

    if (example.length > 1) {
      if (example.some((item) => !has(item, example[0]))) {
        throw new Error(
          "Expected array elements must have all the same type or be empty"
        );
      }
    }

    return arr.every((item) => has(item, example[0]));
  }

  if (isArray(expected)) return matchArray(data, expected);

  if (isObject(expected)) return matchObject(data, expected);

  return typeof data === typeof expected;
}
