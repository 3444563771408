import {
  AnalyticsGatewayInterface,
  PageContext,
  TrackedUser,
} from "@use-cases/AnalyticsGatewayInterface";

function labelForGoogleAnalytics(details?: Record<string, any>) {
  return { label: JSON.stringify(details), ...details };
}

class MarketingTransformGateway implements AnalyticsGatewayInterface {
  private sinkGateway: AnalyticsGatewayInterface;

  private domainsTrackedAsInternal: string[];

  constructor(
    sinkGateway: AnalyticsGatewayInterface,
    domainsTrackedAsInternal: string[]
  ) {
    this.sinkGateway = sinkGateway;
    this.domainsTrackedAsInternal = domainsTrackedAsInternal;
  }

  identifyUser(user: TrackedUser) {
    this.sinkGateway.identifyUser(user);
  }

  trackEvent(name: string, details?: Record<string, any>) {
    details = labelForGoogleAnalytics(details);

    if (!this.isEventToBeTracked(name, details)) return;

    this.sinkGateway.trackEvent(name, details);
  }

  trackPageContext({ sectionID }: PageContext) {
    this.sinkGateway.trackPageContext({ sectionID });
  }

  private isEventToBeTracked = (
    name: string,
    details?: Record<string, any>
  ) => {
    if (/Link to External Site Clicked/.test(name)) {
      if (this.isDomainTrackedAsInternal(details)) {
        return false;
      }
    }
    return true;
  };

  private isDomainTrackedAsInternal = (details?: Record<string, any>) => {
    if (!details?.href) return true;
    const host = new URL(details.href).hostname;
    return this.domainsTrackedAsInternal.some((d) => host.endsWith(d));
  };
}

export { MarketingTransformGateway };
