import {
  NotecardCommand,
  NotecardDeviceConnection,
} from "../NotecardDevice/NotecardDeviceConnection";
import { CommandResult } from "../DeviceConnection";
import { hintMarkdownLines } from "../HistoryLine";

/* eslint-disable class-methods-use-this */

export default class Bootloader implements NotecardCommand {
  COMMANDS = ["bootloader", "zxc"];

  name = "bootloader";

  triggeredBy(request: string) {
    return this.COMMANDS.includes(request);
  }

  copy(): Bootloader {
    return new Bootloader();
  }

  async perform(
    deviceConnection: NotecardDeviceConnection,
    request: string
  ): Promise<CommandResult> {
    await deviceConnection.write(`${request}\n`);
    await deviceConnection.disconnect();
    return {
      history: hintMarkdownLines("Disconnected terminal for firmware update"),
    };
  }
}
