/* eslint-disable react/destructuring-assignment */
import Link from "next/link";

// SmartLink makes a link appear and behave the way we want in our docs:
// * for links to other pages on the site: NextJS Client-Side-Routing
// * for links to other sections on the page: Normal <a> behavior
// * for links to external sites:
//   * when clicked opens link in a new tab
//   * displays an (open in new) icon for string links (not elements like <img>)
//
// Usage: <SmartLink href="/foo/bar" ...>clickable children</SmartLink> Any
// properties usually used on a link can given to the SmartLink and they'll be
// passed through to the resulting <a> anchor element.

// AProps are the normal props an html <a> element takes.
interface AProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {}

export interface LinkSummary {
  type: "internal" | "external" | "section";
  href: string;
}

export interface SmartLinkProps extends AProps {
  // csrLinkComponent (default: next/link's <Link>) is the react component that
  // we use to accomplish client-side-routing for internal links to other pages
  // on the same site.
  csrLinkComponent?: (props: AProps) => JSX.Element;
  onClickPreamble?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    summary: LinkSummary
  ) => void;
}

export const SmartLink = (props: SmartLinkProps) => {
  if (!props.children)
    throw "err: SmartLink should have `children` but none given";

  const CSRLinkComponent = props.csrLinkComponent || Link;

  // make mutable object for props to pass through to <a>
  const aProps = { ...props };
  // remove our special SmartLink props since they don't apply to a vanilla <a>
  delete aProps.csrLinkComponent;
  delete aProps.onClickPreamble;

  const isNoopLink = !props.href || props.href == "#" || props.href == "";
  const isSectionLink = !isNoopLink && props.href?.startsWith?.("#"); // Links to an anchor on the current page.
  const isExternalLink = [
    "http://",
    "https:/", // one '/' intentionally to make 7 characters
    "mailto:",
  ].includes(props.href?.slice(0, 7) || "");

  const onClickHandler = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.persist();
    props.onClickPreamble?.(event, {
      href: props.href || "",
      type: isSectionLink
        ? "section"
        : isExternalLink
        ? "external"
        : "internal",
    });

    props.onClick?.(event);
  };

  if (isExternalLink) {
    aProps.target = aProps.target || "_blank";
    aProps.rel = "noopener";

    return (
      <>
        <a {...aProps} onClick={onClickHandler}>
          {props.children}
          {
            /* open_in_new icon from material theme */
            typeof props.children === "string" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                width="0.8em"
                height="0.8em"
                viewBox="0 0 24 24"
                data-testid="open-in-new-tab-icon"
              >
                <path d="M0 0h24v24H0z" fill="none" />{" "}
                <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" />{" "}
              </svg>
            )
          }
        </a>
        <style jsx>{`
          svg {
            vertical-align: top;
          }
        `}</style>
      </>
    );
  }

  if (isSectionLink || isNoopLink) {
    // Just a link to another section on the same page. Don't need fancy nextjs routing.
    return (
      <a {...aProps} onClick={onClickHandler}>
        {props.children}
      </a>
    );
  }

  // Internal link to another page on the site.
  return (
    <>
      <CSRLinkComponent
        href={props.href || "#"}
        {...aProps}
        onClick={onClickHandler}
      >
        {props.children}
      </CSRLinkComponent>
    </>
  );
};

export default SmartLink;
