import * as Sentry from "@sentry/react";
import { ReactNode } from "react";

export function BluesErrorBoundary({
  boundaryName,
  children,
}: {
  boundaryName: string;
  children: ReactNode;
}) {
  return (
    <Sentry.ErrorBoundary
      showDialog
      beforeCapture={(scope) => {
        scope.setTag("boundaryName", boundaryName);
      }}
      fallback={
        <>
          An error has occurred. Our team has been notified. If you encounter
          this again please try a different browser.
        </>
      }
      dialogOptions={{
        subtitle:
          "Our team has been notified. If you encounter this again try a different browser.",
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

const DEFAULT = { BluesErrorBoundary };

export default DEFAULT;
