// theme
const theme = {
  fonts: {
    size: {
      px10: "0.63rem",
      px12: "0.75rem",
      px14: "0.88rem",
      px16: "1rem",
      px18: "1.13rem",
      px20: "1.25rem",
      px22: "1.38rem",
      px24: "1.5rem",
      px26: "1.625rem",
      px28: "1.75rem",
      px30: "1.88rem",
      px32: "2rem",
      px34: "2.13rem",
      px36: "2.25rem",
      px48: "3rem",
      px56: "3.5rem",
    },
    letterSpacing: {
      one: "0.01rem",
      two: "0.02rem",
      three: "0.03rem",
      five: "0.05rem",
      ten: "0.1rem",
    },
  },

  colors: {
    // new brand colors
    saffronYellow: "#F7C23E",
    saffronYellowTint: "#FFDC8D",
    saffronYellowTintLighter: "#FFE9C2",
    saffronYellowTintLightest: "#FFF4E1",
    saffronYellowShade: "#DBA326",
    ultramarine: "#4400FF",
    ultramarineTint: "#5993FB",
    ultramarineTintLighter: "#A2C7FC",
    ultramarineTintLightest: "#DCECFF",
    ultramarineShade: "#0404AF",
    poppyRed: "#DB3939",
    poppyRedTint: "#F76B6B",
    poppyRedTintLighter: "#FFABAB",
    poppyRedTintLightest: "#FFDCDC",
    poppyRedShade: "#9B1E1E",
    kellyGreen: "#73CC3A",
    kellyGreenTint: "#A5DE81",
    kellyGreenTintLighter: "#D6F4C3",
    kellyGreenTintLightest: "#EAFCDE",
    kellyGreenShade: "#49A30F",
    notehubBlue: "#1C3A52",
    notehubBlueTint: "#1D4F77",
    notehubBlueTintLighter: "#729BB5",
    notehubBlueTintLightest: "#B1D3E8",
    notehubBlueShade: "#152C3E",
    gray1: "#333",
    gray2: "#595959",
    gray3: "#808080",
    gray4: "#CCC",
    gray5: "#F2F2F2",
    gray6: "#F8F8F8",
    black: "#000",
    white: "#FFF",
    devGray: "#E2E8F0",

    // old brand colors
    // todo (paige) address these during the redesign
    text: "#1F282E",
    blue0: "#E8F9FF",
    grayBlue: "#9EBACC",
    snowBlue: "#A7C0D1",
    lightSnowBlue: "#DBE9F3",
    turquoise: "#8CCEDB",
    darkTurquoise: "#3B8B9A",
    replDark: "#011627",
    gray: "#A0AFB9",
    darkCharlestonGreen: "#21282D",
    mediumGray: "#6B7C87",
    lightGray: "#CED9E1",
    teal: "#59D2FF",
  },

  misc: {
    boxShadow: "0px 5px 10px rgba(54, 69, 79, 0.1)",
    boxShadowBlogImage: "0px 1px 4px rgba(0, 0, 0, 0.25)",
    boxShadowDropDownMenu: "0px 2px 6px rgba(0, 0, 0, 0.14)",
    cardShadow: "0px 2px 10px rgb(27 58 82 / 25%)",
  },

  zIndex: {
    bottom: 0,
    main: 10,
    footer: 20,
    rating: 40,
    repl: 50,
    mobileMenu: 55,
    logo: 60,
    menu: 65,
    dropdown: 70,
    header: 80,
    top: 100,
    modal: 110,
    supportChat: 120,
    tooltip: 9999,
  },
  menu: {
    width: "14rem",
  },
  header: {
    height: "3.4rem",
  },
  repl: {
    height: {
      right: "100%",
    },
  },
  banner: {
    height: "115px",
  },
  announcementBanner: {
    height: {
      largeScreen: "36px",
      mediumScreen: "54px",
      smallScreen: "76px",
    },
  },

  breakpoints: {
    sm: 480,
    md: 991,
    lg: 1279,
    xl: 1439,
    noReplMobile: 600,
    betweenSmMd: 768,
    condenseTopNavBar: 870,
  },
};

export default theme;
