import { useRouter } from "next/router";
import { useEffect } from "react";

import { AnalyticsGateway } from "./AnalyticsGateway";

// RouteTracker will notice when the customer navigates, without doing a full
// page reload, and will pass the new url to AnalyticsGateway for dissemination.
export const RouteTracker = () => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (_url: string) => {
      AnalyticsGateway().trackPageContext({});
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      // component unmounted, unsubscribe from the event with the `off` method:
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  return null;
};
