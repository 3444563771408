import { AnalyticsGatewayInterface } from "@use-cases/AnalyticsGatewayInterface";
import { Config } from "@configured/EnvironmentConfig";
import { ConsoleAnalyticsGateway } from "@analytics/ConsoleAnalyticsGateway";
import { MarketingTransformGateway } from "@analytics/MarketingTransformGateway";
import { SegmentAnalyticsGateway } from "@analytics/SegmentAnalyticsGateway";
import { SetAnalyticsGatewayImpl } from "@components/analytics/AnalyticsGateway";

const defaultGateway = new ConsoleAnalyticsGateway();
// eslint-disable-next-line import/no-mutable-exports
let CAnalyticsGateway: AnalyticsGatewayInterface = defaultGateway;

if (Config.segmentEnabled && Config.isBrowser) {
  CAnalyticsGateway = new SegmentAnalyticsGateway(
    Config.segmentWriteKey,
    Config.segmentEnvironment
  );
}

CAnalyticsGateway = new MarketingTransformGateway(
  CAnalyticsGateway,
  Config.domainsTrackedAsInternal
);

SetAnalyticsGatewayImpl(CAnalyticsGateway);

export { CAnalyticsGateway };
