export interface HistoryLine {
  line: string;
  source: HistoryLineSource;
  nobreak?: boolean;
}

export enum HistoryLineSource {
  Error = "ERROR",
  HintMarkdown = "HINT-MARKDOWN",
  Input = "INPUT",
  Message = "MESSAGE",
  Output = "OUTPUT",
  Welcome = "WELCOME",
}

const prefix: Record<HistoryLineSource, string> = {
  [HistoryLineSource.Error]: "* ",
  [HistoryLineSource.HintMarkdown]: "Hint: ",
  [HistoryLineSource.Input]: "> ",
  [HistoryLineSource.Message]: "~ ",
  [HistoryLineSource.Output]: "",
  [HistoryLineSource.Welcome]: "",
};
const onlyPrefixFirstLine: Record<HistoryLineSource, boolean> = {
  [HistoryLineSource.Error]: false,
  [HistoryLineSource.HintMarkdown]: false,
  [HistoryLineSource.Input]: true,
  [HistoryLineSource.Message]: false,
  [HistoryLineSource.Output]: false,
  [HistoryLineSource.Welcome]: false,
};

const bareLine = (source: HistoryLineSource, line: string) =>
  ({ source, line } as HistoryLine);

const line = (source: HistoryLineSource, txt: string) =>
  bareLine(source, `${prefix[source]}${txt}`);

/**
 * Splits a given text into lines. If the last line in the text (which may be the only line fragment if the text has no linebreaks)
 * does not end with a newline, the nobreak property is set to true.
 *
 * @param source The formatting source for the type of lines to produce.
 * @param txt   The text to split into lines.
 * @param wholeLines  Whether the input should be treated as whole lines or not, even if they do not end with a newline.
 * @returns
 */
export function lines(
  source: HistoryLineSource,
  txt: string,
  wholeLines: boolean = true
): HistoryLine[] {
  if (txt === "") return [];
  const endsWithNewline = wholeLines || txt.endsWith("\n");
  const returnLines = onlyPrefixFirstLine[source]
    ? `${prefix[source]}${txt}`.split("\n").map(bareLine.bind(null, source))
    : txt.split("\n").map(line.bind(null, source));
  if (!endsWithNewline && returnLines.length) {
    returnLines[returnLines.length - 1].nobreak = true;
  }
  return returnLines;
}

/**
 * Appends new lines to the current sequence of lines. If the last line in the current sequence has nobreak === true then
 * the first line is appended to that line.
 * @param current     The current array of lines
 * @param additional  The additional lines to append
 * @returns
 */
export function appendLines(
  current: HistoryLine[],
  additional: HistoryLine[]
): HistoryLine[] {
  if (!additional || !additional.length) {
    return current;
  }

  if (current.length && current[current.length - 1].nobreak === true) {
    // append the first line to the current last line
    // This does a similar job as LineAggregator, but does it in a way that the terminal updates live as data is received, rather
    // than buffering it all until the newline arrives.
    current[current.length - 1].line += additional[0].line;

    // if the first line has a break then this is the end of the line
    current[current.length - 1].nobreak = additional[0].nobreak;
    const [, ...remain] = [...additional];
    return [...current, ...remain];
  }

  return [...current, ...additional];
}

export const errorLine = line.bind(null, HistoryLineSource.Error);
export const errorLines = lines.bind(null, HistoryLineSource.Error);

export const hintMarkdownLine = line.bind(null, HistoryLineSource.HintMarkdown);
export const hintMarkdownLines = lines.bind(
  null,
  HistoryLineSource.HintMarkdown
);

export const inputLine = line.bind(null, HistoryLineSource.Input);
export const inputLines = lines.bind(null, HistoryLineSource.Input);

export const messageLine = line.bind(null, HistoryLineSource.Message);
export const messageLines = lines.bind(null, HistoryLineSource.Message);

export const outputLine = line.bind(null, HistoryLineSource.Output);
export const outputLines = lines.bind(null, HistoryLineSource.Output);

export const welcomeLine = line.bind(null, HistoryLineSource.Welcome);
export const welcomeLines = lines.bind(null, HistoryLineSource.Welcome);
